import PropTypes from "prop-types";
import React, { useContext } from 'react';
import { Platform } from 'react-native';
import * as Icon from "react-icons/fi";
import {default as RNCheckBox} from 'react-native-check-box';
import {default as WebCheckBox} from "react-custom-checkbox";
import { AppContext } from "../../../AppContext";

export const CheckBox = (props) => {
  const {user} = useContext(AppContext);

  const getColor = (no=false) => (user.theme||!props.abideByTheme)||no ? "#212121" : "white";

  if(Platform.OS === "web") {
    return <WebCheckBox
    icon={
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: getColor(),
          alignSelf: "stretch",
        }}
      >
        <Icon.FiCheck color={props.abideByTheme && user.theme === 0 ? "212121" : "white"} size={20} />
      </div>
    }
    disabled={props.disabled}
    checked={props.isChecked}
    onChange={props.onClick}
    borderColor={getColor()}
    testID={props.testID}
    style={{ cursor: "pointer", margin: "10px" }}
  />;
  }

  return <RNCheckBox {...props} testID={props.testID} checkBoxColor={user.theme||!props.abideByTheme ? "black" : "white"}/>;
};
CheckBox.propTypes = {
  abideByTheme: PropTypes.any,
  disabled: PropTypes.bool,
  isChecked: PropTypes.any,
  onClick: PropTypes.any,
  testID: PropTypes.any
};