import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from 'react';
import { View, Image, Animated, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { InputText } from '../../components/elements/InputText';
import {REACT_APP_API_PASSWORD} from "@env";
import { Button } from '../../components/elements/Button';
import SortableList from 'react-native-sortable-list';
import { CreateImageFormData, SetFontSizeToFit,  regularColor,} from '../../utils/utils';
import {ImagePicker} from '../../components/elements/ImagePicker';
import { AppContext, MainScrollContext } from '../../../AppContext';
import { CheckBox } from '../../components/elements/Checkbox';
import { useCrtlEnterSubmit } from '../../components/elements/useCtrlEnter';
import { LMText } from '../../components/elements/Text';
import { MyColorPicker } from "../../components/elements/ColorPicker";
import { DropDown } from "../../components/elements/DropDown";
import { OKButton } from "../../components/elements/OKButton";
import { setDataSave } from "../../utils/dataSaver";

var appJson = require('../../../app.json');
const FS = 17.5;

const FieldRow = ({active, toggleRowActive, field, fields, onDropChange,deleteField, i, ValidateAll, onChange, fieldTypes, onFocus}) => {
  const [, setVal] = useState('text');
  const {user} = useContext(AppContext);
  return (
    <Animated.View style={active ? styles.fieldRowActive : {}}>
        <View style={[styles.textCell, {borderEndWidth:0, flexDirection: "row", alignItems: "center"}]} >
          <View style={{flexDirection: "column"}} onTouchStart={fields.length > 1 ? toggleRowActive : ()=>null}>
            {Platform.OS !== "web"
            &&<><LMText style={textBoxStyles(fields).minusText}>-</LMText>
            <LMText style={textBoxStyles(fields).minusText}>-</LMText>
            <LMText style={textBoxStyles(fields).minusText}>-</LMText></>
            }
          </View>
          <View style={{width: "50%"}}>
            <InputText
              onChange={onChange} name={i.toString()} 
              defaultValue={field.name} placeholder="Field Name"
              style={textBoxStyles(field.valid, user.theme).textBox}
              value={field.name} onBlur={ValidateAll} onFocus={onFocus}
            />
          </View>
          <DropDown defaultValue={field.type} data={fieldTypes.map(t=>t.name)} onDropChange={(selectedItem) => {onDropChange(i, selectedItem);setVal(selectedItem);}}/>
          <Button 
            icon={require("../../icons/remove.png")} 
            style={{...styles.removeButton, opacity: fields.length > 1 ? 1 : 0}} 
            onPress={fields.length > 1 ? ()=>deleteField(i) : ()=>null}
            abideByTheme={true}
          />
        </View>
    </Animated.View>
  );
};

FieldRow.propTypes = {
  ValidateAll: PropTypes.any,
  active: PropTypes.any,
  deleteField: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.any,
    type: PropTypes.any,
    valid: PropTypes.any
  }),
  fieldTypes: PropTypes.shape({
    map: PropTypes.func
  }),
  fields: PropTypes.shape({
    length: PropTypes.number
  }),
  i: PropTypes.shape({
    toString: PropTypes.func
  }),
  onChange: PropTypes.any,
  onDropChange: PropTypes.func,
  onFocus: PropTypes.any,
  toggleRowActive: PropTypes.any
};

export const AddCategory = ({setHomeRoute, setCanScroll, copyCategory, addCat}) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);
  const {setDoingAction} = useContext(MainScrollContext);

  const [name, setName] = useState(copyCategory.name ? copyCategory.name + "- Copy" :"");
  const [fields, setFields] = useState(
    copyCategory.feilds ? JSON.parse(copyCategory.feilds).map(f=>({...f, valid: true}))
    : [{name: "", type: "text", valid: true}]
  );
  const [fieldTypes, setFieldTypes] = useState([]);
  const [adminCat, setAdminCat] = useState(false);
  const [currentFont, setCurrentFont] = useState(FS);
  const [sortBy, setSortBy] = useState(copyCategory.sortBy||"Creation Time");
  const [image, setImage] = useState('');
  const [image64, setImage64] = useState('');
  const [specialSort, setSpecialSort] = useState(copyCategory.specialSort ? true : false);
  const [reverseSort, setReverseSort] = useState(copyCategory.reverseSort ? true : false);
  const [color, setColor] = useState(regularColor);
  const [darkness, setDarkness] = useState(0);

  const [sortingOptions, setSortingOptions] = useState(false);
  const [imageOptions, setSImageOptions] = useState(false);
  const [colorOptions, setColorOptions] = useState(false);

  useEffect(()=>{
    fetch(`${url}/lm_api/fields`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, version: appJson.expo.version },
    }).then((res) => res.json()).then((types) => {
      setFieldTypes(types);
    }).catch((err) => console.log(err));
  }, []);

  const addField = () => {
    const NewFields = fields.slice(0,fields.length);
    NewFields.push({name: "", type: "text", valid: true});
    setFields(NewFields);
    const namesOnly = fields.map(f=>f.name);
    if(!namesOnly.includes(sortBy))
      setSortBy("Creation Time");
  };
  const deleteField = (i) => {
    if(fields.length > 1) {
      const NewFields = fields.slice(0,fields.length);
      NewFields.splice(i,1);
      setFields(NewFields);
    }
    const namesOnly = fields.map(f=>f.name);
    if(!namesOnly.includes(sortBy))
      setSortBy("Creation Time");
  };
  const onChange = (nativeEvent, name) => {
    const NewFields = fields.slice(0,fields.length);
    if(sortBy === fields[name].name)
      setSortBy(nativeEvent.text);
    const index = Number(name);
    NewFields[index].name = nativeEvent.text;
    setFields(NewFields);
    validate(nativeEvent, index);
  };
  const onOrderChange = (key, currentOrder) => {
    let NewFields = fields.slice(0,fields.length);
    NewFields = currentOrder.map(n=>fields[Number(n)]);
    setCanScroll(true);
    setFields(NewFields);
  };
  const onDropChange = (index, value) => {
    const NewFields = fields.slice(0,fields.length);
    NewFields[index].type = value;
    setFields(NewFields);
  };

  const validate = (event, index) => {
    const NewFields = fields.slice(0,fields.length);

    var allNames = [...fields];
    allNames.splice(index, 1);
    allNames = allNames.map(f=>f.name);

    if(fields[index].name == "" || allNames.includes(fields[index].name)) {
      NewFields[index].valid = false;
      setFields(NewFields);
      return false;
    } else {
      NewFields[index].valid = true;
      setFields(NewFields);
      return true;
    }
  };

  const ValidateAll = () => {
    fields.map((f, i)=>{
      return validate({}, i);
    });
  };

  const onSubmit = () => {
    if(name.length < 2 || name[0] === ' ') {
      Alert("Can't add category", "Title is invalid");
      return;
    }
    const valids = fields.map((f, i)=>{
      return validate({}, i);
    });
    const newFields = fields.map((f)=>{
      return {name: f.name, type: f.type};
    });
    if(valids.includes(false))
      return;

    const namesOnly = fields.map(f=>f.name);
    if(!namesOnly.includes(sortBy))
      setSortBy("Creation Time");

    setReload(true);
    let imageName = image=="" ? "" : `${user.id}-category-${new Date().getTime()}`;
    uploadImage(imageName);
    addCat({name, feilds: fields, shared: 0, userId: user.id, id: 0, image: image=="" ? "" : imageName + ".jpeg"});
    fetch(`${url}/lm_api/categories`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        name, userId: user.id,
        feilds: newFields, admin: adminCat,
        sortBy, image: image=="" ? "" : imageName + ".jpeg", returnCat: true,
        specialSort, reverseSort, color: JSON.stringify({color, darkness})
      }),
    }).then((res) => res.json()).then((c) => {
      if(c.image !== "")
        saveCategory(c);
      setTimeout(()=>{
        reload();
        setHomeRoute("main");
        setReload(false);
      }, 250);
    });
  };
  
  const saveCategory = async (category) => await setDataSave(`category_image_${category.id}`, image);

  const onFocus = () => {
    //const index =  Number(name);
    //scrollTo(index * 40 + 40);
  };

  const uploadImage = (name) => {
    if(image=="")
      return "";

    fetch(`${url}/lm_api/image`, {
      method: 'POST',
      body: CreateImageFormData(image64, name),
      headers: {
        bucket: "cat"
      },
    }).then(()=>{
      reload();
    });
    return name + ".jpeg";
  };

  const onImagePick = (image, smallImage) => {setImage64(smallImage.uri);setImage(smallImage.uri);};

  useCrtlEnterSubmit(onSubmit, [name, fieldTypes, adminCat, image, sortBy, specialSort, reverseSort]);
  
  return <View style={styles.container}>
      <LMText style={styles.header}>Add Category</LMText>
      <InputText 
        placeholder={"Title"} onChange={(nativeEvent)=>setName(nativeEvent.text)} 
        defaultValue={name} style={styles.textBoxName} autoFocus={true}
      />
      <View style={styles.w100}>
      <SortableList
        style={{}}
        contentContainerStyle={{height: fields.length * 53 + 10}}
        data={fields}
        onActivateRow={()=>setCanScroll(false)}
        onReleaseRow={onOrderChange}
        renderRow={({data, active, toggleRowActive})=>{
          return <FieldRow
            field={data} active={active} fields={fields} onDropChange={onDropChange} deleteField={deleteField} 
            i={fields.indexOf(data)} ValidateAll={ValidateAll} onChange={onChange} fieldTypes={fieldTypes}
            toggleRowActive={toggleRowActive} onFocus={onFocus}
          />;
        }} 
        scrollEnabled={false}
        manuallyActivateRows={true}
      />
      </View>

        <Grid style={styles.w100}>
          <Row style={{paddingTop: 0}}>
            <Col style={[styles.textCell, {flex: 1, alignItems: "center", borderBottomColor: "black", borderBottomWidth: 0.5, paddingBottom: 20}]}>
              <Button style={[styles.addFieldButton, user.theme ? {} : {borderWidth: 0}]} onPress={addField} disabled={fields.length > 19}>
                <Image style={{width:20, height: 20, marginLeft: 15, tintColor: user.theme ? "black" : "white"}} source={require("../../icons/plus.png")}/>
                <LMText>Add Field</LMText>
              </Button>
            </Col>
          </Row>
          {user.admin
            ?<Row  style={[styles.dtRow, {paddingTop: 20}]}>
              <Col style={[styles.textCell, {alignItems:  "center", flex: 0.55, borderEndWidth:0}]}>
                <LMText>Admin Category</LMText>
              </Col>
              <Col style={[styles.textCell, {alignItems: "center", flex: 0.55, borderEndWidth:0}]}>
                <CheckBox
                  style={{padding: 15}}
                  onClick={()=>setAdminCat(!adminCat)}
                  isChecked={adminCat} abideByTheme={true}
                />
              </Col>
            </Row>
            : null
          }
          <TouchableOpacity style={{width: "100%"}} onPress={()=>setSImageOptions(!imageOptions)}>
            <LMText style={{padding: 10, fontSize: 20, paddingLeft: "2.5%"}}>{imageOptions? "-" : "+"} Image Options</LMText>
          </TouchableOpacity>
          {imageOptions
            ?<View style={{flexDirection: "column", padding: 10, paddingLeft: "5%"}}>

              <ImagePicker callback={onImagePick} setReload={setReload} />
            </View>
            : null
          }
          <TouchableOpacity style={{width: "100%"}} onPress={()=>setSortingOptions(!sortingOptions)}>
            <LMText style={{padding: 10, fontSize: 20, paddingLeft: "2.5%"}}>{sortingOptions? "-" : "+"} Sorting Options</LMText>
          </TouchableOpacity>
          {sortingOptions
            ?<View style={{padding: 10, paddingLeft: "5%"}}>
              <View style={{flexDirection: "row"}}>
                <LMText 
                  style={{fontSize: FS, ...styles.label, }}
                  numberOfLines={ 1 } adjustsFontSizeToFit
                  onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
                >Sort by:</LMText>
                <DropDown buttonStyle={{marginLeft: 10, width: 250}} defaultValue={sortBy} data={["Creation Time", "Title"].concat(fields.map(f=>f.name))} onDropChange={(selectedItem) => setSortBy(selectedItem)}/>
              </View>

              <View style={{flexDirection: "row", alignItems: "center"}}>
                <LMText  style={{fontSize: 25}}>Reverse</LMText>
                <CheckBox
                  style={{padding: 15}}
                  onClick={()=>setReverseSort(!reverseSort)}
                  isChecked={reverseSort} abideByTheme={true}
                />
              </View>
              {fields.find(f=>f.name===sortBy) && fields.find(f=>f.name===sortBy).type === "date"
                ?<View style={{flexDirection: "row", alignItems: "center"}}>
                  <LMText style={{fontSize: 25}}>Special Sort</LMText>
                  <CheckBox
                    style={{padding: 15}}
                    onClick={()=>setSpecialSort(!specialSort)}
                    isChecked={specialSort} abideByTheme={true}
                  />
                </View>
                : null
              }
            </View>
            : null
          }
          <TouchableOpacity style={{width: "100%"}} onPress={()=>setColorOptions(!colorOptions)}>
            <LMText style={{padding: 10, fontSize: 20, paddingLeft: "2.5%"}}>{colorOptions? "-" : "+"} Color Options</LMText>
          </TouchableOpacity>
          {colorOptions
            ? <MyColorPicker darkness={darkness} setDarkness={setDarkness} color={color} setColor={setColor} setDoingAction={setDoingAction}/>
            : null
          }
          <Row style={{paddingBottom: 60}}>
            <Col style={[styles.textCell, {flex: 1, paddingTop: 40}]}>
              <View style={styles.flexRow}>
                <OKButton onClick={onSubmit}  />
              </View>
            </Col>
          </Row>
        </Grid>
    </View>;
};

AddCategory.propTypes = {
  addCat: PropTypes.func,
  copyCategory: PropTypes.shape({
    feilds: PropTypes.any,
    name: PropTypes.string,
    reverseSort: PropTypes.any,
    sortBy: PropTypes.string,
    specialSort: PropTypes.any
  }),
  scrollTo: PropTypes.func,
  setCanScroll: PropTypes.func,
  setHomeRoute: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center"
  },
  header: {
    fontSize: 35,
    fontWeight: "400",
    marginBottom: 20
  },
  dtRow: {borderBottomWidth: 0, height: 50,},
  label: {
    fontSize: 25
  },
  textCell: {
    borderRightColor: "transparent", 
    borderRightWidth: 1, 
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  w100: {width:"100%"},
  textBoxName: {
    width: 400,
    height: 40,
    maxWidth: "60%"
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: 10
  },
  removeButton: {
    width: 40, 
    height: 40, 
    backgroundColor:"transparent",
  },
  addFieldButton: {
    width: 120,
    borderColor: "#d7d7d7",
    borderRadius: 6,
    borderWidth: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  fieldRowActive: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    shadowColor: "#000",
    shadowOffset:{
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 4,
    elevation: 16,
  },
});

const textBoxStyles = (valid, theme) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  textBox: {
    borderColor: valid ? (theme ? "#d7d7d7" : "transparent") : "red",
    borderWidth: valid ? 1 : 2,
    width: "97%",
    height: 30
  },
  // eslint-disable-next-line react-native/no-unused-styles
  minusText: {fontSize: 30, marginTop: -20, marginBottom: -20, opacity: valid.length > 1 ? 1 : 0,}
});