import PropTypes from "prop-types";
import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import { LMText } from "./Text";
import { AppContext } from "../../../AppContext";

export const InputText = ({
  style = {},
  label = "",
  labelColor = "gray",
  value = null,
  onChange = ()=>null,
  onBlur = ()=>null,
  rightIconOnPress = ()=>null,
  iconOnPress = ()=>null,
  password = false,
  placeholder = "",
  icon = null,
  name = "",
  type = "text",
  onFocus = ()=>null,
  topLabel = "",
  rightIcon = null,
  autoFocus=false,
  autoCapitalize,
  defaultValue="",
  abideByTheme=true,
  selectTextOnFocus=false,
}) => {
  const { user } = useContext(AppContext);


  const limitedProps = {
    value: value,
    returnKeyType: "done",
    defaultValue: defaultValue
  };
  if (value === null) delete limitedProps.value;
  if (defaultValue === null) delete limitedProps.defaultValue;
  if (type === "free text") delete limitedProps.returnKeyType;
  const textColor = style.color || user.theme > 0 || user.theme===undefined || !abideByTheme ? "black" : "white";
  return (
    <>
      {topLabel === "" ? null : <LMText style={styles.topLabel}>{topLabel}</LMText>}
      <TextInput
        style={[
          styles.input,
          style,
          type === "free text" ? { height: 100 } : {},
          user.theme > 0|| user.theme===undefined || !abideByTheme ? {} : {backgroundColor: "#4d4d4d", borderColor: style.borderColor||"transparent", color: "white"}
        ]}
        textColor="black"
        label={label}
        placeholder={placeholder}
        {...limitedProps}
        numberOfLines={1}
        testID={placeholder.replace(' ', '_') + (icon ?  "_" + icon.toString().split('/').pop().split('.')[0] : "")}
        theme={{
          colors: {
            placeholder: labelColor,
            text: textColor,
            primary: textColor,
            underlineColor: "transparent",
            background: style.backgroundColor || "white",
          },
        }}
        autoFocus={autoFocus}
        autoCapitalize={
          autoCapitalize ? autoCapitalize : (type !== "url"  && type !== "email" && type !== "user" ? "sentences" : "none")
        }
        left={
          icon === null ? (
            ""
          ) : (
            <TextInput.Icon
              name={icon}
              color={textColor}
              onPress={iconOnPress}
            />
          )
        }
        right={
          rightIcon === null ? (
            ""
          ) : (
            <TextInput.Icon
              name={rightIcon}
              color={textColor}
              onPress={rightIconOnPress}
            />
          )
        }
        secureTextEntry={password||type === "password"}
        underlineColor="transparent"
        keyboardType={
          type === "number" ? "numeric" : (
            type === "email" ? "email-address" : (
              type==="url" ? "url" : (
                type === "phone" ? "phone-pad" : (
                  type === "password" ? "visible-password" : "default"
                )
              )
            )
          )
        }
        onChange={({ nativeEvent }) => {
          onChange(nativeEvent, name);
        }}
        onBlur={onBlur ? (event) => onBlur(event, name) : null}
        onFocus={onFocus ? (event) => onFocus(event, name) : null}
        multiline={type === "free text"}
        selectTextOnFocus={selectTextOnFocus}
      />
    </>
  );
};

InputText.propTypes = {
  abideByTheme: PropTypes.bool,
  autoCapitalize: PropTypes.any,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  icon: PropTypes.shape({
    toString: PropTypes.func
  }),
  iconColor: PropTypes.string,
  iconOnPress: PropTypes.func,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  rightIcon: PropTypes.any,
  rightIconOnPress: PropTypes.func,
  selectTextOnFocus: PropTypes.bool,
  style: PropTypes.object,
  topLabel: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any
};

const styles = StyleSheet.create({
  input: {
    color: "black",
    height: 30,
    width: 300,
    borderColor: "#d7d7d7",
    borderWidth: 1,
    borderRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    margin: 10,
    backgroundColor: "white",
    textAlignVertical: "top",
  },
  topLabel: {
    position: "relative",
    alignSelf: "flex-start",
    marginLeft: "10%",
    textAlign: "left",
  },
});
