import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Image, ScrollView, Keyboard, Platform } from 'react-native';
import { Button } from '../../components/elements/Button.js';
import { InputText } from '../../components/elements/InputText.js';
import {REACT_APP_API_PASSWORD} from "@env";
import * as AppleAuthentication from 'expo-apple-authentication';
import jwt_decode from 'jwt-decode';
import { Popup } from '../../components/elements/popup.js';
import { screenHeight, toMysqlFormat, validateEmail } from '../../utils/utils.js';
import { setDataSave } from "../../utils/dataSaver.js";
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { AppContext } from "../../../AppContext.js";
import { LMText } from "../../components/elements/Text.js";
import { BarPasswordStrengthDisplay, scorePassword } from 'react-native-password-strength-meter';
import { useColorScheme } from "react-native";



WebBrowser.maybeCompleteAuthSession();

export const Register = ({setRoute}) => {
  const {url, setReload, Alert} = useContext(AppContext);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [phone, setPhone] = useState('');
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [passC, setPassC] = useState('');
  //refs
  const scrollRef = React.useRef(); 
  //visible
  const [passV, setPassV] = useState(false);
  const [passCV, setPassCV] = useState(false);
  const [KeyboardVisible, setKeyboardVisible] = useState(false);

  const [appleNamePopup, setAppleNamePopup] = useState(false);
  const [Aname, setAName] = useState("");
  const [ALname, setALName] = useState("");
  const [AEmail, setAEmail] = useState("");
  const [AEmailValid, setAEmailValid] = useState(true);
  const [credential, setCredential] = useState({});
  const [warned, setWarned] = useState(false);

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const theme = useColorScheme();
  
  useEffect(()=>{
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardWillShow',
      () => {
        setKeyboardVisible(true);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardWillHide',
      () => {
        setKeyboardVisible(false);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);  

  useEffect(() => {
    if(KeyboardVisible) {return;}
  }, []);

  const onSubmit = () => {
    const variations = {
      digits: /\d/,
      lower: /[a-z]/,
      upper: /[A-Z]/,
      nonWords: /\W/,
    };
    setReload(true);
    if(pass===passC) {
      if(pass == '' || user == '' || !validateEmail(email) || name == '' || lastName == '') { 
        Alert("Regisration Failed!", "fields are not valid");
        setReload(false);
        return;
      }
      if( scorePassword(pass, 0, 100, variations) < 56) {
        Alert("Regisration Failed!", "you need at least an average strong password!");
        setReload(false);
        return;
      }
			fetch(`${url}/lm_api/user`, {
				method: "post", headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					password: REACT_APP_API_PASSWORD,
					date: toMysqlFormat(new Date()),
					username: user, email: email,
					phone: phone, Upassword: pass,
					name: name,LName: lastName,
          emailUrl: url, theme: theme==="dark" ? 0 : 1,
				}),
			}).then((res) => res.json()).then((res) => {
        setReload(false);
        if(res.includes("exist")) {
          Alert(`User with that ${res.split(' ')[0]} already exist!`);
        }
				else {
					Alert("Registration Successful!", "User was created successfully.\nA verification email was sent to you, login again after verification!");
          setRoute("signin");
        }
			}).catch((err) => console.log(err));
    } else
      Alert("Regisration Failed!", "passwords do not match");
    setReload(false);
  };

  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId: '577999943798-1a72ik1c97gro5q7ma1gksjuh90rhr1a.apps.googleusercontent.com',
    iosClientId: '577999943798-euvrk0dtbc0ko6j69u0vn04bmcg20534.apps.googleusercontent.com',
    androidClientId: '577999943798-cqpc50uq390ftvgtlsr8nd6rksjpdmd5.apps.googleusercontent.com',
    webClientId: '577999943798-pb33ai7rinada6a8n921uitrvfm1foan.apps.googleusercontent.com',
    scope: ["email", "profile"],
  });
  React.useEffect(() => {
    if (response?.type === 'success') {
      getGoogleData(response.authentication.accessToken);
    }
  }, [response]);

  const getGoogleData = async (token) => {
    const response = await fetch(
      "https://www.googleapis.com/userinfo/v2/me",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const user = await response.json();

    console.log(user);
    googleSignIn(user);
  };


  const googleSignIn = async (result) => {
    try {
        setReload(true);
        fetch(`${url}/lm_api/user/google`, {
          method: "post", headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: REACT_APP_API_PASSWORD,
            date: toMysqlFormat(new Date()),
            username: result.email.split("@")[0], email: result.email,
            phone: "", Upassword: result.id,
            name: result.name.split(" ")[0], LName: result.name.split(" ")[1],
            emailUrl: url, theme: theme==="dark" ? 0 : 1,
          }),
        }).then((res) => res.json()).then((res) => {
          setReload(false);
          if(res === "exist")
            Alert("User with that google account already exist!");
          else {
            fetch(`${url}/lm_api/user/google`, {
              method: "get", headers: {
                 "Content-Type": "application/json",
                 password: REACT_APP_API_PASSWORD, 
                 email: result.email, id: result.id,
               },
            }).then((res) => res.json()).then((user) => {
              if(user === false)
                Alert("Invalid User Or Password.");
              else {
                setDataSave("userdata", user.username + "," + user.password);
                setDataSave("recents", "[]");
                setRoute('home');
              }
            }).catch((err) => console.log(err));
          }
        }).catch((err) => console.log(err));
    } catch (e) {
      console.log("error", e);
    }
  };

  const AppleLogin = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      const { email } = jwt_decode(credential.identityToken);
      
      setCredential(credential);
      setAppleNamePopup(true);
      setEmail(email);
    } catch (e) {
      if (e.code === 'ERR_CANCELED') {
        console.log("canceled");
      } else {
        console.log("error", e);
      }
    }
  };
  const CreateAppleUser = () => {
    if(warned || !email.includes("appleid") || AEmail==="") {
      EnterAppleUserToDataBase();
    } else {
      //Alert(`Please Review Your Email:\n${AEmail}\n ** wrong email will prevent virification.\nContinue?`);
      Alert(
        "WARNING!",
        `Please Review Your Email:\n${AEmail}\n ** wrong email will prevent verification.\nContinue?`,
        [
          {text: "Cancel",},
          {text: "Continue", onPress: () =>{setWarned(true);EnterAppleUserToDataBase();}},
        ]
      );
    }
  }; 
  const EnterAppleUserToDataBase = () => {
    const jwt = jwt_decode(credential.identityToken);
    setReload(true);
    setAppleNamePopup(false);
    fetch(`${url}/lm_api/user/apple`, {
      method: "post", headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        date: toMysqlFormat(new Date()),
        username: (jwt.email.includes("appleid") ? (AEmail==="" ? jwt.email : AEmail) : jwt.email).split("@")[0], 
        email: jwt.email.includes("appleid") ? (AEmail==="" ? jwt.email : AEmail) : jwt.email,
        phone: "", Upassword: credential.user,
        name: Aname, LName: ALname,
        emailUrl: url, theme: theme==="dark" ? 0 : 1,
      }),
    }).then((res) => res.json()).then((res) => {
      setReload(false);
      if(res.includes("exist"))

        Alert(`User with that email already exist!`);
      else {
        Alert("Success!!", "User was created successfully!\nClick on the link in the email that was sent to you, after that you will be able to login.");
        setRoute("signin");
      }
    }).catch((err) => console.log(err));
  };

  const onFocus = () => {
    // const index =  Number(name);
    // scrollRef.current?.scrollTo({
    //     y: index * 40 + 40,
    //     animated: true,
    // });
  };

  console.log(passwordsMatch);


  useEffect(() => {
    setPasswordsMatch(pass == passC);
  }, [pass, passC]);
  
  return <View style={styles.container}>
    <View style={styles.backCon}>
      <Button 
        icon={require("../../icons/backArrow.png")} style={styles.backButton} 
        textStyle={styles.submitText} onPress={()=>setRoute("signin")}
        abideByTheme={true}
      />
    </View>
      <ScrollView style={styles.scrollContainer} ref={scrollRef}>
        <LMText style={styles.title}>Sign up</LMText>
        <Button 
          text="Sign up with Google" style={styles.wideButton} 
          textStyle={styles.submitText} onPress={()=>{
            promptAsync().catch(err=>console.log(err));
          }}
          disabled={!request}
        ><Image source={require("../../icons/google.png")} style={styles.googleIcon} /></Button>
        {Platform.OS == "ios"
          ?         <AppleAuthentication.AppleAuthenticationButton
          buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP}
          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
          cornerRadius={5}
          style={{...styles.wideButton, backgroundColor: "transparent"}}
          onPress={AppleLogin}
        />
          : null
        }
        <View style={styles.seperationText}><LMText>OR</LMText></View>
        <View style={{flexDirection: "column", alignItems: "center", width: 400, alignSelf: "center"}}>
          <InputText 
            placeholder="Type Your First Name..." style={styles.input}
            onChange={(nativeEvent)=>setName(nativeEvent.text)}
            icon={require("../../icons/accountIconTransparent.png")} iconColor="gray"
            topLabel="First Name" onFocus={onFocus} name="1"
          />
          <InputText 
            placeholder="Type Your Last Name..." style={styles.input}
            onChange={(nativeEvent)=>setLastName(nativeEvent.text)}
            icon={require("../../icons/accountIconTransparent.png")} iconColor="gray"
            topLabel="Last Name" onFocus={onFocus} name="2"
          />
          <InputText 
            placeholder="Type Your Email address..." style={{...styles.input, borderColor: emailValid ? "#d7d7d7" : "red"}}
            onChange={(nativeEvent)=>{setEmail(nativeEvent.text);setEmailValid(validateEmail(nativeEvent.text));}}
            icon={require("../../icons/accountIconGray.png")} iconColor="gray"
            topLabel="Email *" onFocus={onFocus} name="3"
          />
          <InputText 
            placeholder="Type Your Phone number..." style={styles.input}
            onChange={(nativeEvent)=>setPhone(nativeEvent.text)}
            icon={require("../../icons/phone.png")} iconColor="gray"
            topLabel="Phone Number" type="number" onFocus={onFocus}
            name="4"
          />
          <InputText 
            placeholder="Type Your wanted Username..." style={styles.input}
            onChange={(nativeEvent)=>setUser(nativeEvent.text)}
            icon={require("../../icons/accountIconTransparent.png")} iconColor="gray"
            topLabel="Username *" onFocus={onFocus} name="5"
          />
          <InputText 
            placeholder="Type Your Password..." style={[styles.input, passwordsMatch ? {} : {borderWidth: 3, borderColor: "red"}]}
            onChange={(nativeEvent)=>{setPass(nativeEvent.text);}}
            icon={require("../../icons/lock.png")} iconColor="gray"
            topLabel="Password *" password={!passV} onFocus={onFocus}
            name="6" rightIcon="eye" rightIconOnPress={()=>setPassV(!passV)}
          />
          <View style={{backgroundColor: user.theme ? "white" : "#4d4d4d", marginBottom: 10, marginTop: 5, borderRadius: 10, height: 50, alignItems: "center", width: 300, justifyContent: "center", paddingBottom: 15}}>
            <BarPasswordStrengthDisplay
              password={pass}
              width={280}
              minLength={0}
              labelStyle={{fontSize: 18, fontWeight: 800}}
            />
          </View>
          <InputText 
            placeholder="Type Your Password Again..." style={[styles.input, passwordsMatch ? {} : {borderWidth: 3, borderColor: "red"}]}
            onChange={(nativeEvent)=>{setPassC(nativeEvent.text);}}
            icon={require("../../icons/lock.png")} iconColor="gray"
            topLabel="Confirm Password *" password={!passCV} onFocus={onFocus}
            name="7" rightIcon="eye" rightIconOnPress={()=>setPassCV(!passCV)}
          />
        </View>

          <Button 
            text="SIGN UP" 
            onPress={onSubmit}
            style={styles.submit}
            textStyle={styles.submitText}
            gradient={["#4065b2", "#277cb9"]}
            gradientStart={[0,1]}
            gradientEnd={[1,0]} disabled={!emailValid}
          />
          <Popup isVisible={appleNamePopup} close={()=>setAppleNamePopup(false)} style={styles.popup}>
            <LMText style={styles.header}>Set Name</LMText>
            <InputText 
              placeholder="Type Your First Name..." style={styles.input}
              onChange={(nativeEvent)=>setAName(nativeEvent.text)}
              topLabel="Name"
            />
            <InputText 
              placeholder="Type Your Last Name..." style={styles.input}
              onChange={(nativeEvent)=>setALName(nativeEvent.text)}
              topLabel="Last Name"
            />
            {email.includes("appleid")
              ?<InputText 
                placeholder="Type Your Email..." style={{...styles.input, borderColor: AEmailValid ? "#d7d7d7" : "red", borderWidth: 1}}
                onChange={(nativeEvent)=>setAEmail(nativeEvent.text)}
                topLabel="Email (Optional)" onBlur={(event)=>setAEmailValid(validateEmail(event.nativeEvent.text)||event.nativeEvent.text=="")}
              />
              : null
            }
            <Button 
              text="Sign Up" 
              onPress={CreateAppleUser}
              style={styles.submit}
              textStyle={styles.submitText}
              gradient={["#4065b2", "#00b2e8"]}
              gradientStart={[0,1]}
              gradientEnd={[1,0]}
              disabled={!AEmailValid && email.includes("appleid")}
            />
          </Popup>
      <View style={{...styles.scrollFiller, height: 100}}/>
    </ScrollView>
  </View>;
};

Register.propTypes = {
  reload: PropTypes.any,
  setRoute: PropTypes.func,
  url: PropTypes.any
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    width: "100%",
  },
  scrollContainer: {
    width: "120%",
    padding: 50,
    paddingTop: 0,
    marginTop: 80,
    height: screenHeight,
  },
  submit: {
    height: 40,
    width: 300,
    borderRadius: 70,
    alignSelf: "center",
    marginTop: 20
  },
  title: {
    fontSize: 35,
    fontWeight: "400",
    margin: 10,
    alignSelf: "center"
  },
  submitText: {color: "white", fontWeight: "400"},

  backCon: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 101,
    width: "100%",
    height: 60,
  },

  backButton: {
    backgroundColor: "transparent",
    width: 40,
    height: 40,
    zIndex: 101,
    top: 40,
    left: 20
  },
  input: {
    backgroundColor: "white",
    height: 40
  },
  scrollFiller: {
    width: "100%",
    height: 200
  },
  seperationText: {
    marginTop: 10,
    marginBottom: 10,
    borderTopColor: "#ededed",
    borderBottomColor: "#ededed",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    width: 80,
    alignItems: "center",
    padding: 10,
    alignSelf: 'center'
  },
  wideButton: {
    backgroundColor: "#5e98f7",
    width: 300,
    height: 40,
    marginBottom: 20,
    alignSelf: "center"
  },
  googleIcon: {
    width: 30,
    height: 30,
    position: 'absolute',
    left: "10%"
  },
  popup: {
    zIndex:101
  }
});