import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, Text, View, KeyboardAvoidingView, StatusBar, Animated, Platform } from 'react-native';
import { Signinpage } from './src/pages/signinpage/signinpage';
import { getDataSave, setDataSave } from './src/utils/dataSaver';
import {REACT_APP_API_PASSWORD } from "@env";
import { Home } from './src/pages/home/home';
import { Register } from './src/pages/register/register';
import * as Updates from 'expo-updates';
import * as Notifications from 'expo-notifications';
import { Provider } from 'react-native-paper';
import { AppContext, CacheContext } from './AppContext';
import { IsJsonString, toMysqlFormat } from './src/utils/utils';
import { useFonts } from 'expo-font';
import { Alert } from './src/components/elements/Alert';
import { useColorScheme } from 'react-native';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

export default function App() {
  const [fontsLoaded] = useFonts({
    'Poppins': require("./assets/fonts/Poppins-Regular.ttf"),
    'Poppins_bold': require("./assets/fonts/Poppins-Bold.ttf")
  });

  const theme = useColorScheme()==="dark" ? 0 : 1;
  const [route, setRoute] = useState('signin');
  const [url, setUrl] = useState('');
  const [user, setUser] = useState({theme});
  const [isReload, setReload] = useState(false);


  // alert data
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertOptions, setAlertOptions] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(0);

  const ShowAlert = (title, text, buttonOptions=[]) => {
    setAlertPopup(true);
    setAlertText(text);
    setAlertTitle(title);
    setAlertOptions(buttonOptions);
  };

  const [AlertFunc, setAlertFunc] = useState({func: ShowAlert});
  useEffect(()=>{
    if(AlertFunc.func === 0) setAlertFunc({func: ShowAlert});
  }, [AlertFunc]);

  // cache
  const [defaultCats, setDefaultCats] = useState([]);
  const getCats = async () => {
    const value = await getDataSave(`categories`);
    //console.log(value);
    if(IsJsonString(value) && value != null)
      setDefaultCats(JSON.parse(value));
    else {
      setDataSave("categories", "[]");
      getCats();
    }
  };


  const ReactNative = require('react-native');
  try {
    if(ReactNative.I18nManager.isRTL){
      ReactNative.I18nManager.allowRTL(false);
      ReactNative.I18nManager.forceRTL(false);
      Updates.reloadAsync();
   }
  } catch (e) {
    alert(e);
  }
  ReactNative.LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
  ReactNative.LogBox.ignoreAllLogs();//Ignore all log notifications


  useEffect(() => {
    getCats();
    if (__DEV__&&0) {
      setUrl("http://192.168.1.87:3009");
    } else {
      setUrl(
        `https://server.lifemngr.com:443`
      );
    }
  }, []);


  const getUser = async () => {
    if(route !== "register") {
      const userdata = await getDataSave('userdata');
      if (userdata !== " , " && userdata !== null) {
        const split = userdata.split(",");
       fetch(`${url}/lm_api/user`, {
          method: "get",
          headers: { 
            "Content-Type": "application/json",
            password: REACT_APP_API_PASSWORD,
            user: split[0],
            UPassword: split[1],
            encrypt: true,
            isEmail: false,
            date: toMysqlFormat(new Date()),
          },
        }).then((res) => res.json()).then((user) => {
          if(user) {
            setUser(user);
            if(route=="signin")
              setRoute("home");
          } else {
            setDataSave('userdata', " , ");
            setRoute("signin");
          }
        }).catch(()=>null);
      } else {
        setRoute("signin");
        setReload(false);
      }
    }
  };

  useEffect(() => {
    getUser();
    Text.defaultProps = Text.defaultProps || {};
    Text.defaultProps.allowFontScaling = false; 
    if(user.theme === -1) {
      setUser({...user, theme});
      fetch(`${url}/lm_api/user/theme`, {
        method: "put",
        headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD },
        body: JSON.stringify({ theme, id: user.id, }),
      }).then(()=>{
        reload();
      });
    } 
  }, [url, route]);

  useEffect(() => {
    spinValue.setValue(0);
    ReactNative.Animated.loop(
      ReactNative.Animated.timing(
        spinValue,
      {
        toValue: 3,
        duration: 10000,
        easing: ReactNative.Easing.linear, // Easing is an additional import from react-native
        useNativeDriver: true  // To make use of native driver for performance
      }
      )
    ).start();
  }, [isReload]);
  

  const reload = () => {getUser();setForceUpdate(forceUpdate+1);};


  const ViewType = Platform.OS === 'ios' ? KeyboardAvoidingView : View;

  let spinValue = new ReactNative.Animated.Value(0);


  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  });

  const background3 = require("./src/icons/background3.jpg");
  const background4 = require("./src/icons/background4.jpg");
  const darkBackground = require("./src/icons/backgroundNight.jpg");
  const darkBackgroundWeb = require("./src/icons/backgroundNightWeb.jpg");

  if (!fontsLoaded) {
    return null;
  }
  
  const lightTheme = user.theme===undefined||user.theme>0;

  return (
    <>
      <Provider>
        <AppContext.Provider value={{ setReload, url, user, reload, Alert: AlertFunc.func, setAlertFunc, isReload }}>
          <CacheContext.Provider value={{categoriesCache: defaultCats}}>
            <View style={{...styles.container, backgroundColor: lightTheme ? "white" : "#2e2e2e"}}>
              <StatusBar
                barStyle={user.theme ? "dark-content" : "light-content"}
                backgroundColor={user.theme ? "#E1F4FF" : "black"}
                style={{color: user.theme ? "black" : "white"}}
              />
              <Image source={lightTheme ? (Platform.OS === "web" ? background4 : background3) : (Platform.OS==="web" ? darkBackgroundWeb : darkBackground)} 
                style={[styles.backgroundImage, user.theme ? {} : {opacity: 0.4}]} blurRadius={1}/>
              <ViewType
                behavior="padding"
                style={styles.container}
              >
                {
                  {
                    'signin': <Signinpage setRoute={setRoute} setUser={setUser}/>,
                    'home': <Home defaultCats={defaultCats} forceUpdate={forceUpdate}/>,
                    'register': <Register setRoute={setRoute} />,
                  }[route]
                }
              </ViewType>
              <View style={[styles.allScreen, {right:  !isReload ? "300%" : 0}]}>
                <Animated.View style={[styles.allScreen, {transform: [{rotate: spin},], backgroundColor: "transparent", height: 200, width: 300,}]}>
                  <Image source={require('./src/icons/loading.png')} style={{width: 200, height: 200}} resizeMode="contain"/>
                </Animated.View>
              </View>
            </View>  
          </CacheContext.Provider>
        </AppContext.Provider>
      </Provider>
      
      <Alert 
        alertPopup={alertPopup} alertTitle={alertTitle} 
        alertText={alertText} setAlertPopup={setAlertPopup}
        alertOptions={alertOptions}
      />
    </>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
    position: 'absolute',
    zIndex: 0,
    width: "100%",
    height: "100%",
    opacity: 0.3,
  },
  allScreen: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  }
});
