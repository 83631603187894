import PropTypes from "prop-types";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { View } from "react-native";
import ColorPicker from "react-native-wheel-color-picker";
import { LightenDarkenColor } from "../../utils/utils";
import Slider from "@react-native-community/slider";
import { GetColorName } from "hex-color-to-color-name";
import { LMText } from "./Text";
import { StyleSheet } from "react-native";
import { Platform } from "react-native";

export const MyColorPicker = ({ color, setColor, setDoingAction, setDarkness, darkness=-1 }) => {
  return <View style={{ maxWidth: darkness===-1 ? "100%" : 350, width: 500, paddingBottom: 70, height: 400, flexDirection: "column", justifyContent: "center"}}>
    <ColorPicker
      color={color}
      onColorChange={setColor}
      onInteractionStart={()=>setDoingAction(true)}
      onColorChangeComplete={()=>{setDoingAction(false);}}
      thumbSize={30}
      swatches={false}
      sliderHidden={darkness !== -1}
      noSnap={true}
      row={false}
      palette={['#ffffff','#888888','#ed1c24','#d11cd5','#1633e6','#00aeef','#00c85d','#57ff0a','#ffde17','#f26522']}
    />
    {
      darkness !== -1 &&<LinearGradient
      colors={[color, LightenDarkenColor(color, -65)]}
      style={styles.sliderBackground}
      start={[0,0.5]}
      end={[1.5,0]}
    >
      <Slider
        style={{width: "99%", height: 20, flex: 1}}
        minimumValue={0}
        maximumValue={65}
        onValueChange={(val)=>setDarkness(val)}
        minimumTrackTintColor="transparent"
        maximumTrackTintColor="transparent"
        thumbTintColor="white"
      />
    </LinearGradient>
    }
    
    <LMText abideByTheme={false} style={[styles.colorText, {backgroundColor: LightenDarkenColor(color, -darkness), position: "absolute", bottom: 20}]}>
    {GetColorName(LightenDarkenColor(color, -darkness))}</LMText>
  </View>;
};

MyColorPicker.propTypes = {
  color: PropTypes.any,
  darkness: PropTypes.any,
  popup: PropTypes.bool,
  setColor: PropTypes.any,
  setDarkness: PropTypes.func,
  setDoingAction: PropTypes.func
};

const styles = StyleSheet.create({
  colorText: {
    fontSize: 25, 
    borderColor: "black", 
    borderWidth: 0.7, 
    paddingHorizontal: 20,
    alignSelf: "center" 
  },
  sliderBackground: {
    width: 240,
    borderRadius: 25,
    height: Platform.OS !== "web" ? 35 : 25,
    justifyContent: "center",
    alignSelf: "center"
  }
});