import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import * as Notifications from 'expo-notifications';
import { StyleSheet, View, Platform } from 'react-native';
import {Popup} from "../../components/elements/popup";
import { Button } from "../../components/elements/Button";
import { DateTimePickerButton } from "../../components/elements/DateTimePickerButton";
import { LMText } from "../../components/elements/Text";

async function registerForPushNotificationsAsync() {
  let token;
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    //Alert('Failed to get push token for push notification!');
    return;
  }
  token = (await Notifications.getExpoPushTokenAsync()).data;

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      lightColor: '#FF231F7C',
      sound: 'alarm.wav'
    });
  }

  return token;
}
export const AddTimer = ({defaultTime, header, fieldName}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [time, setTime] = useState(defaultTime);

  const init = async () => {
    registerForPushNotificationsAsync();
    await Notifications.setNotificationChannelAsync('note-push', {
      name: 'alarm',
      sound: 'alarm.wav'
    });
  };
  useEffect( ()=>{init();}, []);

  const createTimer = async () => {
    setIsVisible(true);
    await Notifications.scheduleNotificationAsync({
      content: {
        title: header,
        body: `Alarm for ${fieldName}`,
        sound: "alarm.wav",
      },
      trigger: { seconds: new Date(time).getMinutes()*60 + new Date(time).getHours()*60*60, channelId: 'default', },
    });
    setIsVisible(false);
  };

  return <>
  <Button icon={require("../../icons/timer.png")} style={styles.dateIcon} onPress={()=>setIsVisible(true)}/>
    <Popup isVisible={isVisible} close={()=>setIsVisible(false)}>
      <LMText style={styles.header}>Set Timer (hour:minute)</LMText>
      <LMText style={styles.subheader}>Are you sure you want set timer?</LMText>
      <DateTimePickerButton style={{width: 200, alignSelf: "center"}} field={{type: 'time', value: time}} onDTChange={setTime}/>
      <View style={styles.buttonCon}>
        <Button 
          text="No, go back" onPress={()=>setIsVisible(false)}
          style={styles.backButton}
        />
        <Button 
          text="OK!" onPress={createTimer}
          gradient={["#277cb9", "#000000"]}
          gradientStart={[0,0.5]} style={styles.submit}
          gradientEnd={[1.5,0]} textStyle={{color: "white"}}
        />
      </View>
    </Popup>
  </>;
};

AddTimer.propTypes = {
  defaultTime: PropTypes.any,
  fieldName: PropTypes.any,
  header: PropTypes.any,
  time: PropTypes.any
};


const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    marginBottom: 30,
    textAlign: "center"
  },
  subheader: {
    fontSize: 20,
    marginBottom: 30,
    textAlign: "center"
  },
  dateIcon: {
    width: 40,
    height: 40,
    marginLeft: 10,
    backgroundColor: "transparent",
    paddingBottom: 10
  },
  buttonCon: {
    marginTop: 10,
    flexDirection: "row"
  },
  submit: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5
  },
  backButton: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5,
    borderWidth: 2,
    borderColor: "#277cb9",
    backgroundColor: "transparent"
  }
});