import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtmlPuri from "draftjs-to-html";
import { LMText } from "./Text";
const Icon = ({ tintColor }) => <LMText style={{ color: tintColor }}>H1</LMText>;

Icon.propTypes = {
  tintColor: PropTypes.any,
};

export const RichTextEditor = ({
  onChange,
  name,
  initialValue,
  onFocus = ()=>null,
  onBlur = ()=>null,
  disabled=false,
  constHeight=true,
  height=185
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(initialValue||"");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    setValue(editorState);
  }, []);
  

  const onTextChange = (state) => {
    console.log(draftToHtmlPuri(
      convertToRaw(state.getCurrentContent())
    ));
    var val = draftToHtmlPuri(
      convertToRaw(state.getCurrentContent())
    );
    onChange({text: val}, name);
    setValue(state);
  };

  const heightData = {height: height};
  if(!constHeight) delete heightData.height;

  return (
    <View style={{ width: "89%", borderColor: "gray", borderWidth: 1 }}>
      <Editor
        editorState={value}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorStyle={{backgroundColor: "white", padding:  height / 10, position: "relative", ...heightData, paddingTop: 0, marginTop: -10}}
        onEditorStateChange={onTextChange}
        onFocus={onFocus} onBlur={onBlur}
        toolbarHidden={disabled} readOnly={disabled}
        toolbar={{
          options: ['inline', 'fontSize', 'list', 'textAlign'],
          list: {inDropdown: true}
        }}
      />
    </View>
  );
};
RichTextEditor.propTypes = {
  constHeight: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.number,
  initialValue: PropTypes.any,
  name: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func
};