/* eslint-disable react-native/no-unused-styles */
import PropTypes from "prop-types";
import React, { useContext } from 'react';
import { Button } from '../elements/Button';
import {View, StyleSheet} from 'react-native';
import { AppContext, BackButtonContext } from "../../../AppContext";
import { LMText } from "../elements/Text";
import { TouchableOpacity } from "react-native-gesture-handler";

export const WebSideBar = ({setHomeRoute, homeRoute, isUnreadNotification, setCopyMode}) => {
  const { backButtonFunction } = useContext(BackButtonContext);
  const {user} = useContext(AppContext);

  const inMain = homeRoute == "main" || homeRoute == "searchScreen";
  const inAcc = homeRoute == "account";
  const inNotifications = homeRoute == "notifications";

  return <View style={styles(false, user.theme).sideBar}>
    {homeRoute !== "main"
      ? <Button
      style={styles().backButton}
      icon={require("../../icons/backArrow.png")}
      onPress={() => {
        backButtonFunction.func();
        setCopyMode(false);
      }} abideByTheme={homeRoute !== "addNote" && homeRoute !== "viewNote"}
    />
    : null
    }
    <TouchableOpacity onPress={() => setHomeRoute("main")} style={styles().sideBarOption}>
      <Button
        icon={require("../../icons/home_web.png")}
        style={styles().button}
        iconStyle={styles(inMain, user.theme).buttonImage}
      />
      <LMText style={styles(inMain, user.theme).text}>Home</LMText>
    </TouchableOpacity>
    <TouchableOpacity onPress={() => setHomeRoute("notifications")}  style={styles().sideBarOption}>
      <Button
        icon={isUnreadNotification && !inNotifications ? require(`../../icons/bell2_web.png`) : require(`../../icons/bell_web.png`)}
        style={styles().button}
        iconStyle={styles(inNotifications, user.theme).buttonImage}
      />
      <LMText style={styles(inNotifications, user.theme).text}>Notifications</LMText>
    </TouchableOpacity>
    <TouchableOpacity onPress={() => setHomeRoute("account")} style={styles().sideBarOption}>
      <Button
        icon={require("../../icons/accountIconGray_web.png")}
        style={styles().button}
        iconStyle={styles(inAcc, user.theme).buttonImage}
      />
      <LMText style={styles(inAcc, user.theme).text}>Account{"\n"}Settings</LMText>
    </TouchableOpacity>

  </View>;
};

WebSideBar.propTypes = {
  homeRoute: PropTypes.string,
  isUnreadNotification: PropTypes.any,
  setCopyMode: PropTypes.func,
  setHomeRoute: PropTypes.func
};


const styles = (isBlack = false, theme = 1) => {
  const tintColor = {
    tintColor: isBlack ? (theme ? "black" : "white") : "white",
  };
  if(!isBlack)
    delete tintColor.tintColor;
  return StyleSheet.create({
    button: {
      width: 40,
      height: 50,
      backgroundColor: "transparent",
      margin: 0,
      marginTop: "5%",
    },
    buttonImage: {
      ...tintColor,
    },
    sideBar: {
      height: "100%",
      width: 110,
      backgroundColor: theme ? "#c3ccdb" : "#000000",
      alignItems: "center"
    },
    sideBarOption: {
      alignItems: "center",
      flexDirection: "column"
    },
    text: {
      color: isBlack ? (theme ? "black" : "white") : "#636363"
    },
    backButton: {
      height: 55, width: 55,
      backgroundColor: "transparent",
      position: "absolute",
      top: 0,
      left: 110,
    }
  });
};
// #d0d3d9
