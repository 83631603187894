import PropTypes from "prop-types";
import React from 'react';
import { Button } from './Button';
import { StyleSheet } from "react-native";

export const OKButton = ({ onClick, style={}, text="OK!", disabled=false }) => {
  return <Button 
    text={text} onPress={onClick}
    gradient={["#277cb9", "#000000"]}
    gradientStart={[0,0.5]} style={{...styles.button, ...style}}
    gradientEnd={[1.5,0]} textStyle={{color: "white"}}
    disabled={disabled}
  />;
};

OKButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.bool,
  text: PropTypes.string
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    width: 120,
    borderRadius: 90,
    margin: 5
  },
});