import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View, TextInput,  Platform } from 'react-native';
import { InputText } from "../../components/elements/InputText";
import {Popup} from "../../components/elements/popup";
import { Button } from "../../components/elements/Button";
import * as Calendar from 'expo-calendar';
import { DateTimePickerButton } from "../../components/elements/DateTimePickerButton";
import { LMText } from "../../components/elements/Text";
import { AppContext } from "../../../AppContext";
import { OKButton } from "../../components/elements/OKButton";
import { DropDown } from "../../components/elements/DropDown";
import { CheckBox } from "../../components/elements/Checkbox";
import { TouchableOpacity } from "react-native";

const remindersTimes = [0, -5, -10, -15, -30, -60, -120, -1440, -2880, -10080];
const remindersTimesText = ["At time of event", "5 minutes before", "10 minutes before", "15 minutes before", "30 minutes before", "1 hour before", "2 hours before", "1 day before", "2 days before", "1 week before"];

export const AddEventToCalender = ({note, date, noteHeader}) => {
  const {Alert} = useContext(AppContext);
  const [isVisible, setIsVisible] = useState(false);

  const [header, setHeader] = useState(noteHeader);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [notes, setNotes] = useState("");
  const [calendars, setCalendars] = useState([]);
  const [id, setId] = useState(null);
  const [reminders, setReminders] = useState([false, false, false, false, false, false, false, false, false, false]);
  const [showReminders, setShowReminders] = useState(false);

  useEffect(() => {
    (async () => {
      const { status } = await Calendar.requestCalendarPermissionsAsync();
      if (status === 'granted') {
        await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT).then(data=>{
          setCalendars(data.filter(c=>c.allowsModifications));
        });
      }
    })();
  }, []);

  const onChangeHeader = (nativeEvent) => {
    setHeader(nativeEvent.text);
  };

  const onChangeNotes = (nativeEvent) => {
    setNotes(nativeEvent.text);
  };

  useEffect(() => {
    setHeader(note.header);
    setStartDate(date);
    setEndDate(date);
    setNotes("");
    setId(null);
  }, [isVisible, note]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if(new Date(date) > new Date(endDate))
      setEndDate(date);
  };

  const onSubmit = async () => {
    console.log(id);
    const { status } = await Calendar.requestCalendarPermissionsAsync();
    const statusReminders = Platform.OS === 'android' ? {status: 'granted'} : await Calendar.requestRemindersPermissionsAsync();
    if (status === 'granted' && statusReminders.status === 'granted') {
      const alarms = reminders.map((r, i)=>r ? ({relativeOffset: remindersTimes[i]}) : null).filter(r=>r);
      console.log(alarms);
      // eslint-disable-next-line no-unused-vars
      const eventID = await Calendar.createEventAsync(id, {
        title: header,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        notes: notes,
        allDay: new Date(startDate).getTime() === new Date(endDate).getTime(),
        timeZone: 'America/Phoenix',
        alarms: alarms
      }).then(()=>{
        Alert("Added Event!");
        setIsVisible(false);
      }).catch((err)=>{
        console.log(err);
        Alert("Unable to add event!", "Please check your app and calender permission");
        setIsVisible(false);
      });
    } else {
      Alert("Unable to add event!", "Please check your app and calender permission");
      setIsVisible(false);   
    }
  };
  
  return <>
  <Button icon={require("../../icons/dateIcon.png")} iconStyle={styles.dateIconImage} style={styles.dateIcon} onPress={()=>setIsVisible(true)}/>
  <Popup containerStyle={{padding: 0,}} isVisible={isVisible} close={()=>setIsVisible(false)} isKeyboardAvoid={false}>
    <LMText style={styles.header}>Add Event To Calender</LMText>
    <View style={{width: "110%"}}>
      <LMText style={styles.label}>Header</LMText>
      <InputText onChange={onChangeHeader} value={header} style={styles.textBox}/>
      <View style={{flexDirection: "row", justifyContent: "space-between"}}>
        <View style={styles.dateInput}>
          <LMText style={styles.label}>Start Date</LMText>
          <DateTimePickerButton style={{width: 150}} onDTChange={handleStartDateChange} field={{type: 'date', value: startDate}}/>
        </View>
        <View style={styles.dateInput}>
          <LMText style={styles.label}>End Date</LMText>
          <DateTimePickerButton style={{width: 150}} onDTChange={setEndDate} field={{type: 'date', value: endDate}} />
        </View>
      </View>
      <View style={{flexDirection: "row", justifyContent: "space-between"}}>
        <View style={styles.dateInput}>
          <LMText style={styles.label}>Start Time</LMText>
          <DateTimePickerButton style={{width: 150}} onDTChange={handleStartDateChange} field={{type: 'time', value: startDate}}/>
        </View>
        <View style={styles.dateInput}>
          <LMText style={styles.label}>End Time</LMText>
          <DateTimePickerButton style={{width: 150}} onDTChange={setEndDate} field={{type: 'time', value: endDate}} />
        </View>
      </View>
      <LMText style={styles.label}>Notes</LMText>
      <TextInput 
        style={styles.multilineInput} multiline={true} 
        onChange={onChangeNotes}
      />
      <LMText style={styles.label}>Choose calendar to add to: </LMText>
      <DropDown 
        data={calendars.map(c=>c.title)} onDropChange={(selectedItem) => setId(calendars.find(c=>c.title===selectedItem).id)}
        buttonStyle={{width: "100%", alignSelf: "center"}} abideByTheme={false}
      />
    </View>
    <TouchableOpacity style={{width: "100%", marginLeft: -55, }} onPress={()=>setShowReminders(!showReminders)}>
      <LMText style={{padding: 10, fontSize: 20, fontWeight: "bold"}}>{showReminders? "-" : "+"} Reminders</LMText>
    </TouchableOpacity>
    {showReminders
      ? <View  style={{width: "120%", justifyContent: "center"}}>
        <View style={{width: "100%", flexDirection: "column", flexWrap: "wrap", height: 200, alignContent: "center"}}>
          {
            remindersTimesText.map((r, i) => {
              return <View style={{flexDirection: "row", marginTop: 5}} key={i}>
                <CheckBox
                  isChecked={reminders[i]}
                  onClick={()=>{
                    var newReminders = [...reminders];
                    const numOfReminders = reminders.filter(r=>r).length;
                    if(numOfReminders > 1 && !reminders[i]) {
                      var removedOne  = false;
                      newReminders = newReminders.map(r2=>{
                        if(r2 && !removedOne) {
                          removedOne = true;
                          return false;
                        } else {
                          return r2;
                        }
                      });
                    }
                    newReminders[i] = !newReminders[i];
                    setReminders(newReminders);
                  }}
                  style={{ transform: [{ scaleX: 1.1 }, { scaleY: 1.1 }], marginRight: -7 }}
                />
                <LMText style={{fontSize: 18, marginLeft: 10}}>{r}</LMText>
              </View>;
            })
          }
        </View>
        <LMText style={{fontSize: 10,  marginLeft: 10, marginTop: -10}}>**Max of two reminders</LMText>
      </View>
      : null
    }
    <View style={styles.buttonCon}>
      <OKButton onClick={onSubmit} disabled={id == null}/>
    </View>
  </Popup>
  </>;
};

AddEventToCalender.propTypes = {
  date: PropTypes.any,
  note: PropTypes.object,
  noteHeader: PropTypes.any
};

const styles = StyleSheet.create({
  header: {
    fontSize: 25,
    marginTop: -15
  },
  textBox: {
    height: 40,
    fontSize: 17,
    alignSelf: "center",
    width: 320,
  },
  label: {
    fontSize: 20,
    alignSelf: "center",
  },
  buttonCon: {
    flexDirection: "row",
    justifyContent: "center", 
    right: -100,
  },
  multilineInput: {
    height: 80,
    margin: 6,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    padding: 10,
    width: 320,
    backgroundColor: "white",
    alignSelf: 'center'
  },
  dateIcon: {
    width: 40,
    height: 40,
    marginLeft: 10,
    backgroundColor: "transparent",
    paddingBottom: 10
  },
  dateIconImage: {
    tintColor: "black"
  },
  dateInput: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

  }
});