import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { Button } from '../elements/Button';
import { InputText } from '../elements/InputText';
import { HelpPopup, InvitePopup } from './topBar';
import { AnimatedMenu } from './animatedMenu';
import { getDataSave, setDataSave } from "../../utils/dataSaver";
import { AppContext } from "../../../AppContext";
import { REACT_APP_API_PASSWORD } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";

const FullLogo = require("../../icons/fullLogo.png");
const FullLogoDarkMode = require("../../icons/fullLogoDarkMode.png");

export const WebTopBar = ({ setHomeRoute, setSearchKey, searchKey}) => {
  const {reload, user, url} = useContext(AppContext);
  
  const [helpPopup, setHelpPopup] = useState(false);
  const [invitePopup, setInvitePopup] = useState(false);
  const [accountMenu, setAccountMenu] = useState(false);
  const [image64, setImage64] = useState("");

  const setImageCache = async (image) => {await setDataSave(`profile_image`, image);};
  const getImageCache = async () => {
    try {
      const value = await getDataSave(`profile_image`);
      if(value)
        setImage64(value);
    } catch(e) {null;}
  };


  useEffect(() => {
    getImageCache();
    if (user.image != null && user.image != "") {
      fetch(`${url}/lm_api/image`, {
        method: "get",
        headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: user.image, bucket: "users-prf" },
      })
        .then((res) => res.json())
        .then((image) => {
          setImage64("data:image/png;base64," + image);
          setImageCache("data:image/png;base64," + image);
        });
    }
    return () => {
      
    };
  }, [user]);


  const logOut = async () => {
    setAccountMenu(false);
    // setDataSave("userdata", " , ");
    // setDataSave("recents", "[]");
    try {
      await AsyncStorage.clear();
    } catch(e) {
      // clear error
    }
    reload();
  };

  return <View style={[styles.container, user.theme ? {} : {backgroundColor: "#000000"}]}>
    <View style={{flexDirection: "row", alignItems: "center"}}>
      <TouchableOpacity onPress={()=>setHomeRoute("main")}>
        <Image source={user.theme ? FullLogo : FullLogoDarkMode} style={styles.logo}/>
      </TouchableOpacity>
      
      <Button
        style={{
          ...styles.button,
          width: 190,
          height: 65,
        }}
        icon={require("../../icons/addCategory.png")}
        onPress={()=>setHomeRoute("addCategory")}
        abideByTheme={true}
      />
      <Button
        style={{
          ...styles.button,
          width: 63,
          height: 65,
        }} abideByTheme={true}
        icon={require("../../icons/help.png")}
        onPress={()=>setHelpPopup(true)}
      />
      <HelpPopup isVisible={helpPopup} close={()=> setHelpPopup(false)}/>
    </View>
    <View style={{flexDirection: "row", width: "30%", justifyContent: "flex-end", alignItems: "flex-end"}}>
      <InputText
        placeholder="search..."
        onChange={(nativeEvent) => setSearchKey(nativeEvent.text)}
        style={styles.search}
        value={searchKey}
        icon={require("../../icons/searchIcon.png")}
        onFocus={() => {
          setHomeRoute("searchScreen");
        }}
      />
      <Button
        style={styles.button}
        icon={image64 === "" || !image64 ? require("../../icons/accountIcon.png") : {uri: image64}}
        iconStyle={{borderRadius: 100}}
        onPress={()=>setAccountMenu(true)}
        testID="accountIcon"
      >
        <View
          style={{
            height: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingBottom: 30,
            right: 150,
            top: 50
          }}
        >
          <AnimatedMenu
            close={() => setAccountMenu(false)}
            show={accountMenu}
          >
            <Button
              text="Add Category"
              textStyle={styles.listTextItem}
              onPress={() => {
                setHomeRoute("addCategory");
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button
              text="Account Settings"
              onPress={() => {
                setHomeRoute("account");
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button
              text="Help"
              onPress={() => {
                setHelpPopup(true);
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button
              text="Invite a Friend"
              onPress={() => {
                setInvitePopup(true);
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button text="Log Out" onPress={logOut} style={styles.listItem} />
          </AnimatedMenu>             
        </View>
        
      </Button>
      <InvitePopup isVisible={invitePopup} close={() => setInvitePopup(false)} />
    </View>
  </View>;
};

WebTopBar.propTypes = {
  homeRoute: PropTypes.any,
  searchKey: PropTypes.any,
  setHomeRoute: PropTypes.func,
  setSearchKey: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    height: 80,
    width: "100%",
    backgroundColor: "#c3ccdb",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10
  },
  logo: {
    width: 160,
    height: 50
  },
  button: {
    width: 50,
    height: 50,
    backgroundColor: "transparent",
    marginTop: 0,
  },
  search: {
    minWidth: 100,
    width: "80%"
  },
  listItem: {
    backgroundColor: "#c3ccdb",
    justifyContent: "center",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    flex: 1,
    height: 70,
    padding: 10,
    alignItems: "center",

    width: 175,
    marginBottom: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 4,
    elevation: 16,
    alignSelf: "flex-end",
  },
  listTextItem: {
    height: 80,
    textAlignVertical: "center",
    paddingTop: 5
  }
});