import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Popup } from "../../../components/elements/popup";
import { View, StyleSheet } from "react-native";

import { REACT_APP_API_PASSWORD } from "@env";
import { ImagePicker } from "../../../components/elements/ImagePicker";
import { CreateImageFormData } from "../../../utils/utils";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { OKButton } from "../../../components/elements/OKButton";

export const ChangeProfileImage = ({ isVisible, close }) => {
  const {user, url, reload, setReload} = useContext(AppContext);
  const [image, setImage] = useState("");
  const [image64, setImage64] = useState("");

  useEffect(() => {
    if (user.image == null) return;
    fetch(`${url}/lm_api/image`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: user.image, bucket: "users-prf" },
    })
      .then((res) => res.json())
      .then((image) => {
        setImage64(image);
      });
  }, [isVisible, user]);

  const onSubmit = () => {
    setReload(true);
    fetch(`${url}/lm_api/changeProfilePhoto`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        imageName: uploadImage(),
        id: user.id,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        reload();
        close();
      });
  };

  const onImagePick = (image, smallImage) => {
    setImage(smallImage.uri);
    setImage64(smallImage.uri);
  };

  const uploadImage = () => {
    if (image == "") return "";
    let newName =
      user.image != null && user.image.includes(".")
        ? user.image.split(".")[0]
        : `${user.id}-${new Date().getTime()}`;

    fetch(`${url}/lm_api/image`, {
      method: "POST",
      body: CreateImageFormData(image, newName),
      headers: {
        bucket: "users-prf"
      },
    }).then(() => {
      reload();
    });
    return newName + ".jpeg";
  };

  return (
    <Popup isVisible={isVisible} style={styles.popup} close={close}>
      <LMText style={styles.header}>Change Profile Photo</LMText>

      <ImagePicker callback={onImagePick} defaultValue={"data:image/png;base64," + image64} setReload={setReload} imageStyle={{borderRadius: 300, width: 300, height: 300}}/>

      <View style={styles.flexRow}>
        <OKButton onClick={onSubmit} />
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  popup: {
    width: 300,
  },
  header: {
    fontSize: 30,
    marginLeft: 70,
    marginRight: 70,
    fontWeight: "500",
    textAlign: "center"
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    right: -100,
  },
});
ChangeProfileImage.propTypes = {
  close: PropTypes.func,
  image: PropTypes.any,
  isVisible: PropTypes.any,
  reload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any,
    image: PropTypes.string,
  }),
};
