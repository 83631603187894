import AsyncStorage from '@react-native-async-storage/async-storage';

export const getDataSave = async (key) => {  
  try { 
    return await AsyncStorage.getItem("@" + key);
  } catch (error) {
    console.log(error);
  }
};
export const setDataSave = async (key, val) => {  
  try {    
    await AsyncStorage.setItem("@" + key,val);  
  } catch (error) {
    console.log(error);
  }
};