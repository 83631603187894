import PropTypes from "prop-types";
import React from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { Button } from '../../components/elements/Button';
import { TextAbstract } from "../../utils/utils";
import { LMText } from "../../components/elements/Text";

export const SearchScreen = ({notes, EnterViewNote, searchKey}) => {
  var filteredNotes = notes.filter(n=> {
    const fields = JSON.parse(n.feilds);
    const foundInFields = fields.map(f => {
      if(!f.value) return false;
      if(f.type === 'photo')
        return false;
      return f.value.toString().toLowerCase().includes(searchKey.toLowerCase());
    });
    return (n.header.toLowerCase().includes(searchKey.toLowerCase()) || foundInFields.includes(true)) && searchKey !== "";
  }
  );
  return <ScrollView scrollEnabled={false} contentContainerStyle={{height: "100%", paddingTop: 40}} style={{flex: 1, flexDirection: "column", height: "100%"}}>
    <LMText style={styles.searchTitle}>Notes that match your search:</LMText>
    <ScrollView contentContainerStyle={styles.notesContainer}>
      {
        filteredNotes.map((note, i)=>{
          return <Button 
            key={i} style={styles.note}  icon={require("../../icons/recent_note.png")}
            onPress={()=>EnterViewNote(note)} iconStyle={styles.noteIcon}
            >
            <LMText abideByTheme={false} style={{width: "60%", textAlign: "center"}}>{note.header==="" ? "No Title" : TextAbstract(note.header, 22)}</LMText>
          </Button>;
        })
      }
      {filteredNotes.length == 0
        ?<LMText style={styles.noNotesText}>No notes...</LMText>
        : null
      }
    </ScrollView>
  </ScrollView>;
};

SearchScreen.propTypes = {
  EnterViewNote: PropTypes.func,
  notes: PropTypes.array,
  searchKey: PropTypes.string
};

const styles = StyleSheet.create({
  searchTitle: {
    fontSize: 25,
    marginLeft: 20
  },
  notesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 20,
    marginTop: 30
  },
  note: {
    backgroundColor: "transparent",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: 110,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: "center",
    height: 120,
  },
  noNotesText: {
    fontSize: 15
  },
  noteIcon: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 21,
    },
    shadowOpacity: 0.48,
    shadowRadius: 11.95,
    elevation: 18, 
  },
});