import React, { useContext, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Image, StyleSheet, View, Animated, Platform, } from 'react-native';
import { Button } from '../../../components/elements/Button';
import {REACT_APP_API_PASSWORD} from "@env";
import { IsJsonString, LightenDarkenColor, SetFontSizeToFit, TextAbstract, adminColor, regularColor, sharedColorNotes, sortNotes } from '../../../utils/utils';
import { LinearGradient } from 'expo-linear-gradient';
import { InView } from 'react-native-intersection-observer';
import { AppContext } from '../../../../AppContext';
import { showDeleteCategoryConfirm } from '../categoryDisplay';
import { ShareNotePopup } from '../shareNotePopup';
import { LMText } from '../../../components/elements/Text';
import { getDataSave, setDataSave } from '../../../utils/dataSaver';
import { Popup } from '../../../components/elements/popup';
import { TouchableOpacity } from 'react-native';
const noteIcon = require("../../../icons/recent_note.png");

import plusIcon from "../../../icons/plus.png";
import editIcon from "../../../icons/edit.png";
import deleteIcon from "../../../icons/trash.png";
import copyIcon from "../../../icons/copy.png";
import shareIcon from "../../../icons/shareNoteBlack.png";
import { FlatList } from 'react-native';
import { ImportFromCalender } from '../importFromCalander';

//const sharedColor = "#4472b8";
// const adminColor = "#00c921";
// const regularColor = "#30e629";

const FS = 14;
export const noteSize = Platform.OS === "web" ? 85 : 75;

export const Category = ({category, AddNewNote, EnterViewCat, notes, EnterViewNote,setSelectedCategory, selectedCategory, setHomeRoute, addCategory, isMoving, widthAnim, heightAnim, openMenu, setCopyCategory, removeCat}) => {
  const { url, setReload, reload, user, Alert} = useContext(AppContext);
  const [image, setImage] = useState("");
  const [currentFont, setCurrentFont] = useState(FS);
  const onDelete = () => {
    console.log("object");
    openMenu();
    removeCat(category);
  };
  const deleteCategory = showDeleteCategoryConfirm(url, reload, setReload, category, onDelete);
  const [sharepopup, setSharepopup] = useState(false);
  const [opening, setOpening] = useState(false);
  const [actionPopup, setActionPopup] = useState(false);
  const [calenderPopup, setCalenderPopup] = useState(false);
  const [loadingImages, setloadingImages] = useState(false);

  const setImageCache = async (image) => {await setDataSave(`category_image_${category.id}`, image);};
  const getImageCache = () => {
    try {
      const value = getDataSave(`category_image_${category.id}`);
      setImage(value&&value.length > 10 ? value : "");
      //console.log(value&&value.length);
      //AsyncStorage.clear();
      if(value&&value.length)
        return true;
    } catch(e) {console.log(e);}
    return false;
  };

  useEffect(()=>{
    if(category.image == null || category.image=="") {
      setImage("");
      setImageCache("");
      setloadingImages(false);
      return;
    }
    if(getImageCache()) return;

    
    setloadingImages(true);
    fetch(`${url}/lm_api/image`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: category.image, bucket: "cat" },
    }).then((res) => res.json()).then((image) => {
      // category.imageValue = image;
      setImage(image);
      setImageCache(image);
      setloadingImages(false);
    });
    return () => {
      
    };
  }, [category.image]);
  
  const showClearCategoryConfirm = () => {
    return Alert(
      "Really Clear All Notes?",
      "This action CAN NOT be reversed!",
      [
        {text: "No",},
        {text: "Yes", onPress: () =>clearCategory()},
      ]
    );
  }; 

  const clearCategory = () => {
    setReload(true);
    fetch(`${url}/lm_api/category/notes`, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({password: REACT_APP_API_PASSWORD, id: category.id}),
    }).then((res) => res.json()).then(() => {
      reload();
      setReload(false);
      Alert("Cleared category");
      setloadingImages(false);
      notes = notes.filter(n=>n.categoryId!==category.id);
    }).catch((err) => console.log(err));
  };

  const onCatClick = () => {
    setOpening(true);
    setTimeout(() => {
      openMenu(category);
      setOpening(false);
    }, 180);
  };

  var actions = [
    {icon: plusIcon, press: ()=>AddNewNote(category), addStyle: {}, text: "Add Note"},
    {icon: editIcon, press: ()=>EnterViewCat(category), addStyle: {}, text: "Edit Category"},
    {icon: deleteIcon, press: ()=>{deleteCategory();}, addStyle: {}, text: "Delete Category"},
    {icon: shareIcon, press: ()=>{setSharepopup(true);}, addStyle: {marginLeft: 5, width: 30, alignSelf: "flex-end"}, text: "Share Category"},
    {icon: copyIcon, press: ()=>{setCopyCategory(category);addCategory();}, addStyle: {width: 35}, text: "Clone Category"},
    {icon: require("../../../icons/tableview.png"), press: ()=>{setSelectedCategory(category);setHomeRoute("table");}, addStyle: {}, text: "Table View"},
    {icon: require("../../../icons/dateIcon.png"), press: ()=>setCalenderPopup(true), addStyle: {}, text: "Import Calender"},
    {icon: require("../../../icons/clean.png"), press: ()=>showClearCategoryConfirm(), addStyle: {width: 33}, text: "Clear Notes"}
  ];
  if(category.id === -1) {
    actions = [actions[0], actions[7]];
  } else {
    if(!((category.permission === 1 || !category.shared))) {
      actions.splice(7, 1);
      actions.splice(6, 1);
    }
    else if(Platform.OS === "web") {
      actions.splice(6, 1);
    }
  
    if(!((category.permission === 1 || !category.shared) && (user.admin || !category.adminCategory))) {
      actions.splice(3, 1);
    }
  
    if(!(user.admin || !category.adminCategory)) {
      actions.splice(2, 1);
    }
  
    if(!((category.permission === 1 || !category.shared) && (category.adminCategory !== 1||user.admin===1))) {
      actions.splice(1, 1);
    }
  
    if(!(category.permission === 1 || !category.shared)) {
      actions.splice(0, 1);
    }
  }

  const color = IsJsonString(category.color) ? JSON.parse(category.color) : null;
  if(selectedCategory && selectedCategory.id === category.id) {
    return <Animated.View style={{width: widthAnim, height: heightAnim, marginBottom: 30}}>
        <Image 
          source={require("../../../icons/category.png")} 
          style={{
            position: "absolute", 
            width: "100%", height: "115%",
            alignSelf: "center",
            resizeMode: "stretch",
            tintColor: color === null ? (category.shared ? sharedColorNotes : (!category.adminCategory ? regularColor : adminColor)) : LightenDarkenColor(color.color, -color.darkness)
          }}
        />
        <View
          style={{
            position: "absolute", 
            width: "100%", height: "100%",
            alignSelf: "center",
          }}
        >
          <View style={styles.catFunctions}>
            <View style={{flexDirection: "row", width: "70%"}}>
              <Image source={loadingImages  ? require("../../../icons/imgLoadingWide.png") : (image=="" ? require("../../../icons/category_no_image2.png") : {uri: "data:image/png;base64," + image})} style={styles.catFunction}/>
              {category.toUser
                ? <LinearGradient
                  start={[0, 0]}
                  end={[1,0]}
                  colors={['transparent', 'gray']}
                  style={{
                    width: 30,
                    top: 5,
                    height: "75%",
                    left: 90,
                    borderRadius: 5,
                    position: "absolute"
                  }}>
                  <Image source={require("../../../icons/is_shared.png")} style={styles.sharedIcon}/>
                </LinearGradient> 
                : null
              }
              <LMText 
                abideByTheme={false}
                style={{fontSize: 20,fontWeight: "800", alignSelf: "center", marginLeft: 1, flex: 1, flexWrap: 'wrap', textAlign: "left"}}
              >{TextAbstract(category.name, 33)}</LMText>
            </View>
            {isMoving
              ? null
              : <>
                <View style={{height : 70, width: 80,flexWrap: "wrap", flexDirection: "row",}}>
                  {
                    actions.slice(0, actions.length > 4 ? 3 : actions.length).map((action, i)=>{
                      return <Button key={i} icon={action.icon} style={{...styles.categoryButton, ...action.addStyle}} onPress={action.press}/>;
                    })
                  }
                  {actions.length > 3
                    ? <Button icon={require("../../../icons/threeDots.png")} style={{...styles.categoryButton, height: 20, marginTop: 15}} onPress={()=>setActionPopup(true)}/>
                    : null
                  }
                  <ShareNotePopup close={()=>setSharepopup(false)} isButton={false} isVisible={sharepopup} note={{...category, categoryId: category.id, id: -1, isCategory: true}}/>
                </View>
              </>
            }
          </View>
          <FlatList style={styles.recentNotes} contentContainerStyle={styles.recentNotesIn}
            data={sortNotes(notes, category)}
            nestedScrollEnabled={true}
            renderItem={(item)=>{
              const note = item.item;
              return <Button 
                key={item.index} style={styles.note} iconStyle={styles.noteIcon} icon={noteIcon}
                onPress={()=>EnterViewNote(note)} 
              >
                <LMText abideByTheme={false} style={{width: "90%", textAlign: "center", marginTop: 7}}>{note.header===""?"No Title":TextAbstract(note.header, 22)}</LMText>
              </Button>;
            }}
          />
        </View>
        {!isMoving
          ? <Button
            icon={require("../../../icons/xWithCircle.png")}
            style={{position: "absolute", top: 7, left: 10, width: 25, height: 25, backgroundColor: "transparent"}}
            onPress={openMenu}
          />
          : null

        }
        <Popup isVisible={actionPopup} close={()=>setActionPopup(false)} containerStyle={{paddingBottom: 40}}>
          <LMText style={{fontSize: 25, fontWeight: 800}}>Category View Setting:</LMText>
          {
            actions.map((action, i)=>{
              return <TouchableOpacity key={i} onPress={()=>{setActionPopup(false);action.press();}}>
              <View  style={styles.actionLine}>
                <Button
                  key={i} icon={action.icon} 
                  style={{...styles.categoryButton, ...action.addStyle, position: "absolute", left: 0}} onPress={action.press}
                  iconStyle={{tintColor: "black"}}
                />
                <LMText style={{fontSize: 20, fontWeight: 800, marginLeft: 10, alignSelf: "center"}}>{action.text}</LMText>
              </View>
            </TouchableOpacity>;    
            })
          }
        </Popup>
        <ImportFromCalender 
          user={user} url={url} pass={REACT_APP_API_PASSWORD} 
          isVisible={calenderPopup} close={()=>setCalenderPopup(false)} category={category} 
          notes={notes.filter(n=>n.categoryId===category.id)}
          reload={reload} setReload={setReload} Alert={Alert}
        />
    </Animated.View>;
  }
  return <InView style={{ width: 115, height: 100}}>
   <View key={category.id} style={{...styles.category, }} >
      <Button 
        style={{...styles.categoryIn, zIndex: -1, backgroundColor: "transparent", ...styles.shadow}}  
        onPress={opening && (category.permission === 1 || !category.shared) ? ()=>AddNewNote(category) : onCatClick}
        onLongPress={()=>{setSelectedCategory(category);setHomeRoute("table");}} testID='categoryButton'
      >
        <Image 
          source={require("../../../icons/category.png")} 
          style={{
            position: "absolute", 
            width: "100%", height: "100%",
            tintColor: color === null ? (category.shared ? sharedColorNotes : (!category.adminCategory ? regularColor : adminColor)) : LightenDarkenColor(color.color, -color.darkness)
          }}
        />
        <View style={{width: 100, height: "60%", position: "absolute", top: "10%"}}>
          <Image source={loadingImages ? require("../../../icons/imgLoadingWide.png") : (image=="" ? require("../../../icons/category_no_image2.png") : {uri: "data:image/png;base64," + image})} style={styles.category_image}/>
          {category.toUser
            ? <LinearGradient
              start={[0, 0]}
              end={[1,0]}
              colors={['transparent', 'gray']}
              style={{
                width: 30,
                height: "100%",
                right: 0,
                borderRadius: 5,
                position: "absolute"
              }}>
              <Image source={require("../../../icons/is_shared.png")} style={styles.sharedIcon}/>
            </LinearGradient> 
            : null
          }
        </View>
        <View style={{position: "absolute", bottom: 10}}>
          <LMText 
            abideByTheme={false}
            style={{fontSize: FS, ...styles.categoryName}}
            numberOfLines={ 1 } adjustsFontSizeToFit
            onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
          >{TextAbstract(category.name, 12)}</LMText>
        </View>
      </Button>
    </View>    
  </InView>;
 
  
};

Category.propTypes = {
  AddNewNote: PropTypes.func,
  EnterViewCat: PropTypes.func,
  EnterViewNote: PropTypes.func,
  addCategory: PropTypes.func,
  category: PropTypes.shape({
    adminCategory: PropTypes.number,
    color: PropTypes.string,
    feilds: PropTypes.any,
    fields: PropTypes.any,
    id: PropTypes.any,
    image: PropTypes.string,
    imageValue: PropTypes.any,
    name: PropTypes.any,
    permission: PropTypes.number,
    reverseSort: PropTypes.any,
    shared: PropTypes.any,
    sortBy: PropTypes.any,
    toUser: PropTypes.any
  }),
  closeMenu: PropTypes.any,
  heightAnim: PropTypes.any,
  i: PropTypes.number,
  isMoving: PropTypes.any,
  notes: PropTypes.array,
  openMenu: PropTypes.func,
  removeCat: PropTypes.func,
  selectedCategory: PropTypes.shape({
    id: PropTypes.any
  }),
  setCopyCategory: PropTypes.func,
  setHomeRoute: PropTypes.func,
  setSelectedCategory: PropTypes.func,
  url: PropTypes.any,
  widthAnim: PropTypes.any
};

const styles = StyleSheet.create({
  category: {
    margin: "0.5%",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: "100%",
    alignItems: 'center',
    justifyContent: "flex-start",
    height: "100%",
    zIndex: -1
  },
  categoryIn: {
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: "100%",
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: "#000",
    height: "100%",
  },
  category_image: {
    width: "100%",
    height: "100%",
    borderRadius: 5
  },
  categoryName: {
    marginTop: 10,
    fontWeight: "400",
    zIndex: 80,
  },
  sharedIcon: {
    width: 20,
    height: 40,
    position: "absolute",
    top: 10,
    right: 2,
    zIndex: 101
  },
  recentNotes: {
    width: "100%",
    height: "95%",
    marginTop: 15,
    flexGrow: 1,
  },
  recentNotesIn: {
    flexDirection: 'row',
    flexWrap: "wrap",
  },
  note: {
    backgroundColor: "transparent",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: noteSize,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: "center",
    height:noteSize,
    margin: 5
  },

  noteIcon: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 21,
    },
    shadowOpacity: 0.48,
    shadowRadius: 11.95,
    elevation: 18, 
    width: noteSize,
    height:noteSize,
  },

  catFunction: {
    width: 120,
    height: 60,
    marginRight: 10,
    backgroundColor: "white",
    borderRadius: 5,
    marginTop: 5
  },
  shadow: Platform.OS === "web" ? {} : {
    shadowOffset:{
      width: 0,
      height: 21,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,
    elevation: 16,
  },
  categoryButton: {
    backgroundColor: "transparent",
    width: 40,
    height: 40,
    margin: 0,
  },
  catFunctions: {
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: 340,
    width: "90%",
    height: 80,
    flexWrap: "wrap",
    top: 25,
    alignItems: "center",
    marginBottom: 10,
    marginLeft: 10
  },
  actionLine: {
    marginTop: 1, 
    alignItems: "center", 
    width: 280, 
    flexDirection: "row", 
    justifyContent: "center", 
    backgroundColor: "#36a9f2", 
    borderRadius: 10,
    padding: 5
  }
});