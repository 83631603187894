import { registerRootComponent } from 'expo';
import React from 'react';
import { ModalPortal } from 'react-native-modals';
import App from './App';
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
const Root = () => {
  return (
    <>
      <App />
      <ModalPortal />
    </>
  );
};
registerRootComponent(Root);
