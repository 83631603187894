import { useEffect } from "react";
import { Platform } from "react-native";

export const useCrtlEnterSubmit = (onSubmit, changeArr) => {
  if(Platform.OS === "web") {
    const onClickEnter = (e) => {
      if(e.keyCode===13 && e.ctrlKe && onSubmit) {
        onSubmit();
      }
    };
  
    useEffect(() => {
      
      window.addEventListener('keydown', onClickEnter);
  
      return ()=> window.removeEventListener('keydown', onClickEnter);
    }, changeArr);
  }
};