import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Image, Animated, TouchableOpacity, Platform, FlatList} from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { Button } from '../../components/elements/Button';
import { InputText } from '../../components/elements/InputText';
import {REACT_APP_API_PASSWORD} from "@env";
import SortableList from 'react-native-sortable-list';
import {CheckBox} from '../../components/elements/Checkbox';
import { CreateImageFormData, IsJsonString, SetFontSizeToFit, capitalizeFirstLetter, regularColor, screenWidth } from '../../utils/utils';
import { ShareNotePopup } from './shareNotePopup';
import { ScrollView } from 'react-native-gesture-handler';
import { AutoScaleImage } from '../../components/elements/Image';
import { ImagePicker } from '../../components/elements/ImagePicker';
import { AppContext, BackButtonContext, MainScrollContext } from '../../../AppContext';
import { useCrtlEnterSubmit } from '../../components/elements/useCtrlEnter';
import { LMText } from '../../components/elements/Text';
import { DropDown } from "../../components/elements/DropDown";
import { MyColorPicker } from "../../components/elements/ColorPicker";
import { OKButton } from "../../components/elements/OKButton";
import { setDataSave } from "../../utils/dataSaver";

var appJson = require('../../../app.json');
const DS = 17.5;

const compare = ( a, b ) => {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
};

export const showDeleteCategoryConfirm = (url, reload, setReload, category, setHomeRoute=()=>{return null;}, setCopyMode=()=>{return null;}, closeMenu=()=>null) => {
  const {Alert} = useContext(AppContext);

  const deleteImage = () => {
    fetch(`${url}/lm_api/deleteImageS3`, {
      method: 'POST',
      body: JSON.stringify({password: REACT_APP_API_PASSWORD, imageName: category.image, bucket: "cat"}),
      headers: { "Content-Type": "application/json" },
    });
  };

  const deleteCategory = () => {
    setReload(true);
    if(category.shared) {
      console.log(category, url);
      fetch(`${url}/lm_api/share`, {
        method: "delete",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({password: REACT_APP_API_PASSWORD, id: category.shareId}),
      }).then((res) => res.json()).then(() => {
        setReload(false);
        reload();
        setHomeRoute("main");
      }).catch((err) => console.log(err));
      return;
    }
    deleteImage();
    fetch(`${url}/lm_api/categories`, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({password: REACT_APP_API_PASSWORD, id: category.id}),
    }).then((res) => res.json()).then(() => {
      reload();
      setCopyMode(false);
      setHomeRoute("main");
      closeMenu();
    }).catch((err) => console.log(err));
  };

  const showDeleteCategoryConfirm = () => {
    return Alert(
      "Really Delete?",
      "Deleting a category means deleting all of its notes.",
      [
        {text: "No",},
        {text: "Yes", onPress: () =>deleteCategory()},
      ]
    );
  }; 

  return showDeleteCategoryConfirm;
};

const FieldRow = ({active, toggleRowActive, field, fields, onDropChange ,deleteField, i, ValidateAll, onChange, fieldTypes, edit, currentFont, setCurrentFont, onFocus}) => {
  const disabled = !(field.newField && edit);

  const buttonStyle = {
    ...styles.buttonStyle,
    backgroundColor: disabled ? "transparent" : "white",
    borderWidth: 0,
  };
  
  return (
    <Animated.View style={active ? styles.fieldRowActive : {}}>
      <View style={[styles.textCell, {borderEndWidth:0, flexDirection: "row", alignItems: "center",}, disabled&&!edit ? {width: screenWidth*0.9, maxWidth: 500,alignSelf: "center"} : {}]} >
        {edit
          ?<View style={{flexDirection: "column"}} onTouchStart={fields.length > 1 ? toggleRowActive : ()=>null}>
          {Platform.OS !== "web"&&<><LMText style={textBoxStyles(fields).minusText}>-</LMText>
          <LMText style={textBoxStyles(fields).minusText}>-</LMText>
          <LMText style={textBoxStyles(fields).minusText}>-</LMText></>}
        </View>
          : null
        }
        <View style={{width: "50%"}}>
        {edit
          ? <InputText
              onChange={onChange} name={i.toString()} 
              defaultValue={field.name} placeholder="Field Name"
              style={textBoxStyles(field.valid).textBox}
              value={field.name} onBlur={ValidateAll} onFocus={onFocus}
            />
          : <LMText 
            style={{fontSize: DS, ...styles.label}}
            numberOfLines={ 1 } adjustsFontSizeToFit
            onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
          >{field.name}</LMText>
        }
        </View>
        
        {disabled
          ? <Button style={buttonStyle} abideByTheme={true} text={capitalizeFirstLetter(field.type)} textStyle={{fontSize: 20}}></Button>
          : <DropDown onDropChange={(selectedItem) => {onDropChange(i, selectedItem);}} defaultValue={field.type} data={fieldTypes.map(t=>t.name)}/>
        }
        

        {edit
          ?<Button 
          icon={require("../../icons/remove.png")} 
          style={{...styles.removeButton, opacity: fields.length > 1 ? 1 : 0}} 
          onPress={fields.length > 1 ? ()=>deleteField(i) : ()=>null}
          abideByTheme={true}
        />
          : null
        }
        
      </View>
    </Animated.View>
  );
};

FieldRow.propTypes = {
  ValidateAll: PropTypes.any,
  active: PropTypes.any,
  currentFont: PropTypes.any,
  deleteField: PropTypes.func,
  edit: PropTypes.any,
  field: PropTypes.shape({
    name: PropTypes.any,
    newField: PropTypes.any,
    type: PropTypes.any,
    valid: PropTypes.any
  }),
  fieldTypes: PropTypes.shape({
    map: PropTypes.func
  }),
  fields: PropTypes.shape({
    length: PropTypes.number
  }),
  i: PropTypes.shape({
    toString: PropTypes.func
  }),
  onChange: PropTypes.any,
  onDropChange: PropTypes.func,
  onFocus: PropTypes.any,
  setCurrentFont: PropTypes.any,
  toggleRowActive: PropTypes.any
};


export const CategoryDisplay = ({category, setHomeRoute, notes, setCanScroll, setSelectedCategory, copyMode, setCopyMode}) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);
  const {setBackButtonFunction} = useContext(BackButtonContext);
  const {setDoingAction} = useContext(MainScrollContext);

  const [edit, setEdit] = useState(true);
  const [name, setName] = useState(category.name);
  const [sortBy, setSortBy] = useState(category.sortBy || "Creation Time");
  const [reverseSort, setReverseSort] = useState(category.reverseSort);
  const [fields, setFields] = useState([{name: "", type: "text", valid: true}]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [currentFont, setCurrentFont] = useState(DS);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [adminCat, setAdminCat] = useState(false);
  const [specialSort, setSpecialSort] = useState(category.specialSort);

  const colorObject = category.color && IsJsonString(category.color) ? JSON.parse(category.color) : {color: regularColor, darkness: 0};
  const [color, setColor] = useState(colorObject.color);
  const [darkness, setDarkness] = useState(colorObject.darkness);

  const [image, setImage] = useState("");
  const [image64, setImage64] = useState("");

  const [sortingOptions, setSortingOptions] = useState(false);
  const [imageOptions, setSImageOptions] = useState(false);
  const [colorOptions, setColorOptions] = useState(false);

  const deleteCategory = showDeleteCategoryConfirm(url, reload, setReload, category, setHomeRoute, setCopyMode) ;

  useEffect(()=>{
    setAdminCat(category.adminCategory?true:false);
    fetch(`${url}/lm_api/fields`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, version: appJson.expo.version },
    }).then((res) => res.json()).then((types) => {
      setFieldTypes(types);
    }).catch((err) => console.log(err));

    if(copyMode) {
      copyCategory();
    }
  }, []);

  useEffect(() => {
    setBackButtonFunction({func: ()=>setHomeRoute("main")});
    
  }, [edit]);


  const updateFields = () => {
    var newFields = JSON.parse(category.feilds);
    newFields = newFields.map((f)=>{
      return {...f,valid: true, oldName: f.name};
    });
    setFields(newFields.slice(0,newFields.length));
    forceUpdate();
  };

  useEffect(()=>{
    updateFields();

    if(category.image == null || category.image=="")
      return;
    fetch(`${url}/lm_api/image`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: category.image, bucket: "cat" },
    }).then((res) => res.json()).then((image) => {
      setImage64('data:image/png;base64,' + image);
    });
  }, []);

  const addField = () => {
    const NewFields = fields.slice(0,fields.length);
    NewFields.push({name: "", type: "text", valid: true, newField: true});
    setFields(NewFields);
  };
  const deleteField = (i) => {
    if(fields.length > 1) {
      const NewFields = fields.slice(0,fields.length);
      NewFields.splice(i,1);
      setFields(NewFields);
    }
  };
  const onOrderChange = (key, currentOrder) => {
    let NewFields = fields.slice(0,fields.length);
    NewFields = currentOrder.map(n=>fields[Number(n)]);
    setCanScroll(true);
    setFields(NewFields);
  };
  const onChange = (nativeEvent, name) => {
    const NewFields = fields.slice(0,fields.length);
    if(sortBy === fields[name].name)
      setSortBy(nativeEvent.text);
    const index = Number(name);
    NewFields[index].name = nativeEvent.text;
    setFields(NewFields);
  };
  const onDropChange = (index, value) => {
    const NewFields = fields.slice(0,fields.length);
    NewFields[index].type = value;
    setFields(NewFields);
  };

  const validate = (event, index) => {
    const NewFields = fields.slice(0,fields.length);

    var allNames = [...fields];
    allNames.splice(index, 1);
    allNames = allNames.map(f=>f.name);

    if(fields[index].name == "" || allNames.includes(fields[index].name)) {
      NewFields[index].valid = false;
      setFields(NewFields);
      return false;
    } else {
      NewFields[index].valid = true;
      setFields(NewFields);
      return true;
    }
  };

  const ValidateAll = () => {
    return fields.map((f, i)=>{
      return validate({}, i);
    });
  };


  const onSubmit =() => {
    if(name.length < 2 || name[0] === ' ') {
      Alert("Can't edit category", "Title is invalid");
      return;
    }

    const valids = fields.map((f, i)=>{
      return validate({}, i);
    });
    const newFields = fields.map((f)=>{
      return {name: f.name, type: f.type, oldName: f.oldName};
    });
    if(valids.includes(false))
      return;

    const namesOnly = fields.map(f=>f.name);
    if(!namesOnly.includes(sortBy))
      setSortBy("Creation Time");

    setReload(true);

    const imageName = uploadImage();
    if(image.length > 22)
      saveCategory(category);

    fetch(`${url}/lm_api/categories`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        name, id: category.id,
        feilds: newFields, notes, admin: adminCat,
        sortBy, image: imageName, specialSort,
        reverseSort, color: JSON.stringify({color, darkness})
      }),
    }).then((res) => res.json()).then(() => {
      reload();
      category.color = JSON.stringify({color, darkness});
      category.name = name;
      category.feilds = JSON.stringify(newFields);
      category.sortBy = sortBy;
      setHomeRoute("main");
    });
  };

  const saveCategory = async (category) => await setDataSave(`category_image_${category.id}`, image64);

  const copyCategory =() => {
    setReload(true);
    fetch(`${url}/lm_api/categories`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        name: category.name + " - Copy", userId: user.id,
        feilds: JSON.parse(category.feilds), admin: 0,
        returnCat: true, specialSort: specialSort, sortBy: sortBy
      }),
    }).then((res) => res.json()).then((cats) => {
      reload();
      setSelectedCategory(cats.sort(compare)[cats.length - 1]);
      setName(name + " - Copy");

      setTimeout(()=>{
        setEdit(true);
        setReload(false);
        setCopyMode(true);
      }, 250);
    });
  };

  const onFocus = () => {

  };

  const uploadImage = () => {
      
    let newName = category.image!=null&&category.image.includes(".") ? category.image.split(".")[0] : `${user.id}-category-${new Date().getTime()}`;
    console.log(image);
    if(image=="")
      return newName + ".jpeg";

    fetch(`${url}/lm_api/image`, {
      method: 'POST',
      body: CreateImageFormData(image64, newName),
      headers: {
        bucket: "cat"
      },
    }).then(()=>{reload();});
    return newName + ".jpeg";
  };

  const onImagePick = (image, smallImage) => {
    setImage(smallImage.uri);
    setImage64(smallImage.uri);
  };
  
  useCrtlEnterSubmit(edit ? onSubmit : ()=>null, [name, fieldTypes, adminCat, image, sortBy, specialSort, reverseSort, edit]);
  return <ScrollView >
    <View style={styles.container}>
    {edit
      ?<InputText 
        placeholder={"Name"} onChange={(nativeEvent)=>setName(nativeEvent.text)} 
        defaultValue={name} style={styles.textBoxName}
      />
      : <LMText style={styles.header}>{name}</LMText>
    }
    {category.adminCategory
      ? <LMText style={{alignSelf: "flex-start", marginLeft: "10%", marginTop: 50, position: "absolute"}}>ADMIN CATEGORY</LMText>
      : null
    }
      {!edit
        ?<View style={styles.buttonCon}>
          {(user.admin || !category.adminCategory) && (category.permission === 1 || !category.shared)
            ?<>
            <ShareNotePopup note={{...category, categoryId: category.id, id: -1, isCategory: true}}/>
            <Button 
              icon={require("../../icons/trash.png")} style={styles.deleteButton}
              onPress={category.adminCategory !== 1||user.admin===1  
                ? deleteCategory
                : ()=>Alert("You can't delete admin category")
              }
            />
          </>
            : null
          }
          {category.permission === 1 || !category.shared
            ?<Button 
              icon={require("../../icons/edit.png")} style={styles.deleteButton}
              onPress={category.adminCategory !== 1||user.admin===1  ? ()=>setEdit(true) : ()=>Alert("You can't edit admin category")}
            />
            : null
          }
          <Button 
            icon={require("../../icons/copy.png")} style={{...styles.deleteButton, height:40}}
            onPress={copyCategory}
          />
        </View>
        : null
      }
     
        <Grid style={{width: "100%"}}>
          {Platform.OS === "web"
            ?<FlatList
              data={fields}
              renderItem={({ item })=>{
                return <FieldRow
                  field={item} active={false} fields={fields} onDropChange={onDropChange} deleteField={deleteField} 
                  i={fields.indexOf(item)} ValidateAll={ValidateAll} onChange={onChange} fieldTypes={fieldTypes} edit={edit}
                  currentFont={currentFont} setCurrentFont={setCurrentFont} toggleRowActive={null}
                  onFocus={onFocus}
                />;
              }}
              keyExtractor={item => item.id}
            />
            : <SortableList
            style={{height: fields.length * 53 + 10, width: "100%",}}
            contentContainerStyle={{width: "100%",}}
            data={fields}
            onActivateRow={()=>setCanScroll(false)}
            onReleaseRow={onOrderChange}
            renderRow={({data, active, toggleRowActive })=>{
              return <FieldRow
                field={data} active={active} fields={fields} onDropChange={onDropChange} deleteField={deleteField} 
                i={fields.indexOf(data)} ValidateAll={ValidateAll} onChange={onChange} fieldTypes={fieldTypes} edit={edit}
                currentFont={currentFont} setCurrentFont={setCurrentFont} toggleRowActive={toggleRowActive}
                onFocus={onFocus}
              />;
            }} 
            scrollEnabled={false}
            sortingEnabled={edit}
            manuallyActivateRows={true}
          />
          }
          
          {edit
            ?<>
              <Row style={{paddingTop: 0}}>
              <Col style={[styles.textCell, {flex: 1, alignItems: "center", borderBottomColor: "black", borderBottomWidth: 0.5, paddingBottom: 20}]}>
                  <Button style={[styles.addFieldButton, user.theme ? {} : {borderWidth: 0, backgroundColor: "#4d4d4d"}]} onPress={addField} disabled={fields.length > 19}>
                    <Image style={{width:20, height: 20, marginLeft: 15, tintColor: user.theme ? "black" : "white"}} source={require("../../icons/plus.png")}/>
                    <LMText>Add Field</LMText>
                  </Button>
                </Col>
              </Row>
            </>
            : null
          }
          {user.admin && edit
            ?<Row  style={[styles.dtRow, {paddingTop: 20}]}>
              <Col style={[styles.textCell, {alignItems:  "center", flex: 0.55, borderEndWidth:0}]}>
                <LMText>Admin Category</LMText>
              </Col>
              <Col style={[styles.textCell, {alignItems: "center", flex: 0.55, borderEndWidth:0}]}>
                <CheckBox
                  style={{padding: 15}}
                  onClick={()=>setAdminCat(!adminCat)}
                  isChecked={adminCat} abideByTheme={true}
                />
              </Col>
            </Row>
            : null
          }
          {edit
            ?<>
              <TouchableOpacity style={{width: "100%"}} onPress={()=>setSImageOptions(!imageOptions)}>
                <LMText style={{padding: 10, fontSize: 20, paddingLeft: "2.5%"}}>{imageOptions? "-" : "+"} Image Options</LMText>
              </TouchableOpacity>
              {imageOptions
                ?<View style={{flexDirection: "column", padding: 10, paddingLeft: "5%"}}>
                  <ImagePicker callback={onImagePick} setReload={setReload} defaultValue={image64}/>
                </View>
                : null
              }
              <TouchableOpacity style={{width: "100%"}} onPress={()=>setSortingOptions(!sortingOptions)}>
                <LMText style={{padding: 10, fontSize: 20, paddingLeft: "2.5%"}}>{sortingOptions? "-" : "+"} Sorting Options</LMText>
              </TouchableOpacity>
              {sortingOptions
                ?<View style={{padding: 10, paddingLeft: "5%"}}>
                  <View style={{flexDirection: "row"}}>
                    <LMText 
                      style={{fontSize: DS, ...styles.label, }}
                      numberOfLines={ 1 } adjustsFontSizeToFit
                      onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
                    >Sort by:</LMText>
                    <DropDown buttonStyle={{marginLeft: 10, width: 250}} onDropChange={(selectedItem) => {setSortBy(selectedItem);}} defaultValue={sortBy} data={["Creation Time", "Title"].concat(fields.map(f=>f.name))}/>                   
                  </View>

                  <View style={{flexDirection: "row", alignItems: "center"}}>
                    <LMText  style={{fontSize: 25}}>Reverse</LMText>
                    <CheckBox
                      style={{padding: 15}}
                      onClick={()=>setReverseSort(!reverseSort)}
                      isChecked={reverseSort} abideByTheme={true}
                      testID={"reverseSort"}
                    />
                  </View>
                  {fields.find(f=>f.name===sortBy) && fields.find(f=>f.name===sortBy).type === "date"
                    ?<View style={{flexDirection: "row", alignItems: "center"}}>
                      <LMText style={{fontSize: 25}}>Special Sort</LMText>
                      <CheckBox
                        style={{padding: 15}}
                        onClick={()=>setSpecialSort(!specialSort)}
                        isChecked={specialSort} abideByTheme={true}
                      />
                    </View>
                    : null
                  }
                </View>
                : null
              }
            </>
            : null
          }
          {edit
            ?<>
              <TouchableOpacity style={{width: "100%"}} onPress={()=>setColorOptions(!colorOptions)}>
                <LMText style={{padding: 10, fontSize: 20, paddingLeft: "2.5%"}}>{colorOptions? "-" : "+"} Color Options</LMText>
              </TouchableOpacity>
              {colorOptions
                ? <MyColorPicker darkness={darkness} setDarkness={setDarkness} color={color} setColor={setColor} setDoingAction={setDoingAction}/>
                : null
              }
            </>
            : null
          }
          {edit
            ?<Row style={{paddingBottom: 60}}>
              <Col style={[styles.textCell, {flex: 1}]}>
                <View style={styles.flexRow}>
                  <OKButton onClick={onSubmit}/>
                </View>
              </Col>
            </Row>
            : null
          }
        </Grid>
        {!edit
          ?<AutoScaleImage source={{uri: image64}} style={{width: 200}}/>
          : null
        }      
    </View>

  </ScrollView>;
};

CategoryDisplay.propTypes = {
  categories: PropTypes.any,
  category: PropTypes.shape({
    adminCategory: PropTypes.number,
    color: PropTypes.any,
    feilds: PropTypes.any,
    id: PropTypes.any,
    image: PropTypes.shape({
      includes: PropTypes.func,
      split: PropTypes.func
    }),
    name: PropTypes.string,
    permission: PropTypes.number,
    reverseSort: PropTypes.any,
    shared: PropTypes.any,
    sortBy: PropTypes.string,
    specialSort: PropTypes.any
  }),
  copyMode: PropTypes.any,
  notes: PropTypes.any,
  scrollTo: PropTypes.func,
  setCanScroll: PropTypes.func,
  setCopyMode: PropTypes.func,
  setHomeRoute: PropTypes.func,
  setSelectedCategory: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',    
  },
  header: {
    fontSize: 25,
    fontWeight: "400"
  },
  dtRow: {borderBottomWidth: 0, height: 50,},
  label: {
    fontSize: 25,
    marginRight: 10,
  },
  textCell: {
    borderRightColor: "transparent", 
    borderRightWidth: 1, 
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  deleteButton: {
    width: 35,
    height: 35,
    backgroundColor: "transparent",
    borderRadius: 0,
    marginRight: 10
  },
  buttonCon: {
    alignSelf: "flex-end",
    margin: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  textBoxName: {
    width: 400,
    height: 40,
    maxWidth: "60%"
  },
  addFieldButton: {
    width: 120,
    backgroundColor: "white",
    borderColor: "#d7d7d7",
    borderRadius: 6,
    borderWidth: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  removeButton: {
    width: 35, 
    height: 40, 
    backgroundColor:"transparent",
  },
  buttonStyle: {
    height: 35,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    backgroundColor: "white",
    width: "30%",
    maxWidth: 180,
    left: 5
  },
  fieldRowActive: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    shadowColor: "#000",
    shadowOffset:{
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 4,
    elevation: 16,
  },
});
const textBoxStyles = (valid) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  textBox: {
    borderColor: valid ? "#d7d7d7" : "red",
    borderWidth: 2,
    width: "95%",
    height: 30
  },
  // eslint-disable-next-line react-native/no-unused-styles
  minusText: {fontSize: 30, margin: -20, opacity: valid.length > 1 ? 1 : 0, marginRight: 5, paddingLeft: 15}
});