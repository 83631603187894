import React, { useContext, useEffect } from 'react';
import PropTypes from "prop-types";
import { StyleSheet, View } from 'react-native';

import { Button } from './Button';
import { Modal, ModalContent, SlideAnimation } from 'react-native-modals';
import { screenWidth } from '../../utils/utils';
import { AppContext } from '../../../AppContext';
import { Animated } from 'react-native';
import { Easing } from 'react-native';
import { Image } from 'react-native';

export const Popup = ({
  isVisible, close,
  hasCloseButton=true, children, containerStyle={}
}) => {
  const {isReload} = useContext(AppContext);

  let spinValue = new Animated.Value(0);
  useEffect(() => {
    if(!isReload || !isVisible) return;
    spinValue.setValue(0);
    Animated.loop(
      Animated.timing(
        spinValue,
      {
        toValue: 3,
        duration: 10000,
        easing: Easing.linear, // Easing is an additional import from react-native
        useNativeDriver: true  // To make use of native driver for performance
      }
      )
    ).start();
  }, [isReload]);
  
  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  });
  return <Modal
    visible={isVisible}
    onTouchOutside={close}
    modalStyle={{maxWidth: screenWidth, borderRadius: 25, backgroundColor: containerStyle.backgroundColor || "#fff"}}
    modalAnimation={new SlideAnimation({
      slideFrom: 'bottom',
    })}
  >
    <ModalContent style={[{padding: 20,}, containerStyle]}>
      <View style={styles.popupInside}>
        {hasCloseButton
          ? <Button icon={require("../../icons/xWithCircle.png")}  style={styles.xButton} onPress={close}/>
          : null
        }
        {children}

        <Modal visible={isReload} modalStyle={{backgroundColor: "transparent"}}>
        <ModalContent style={{padding: 0, backgroundColor: "transparent", justifyContent: "center", alignItems: "center"}}>
          <Animated.View style={{transform: [{rotate: spin},], backgroundColor: "transparent", height: 200, width: 200,}}>
            <Image source={require('../../icons/loading.png')} style={{width: 200, height: 200}} resizeMode="contain"/>
          </Animated.View>
        </ModalContent>

        </Modal>
      </View>      
    </ModalContent>

  </Modal>;

};

Popup.propTypes = {
  children: PropTypes.any,
  close: PropTypes.any,
  containerStyle: PropTypes.any,
  hasCloseButton: PropTypes.bool,
  isVisible: PropTypes.any,
  onContainerPress: PropTypes.func
};

const styles = StyleSheet.create({
  xButton: {
    width: 35,
    height: 35,
    backgroundColor: "transparent",
    alignSelf: "flex-start",
    position: "absolute",
    top: -10,
    left: -10
  },
  popupInside: {
    padding: 30,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center"
  },
});
