import PropTypes from "prop-types";
import React from 'react';

import "react-datepicker/dist/react-datepicker.css";
import { twoDigits } from "../../utils/utils";

const font = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`;
const formatTime = (d) => twoDigits(d.getHours()) + ":" + twoDigits(d.getMinutes());

export const DateTimePickerButton = ({field, onDTChange, index=0}) => {

  const fieldDate = new Date(field.value);
  const txt = fieldDate.toISOString().split('T');
  const dtText = field.type==="date" ? txt[0] : formatTime(fieldDate);

  return <>
      <input defaultValue={dtText}  min="00:00" max="23:59" pattern="[0-2][0-9]:[0-5][0-9]" type={field.type === "timer" ? "time" : field.type} onChange={(e)=>{
        const splited = e.target.value.split(':');
        onDTChange(field.type==="date" ? new Date(e.target.value) : new Date(null, null, null, splited[0], splited[1]), index);
      }} style={{borderRadius: "10px", width: "200px", fontFamily: font, fontWeight: "600", fontSize: "15px", height: "35px"}}/>
  </>;

};

DateTimePickerButton.propTypes = {
  dtValue: PropTypes.number,
  field: PropTypes.shape({
    name: PropTypes.any,
    type: PropTypes.string,
    value: PropTypes.any
  }),
  index: PropTypes.number,
  onDTChange: PropTypes.func,
  setDtValue: PropTypes.any,
  style: PropTypes.any
};
