import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Image, Platform, ScrollView, StyleSheet, TextInput, TouchableHighlight, TouchableOpacity, View } from "react-native";
import { Button } from "../../components/elements/Button";
import { Popup } from "../../components/elements/popup";
import {InputText} from "../../components/elements/InputText";
import {REACT_APP_API_PASSWORD} from "@env";
import SelectDropdown from "react-native-select-dropdown";
import { SwipeListView } from 'react-native-swipe-list-view';
import { AppContext } from "../../../AppContext";
import { LMText } from "../../components/elements/Text";

export const ShareNotePopup = ({note, isButton=true, isVisible=false, close=()=>null}) => {
  const { url, reload, user, Alert } = useContext(AppContext);

  const [sharePopup, setSharePopup] = useState(false);
  const [sharedUsersPopup, setSharedUsersPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [header, setHeader] = useState("");
  const [shares, setShares] = useState([]);
  const [message, setMessage] = useState("");
  const [permission, setPermission] = useState(0);
  const [messageFocused, setMessageFocused] = useState(false);

  const scrollRef = React.useRef();

  const getShares = () => {
    fetch(`${url}/lm_api/share`, {
      method: "get",
      headers: { "Content-Type": "application/json", id: note.id, categoryId: note.categoryId },
    }).then((res) => res.json()).then((res) => {
      setShares(res);
    }).catch((err) => console.log(err));
  };

  useEffect(()=>{
    getShares();
  }, [sharedUsersPopup, sharePopup]);

  const shareNote = () => {
    fetch(`${url}/lm_api/share`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD, noteId: note.id, 
        categoryId: note.categoryId, fromUser: note.userId, 
        toEmail: email, message, header, permission
      }),
    }).then((res) => res.json()).then((res) => {
      if(res.detail) {
        Alert(res.detail);
      } else {
        Alert("Shared!");
        setSharePopup(false);
        close();
        setSharedUsersPopup(false);
        reload();
        getShares();
      }
    }).catch((err) => console.log(err));
  };

  const scrollToEnd = () => {
    setTimeout(() => {
      scrollRef.current.scrollToEnd({animated: true});
    }, 1);
  };

  const shareName =  note.isCategory ? "category" : "note";
  
  const unshareNote = (share) => {
    if(share.permission === 1 || share.fromUser == user.id) {
      fetch(`${url}/lm_api/share`, {
        method: "delete",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: share.id
        }),
      }).then((res) => res.json()).then((res) => {
        if(res.detail) {
          close();
          Alert(res.detail);
        } else {
          Alert("Deleted Share!");
          setSharePopup(false);
          close();
          setSharedUsersPopup(false);
          reload();
          getShares();
        }
      }).catch((err) => console.log(err));
    }
    else {
      close();
      Alert("Unable to delete!", "You don't have the right permissions!");
    }
    reload();
  };

  const showDeleteShareConfirm = (item) => {
    return Alert(
      "Really Delete Share?",
      "This action CAN NOT be reversed!",
      [
        {text: "No",},
        {text: "Yes", onPress: () =>unshareNote(item)},
      ]
    );
  }; 


  const RenderHiddenItem = (data) => (
    <View style={styles.rowBack}>
        <TouchableOpacity
            style={[styles.backRightBtn, styles.backRightBtnRight]}
            onPress={() => unshareNote(data.item)}
        >
            <LMText style={styles.backTextWhite} >Delete</LMText>
        </TouchableOpacity>
    </View>
);

  const renderItem = data => (
    <TouchableHighlight
      style={styles.rowFront}
      underlayColor={'#AAA'}
    >
      <View style={{flexDirection: "row", height: 50, justifyContent: "space-between", alignItems: "center"}}>
        <View>
            <LMText>{data.item.firstName} {data.item.lastName} ({data.item.email})</LMText>
        </View>
        <Button onPress={() => showDeleteShareConfirm(data.item)} icon={require("../../icons/trash.png")} style={{width: 28, padding: 20, height: 28, borderColor: "black", borderWidth: 0.5, backgroundColor: "#ff3224", right: 0,}}/>      
      </View>

    </TouchableHighlight>
  );

  return <>
  {!isButton
    ? null
    : <Button 
        icon={shares.length>0 ? require("../../icons/is_shared.png") : require("../../icons/share.png")} style={styles.button}
        onPress={shares.length>0 ? ()=>setSharedUsersPopup(!sharedUsersPopup) : ()=>setSharePopup(!sharePopup)}
      />
  }

    <Popup isVisible={sharePopup || (!isButton && isVisible && shares.length==0)} close={()=>{setSharePopup(false);close();}}>
      <View style={{height: 570}}>
        <ScrollView style={{width: "100%"}} ref={scrollRef}>
          <LMText style={styles.header}>Share {shareName.charAt(0).toUpperCase()+shareName.slice(1)}</LMText>
          <LMText style={styles.subheader}>Share your {shareName} with...</LMText>
          <InputText style={styles.textBox} autoCapitalize='none' placeholder="Type Email..." onChange={(nativeEvent)=>setEmail(nativeEvent.text)}/>
          <View
            style={{
              borderBottomColor: 'black',
              borderBottomWidth: StyleSheet.hairlineWidth,
              marginBottom: 10
            }}
          />
          <View style={{width: 200, alignSelf: "flex-start", marginLeft: 10}}>
            <InputText style={[{width: 200}, styles.textBox]} placeholder="Title..." onChange={(nativeEvent)=>setHeader(nativeEvent.text)}/>
          </View>
          <TextInput 
            style={styles.multilineInput} multiline={true} 
            onChange={(e)=>setMessage(e.nativeEvent.text)} onBlur={()=>setMessageFocused(false)}
            onFocus={()=>{setMessageFocused(true);scrollToEnd();}}
          />
          <LMText style={styles.subheader}>Permission</LMText>
          <SelectDropdown
              defaultValue={"None"}
              dropdownIconPosition="right"
              data={["Read Only", "Read & Write"]}
              onSelect={(selectedItem, index) => setPermission(index)}
              buttonStyle={styles.buttonStyle}
              defaultButtonText={["Read Only", "Read & Write"][permission]}
              renderDropdownIcon={()=>
                <Image style={{width: 20, height: 20}} source={require("../../icons/down_arrow.png")}/>
              }
            />
          <View style={styles.buttonCon}>
            <Button 
              text="Cancel" onPress={()=>{setSharePopup(false);close();}}
              style={styles.backButton}
            />
            <Button 
              text="OK!" onPress={shareNote}
              gradient={["#277cb9", "#000000"]}
              gradientStart={[0,0.5]} style={styles.submit}
              gradientEnd={[1.5,0]} textStyle={{color: "white"}}
            />
          </View>
          {messageFocused && <View style={{height: 200}}/>}
        </ScrollView>
      </View>
    </Popup>
    {shares.length > 0&&
  <Popup isVisible={shares.length > 0 && !sharePopup && (sharedUsersPopup||isVisible)} close={()=>{setSharedUsersPopup(!sharedUsersPopup);close();}}>
  <View style={{height: 310}}>
    <LMText style={styles.header}>Shared With...</LMText>
    <LMText style={{...styles.subheader, color: "gray", fontSize: 20, marginTop: -20}}>This Category/Note is shared with:</LMText>
    <SwipeListView
        data={shares}
        renderItem={renderItem}
        renderHiddenItem={Platform.OS === "web" ? null : RenderHiddenItem}
        leftOpenValue={0}
        rightOpenValue={-75}
        previewRowKey={'0'}
        previewOpenValue={-40}
        previewOpenDelay={3000}
      />
    <Button 
      text="Share with another one!" onPress={()=>{setSharedUsersPopup(false);setSharePopup(true);}}
      style={styles.shareButton}
    />
  </View>
</Popup>
  }
  </>;
};

ShareNotePopup.propTypes = {
  close: PropTypes.func,
  isButton: PropTypes.bool,
  isVisible: PropTypes.bool,
  note: PropTypes.shape({
    categoryId: PropTypes.any,
    id: PropTypes.any,
    isCategory: PropTypes.any,
    shared: PropTypes.any,
    userId: PropTypes.any
  }),
  reload: PropTypes.any,
  url: PropTypes.any
};

const styles = StyleSheet.create({
  button: {
    width: 30,
    height: 60,
    backgroundColor: "transparent",
    marginRight: 10,
    paddingTop: 5,
    marginTop: Platform.OS === "web" ? 0 : 5,
    //paddingBottom: 15
  },
  header: {
    fontSize: 30,
    marginBottom: 30,
    textAlign: "center"
  },
  subheader: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: "center"
  },
  buttonCon: {
    marginTop: 10,
    flexDirection: "row",
    alignSelf: "center"
  },
  buttonStyle: {
    width: 200,
    height: 35,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    backgroundColor: "white",
    alignSelf: "center"
  },
  submit: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5
  },
  backButton: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5,
    borderWidth: 2,
    borderColor: "#277cb9",
    backgroundColor: "transparent"
  },
  textBox: {
    height: 30
  },
  multilineInput: {
    height: 170,
    margin: 12,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    padding: 10,
    width: 300,
    backgroundColor: "white"
  },
  shareButton: {
    height: 40,
    width: 220,
    borderRadius: 70,
    margin: 5,
    borderWidth: 2,
    borderColor: "#277cb9",
    backgroundColor: "transparent",
    alignSelf: "center"
  },
  backTextWhite: {
    color: '#FFF',
},
  rowFront: {
    //alignItems: 'flex-start',
    backgroundColor: '#FFF',
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    justifyContent: "center",
    paddingLeft: 20,
    height: 50,
  },
  rowBack: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
  },
  backRightBtnRight: {
    backgroundColor: 'red',
    right: 0,
    height: 50,
  },
});