import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Popup } from '../../components/elements/popup';
import {REACT_APP_API_PASSWORD} from "@env";
import { Button } from "../../components/elements/Button";
import { AutoScaleImage } from "../../components/elements/Image";
import { AppContext } from "../../../AppContext";

export const FullImagePopup = ({imageName, note, field, children, loadingImages}) =>{
  const { url } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if(field.bigValue) {
      setImage(field.bigValue);
    } else setImage(null);
    fetch(`${url}/lm_api/image`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: imageName},
    }).then((res) => res.json()).then((image) => {
      setImage('data:image/png;base64,' + image);
      note.feilds = JSON.stringify(JSON.parse(note.feilds).map(f=>{
        if(f.name===field.name)
          return {...f, bigValue: 'data:image/png;base64,' + image};
        return f;
      }));
    }).catch(err=>console.log(err));
  }, [open, note, field, imageName, loadingImages]);

  var childrenWithProps;

  if(children) {
    childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { image: image });
      }
      return child;
    });
  }
  console.log(loadingImages);
  return <>
    <Button style={{width: "100%", height: "90%", top: 40, position: "absolute", backgroundColor: "transparent"}} onPress={()=>setOpen(!open)}/>
    <Button icon={require("../../icons/fullScreen.png")} style={styles.icon} onPress={()=>setOpen(!open)}/>
    <Popup isVisible={open} hasCloseButton={false} containerStyle={{backgroundColor: "transparent", }} close={()=>setOpen(!open)}>
      <View style={styles.popup}>
      <Button icon={require("../../icons/ShrinkScreen.png")} style={styles.icon} onPress={()=>setOpen(!open)}/>
        {image && !loadingImages
          ? (children
            ? childrenWithProps
            : <ScrollView horizontal={true} style={{alignSelf: "center"}}>
            <AutoScaleImage source={{uri: image}} style={{width: 600, borderRadius: 30}}/>
          </ScrollView>
          )
          : <Image source={require("../../icons/imgLoading.png")} style={{left: "3%"}}/>
        }
      </View>
    </Popup>
  </>;
};

const styles = StyleSheet.create({
  popup: {
    width: "100%",
    height: 700,
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: "transparent",
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 101
  }
});


FullImagePopup.propTypes = {
  children: PropTypes.bool,
  field: PropTypes.shape({
    bigValue: PropTypes.any,
    name: PropTypes.any
  }),
  imageName: PropTypes.any,
  loadingImages: PropTypes.any,
  note: PropTypes.shape({
    feilds: PropTypes.any
  }),
  url: PropTypes.any
};
