import PropTypes from "prop-types";
import React, { useContext, } from 'react';
import { StyleSheet, Platform, FlatList } from 'react-native';
import { AppContext } from "../../../AppContext";
import { LMText } from "../../components/elements/Text";
import { View } from "react-native";
import { ScrollView } from "react-native";
import { screenHeight, screenWidth, } from "../../utils/utils";
import { Row, Table } from "react-native-table-component";
import { NotificationRow } from "./notificationRow";


export const Notifications = ({setIsUnreadNotification, notifications}) => {
  const {url, reload} = useContext(AppContext);

  const changeShare = (shareId, type) => {
    fetch(`${url}/lm_api/share`, {
      headers: { 'Content-Type': 'application/json'},
      method: type,
      body: JSON.stringify({id: shareId})
    }).then(response=>response.json()).then(()=>{
      reload();
    }).catch(err=>console.log(err));
  };

  const tableHead = ["", "Date&Time", "From:", "Title", "Read"];
  const flexArr = [1.45, 3, 2, 3, 1];
  const borderStyle = Platform.OS == "android" ? {} : {borderStyle: {borderWidth: 1, borderColor: '#C1C0B9'}};

  return <View style={styles.container}>
    <LMText style={styles.title}>Notifications</LMText>
    <ScrollView style={styles.notificationsContainer} scrollEnabled={false}>
        <Table {...borderStyle}>
          <Row data={tableHead} flexArr={flexArr} style={styles.header} textStyle={styles.text}/>
        </Table>
        <View style={styles.dataWrapper}>
          <Table style={{width: screenWidth - (Platform.OS === "web" ? 110 : 0)}}>
            <FlatList 
              scrollEnabled={true}
              style={{height: screenHeight * 0.6 , paddingBottom: 200}}
              data={[...notifications, notifications[0]]}
              renderItem={(rowData) => (
                rowData.index === notifications.length ? <View style={{height: 100}}/> :
                <NotificationRow setIsUnreadNotification={setIsUnreadNotification} flexArr={flexArr} key={rowData.index} notification={rowData.item} changeShare={changeShare}/>
              )}
            />
          </Table>
          <View style={{height: 100}}/>
        </View>
    </ScrollView>

  </View>;
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  reload: PropTypes.func,
  setHomeRoute: PropTypes.any,
  setIsUnreadNotification: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any,
    lastName: PropTypes.any
  })
};

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    flex: 1,
  },
  title: {
    fontSize: 40,
    alignSelf: 'center',
    fontWeight: '700',
  },
  header: { height: 50, backgroundColor: "#eaeaea", },
  text: { textAlign: 'center', fontSize: 13, flexWrap: "wrap"},
  dataWrapper: { marginTop: -1, width: "100%" },
  notificationsContainer: {
    marginTop: 20,
    height: screenHeight * 0.6
  },
});