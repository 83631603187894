import { useState } from "react";
import { Dimensions, Platform } from "react-native";
import {decode as atob, encode as btoa} from 'base-64';
import { getDataSave, setDataSave } from "./dataSaver";

if (!global.btoa) {  global.btoa = btoa; }

if (!global.atob) { global.atob = atob; }

export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const screenWidth = Dimensions.get('window').width;
export const screenHeight = Dimensions.get('window').height;

export const screenWidthF = () => Dimensions.get('window').width;
export const screenHeightF =  () =>  Dimensions.get('window').height;
export const SetFontSizeToFit = (e, currentFont, setCurrentFont) => {
  const { lines } = e.nativeEvent;
  if (lines.length > 1) {
    setCurrentFont(currentFont - 1);
  }
};
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) || email==="";
};

export function useForceUpdate(){
  const [, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
function rgbToGrayscale(red, green, blue) {
  const gray = (red + green + blue) / 3;

  return [gray, gray, gray];
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const hexToGrayscale = (hex) => {
  const rbg = hexToRgb(hex);
  const grayScale = rgbToGrayscale(rbg.r, rbg.g, rbg.b);
  return rgbToHex(grayScale[0], grayScale[1], grayScale[2]);
};

export function brightnessByColor (color) {
  color = "" + color;
  var isHEX = color.indexOf("#") == 0, isRGB = color.indexOf("rgb") == 0;
  var m, r, b, g;
  if (isHEX) {
    const hasFullSpec = color.length == 7;
    m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    if (m) r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16), g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16), b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
  }
  if (isRGB) {
    m = color.match(/(\d+){3}/g);
    if (m) r = m[0], g = m[1], b = m[2];
  }
  if (typeof r != "undefined") return ((r*299)+(g*587)+(b*114))/1000;
}

const threeToSixHex = (hex) => {
  var hexcolor = hex.substring(1);
  if (hexcolor.length < 6) {
    hexcolor = hexcolor.padStart(6, '0');
  }
  return '#' + hexcolor;
};

export function LightenDarkenColor(col, amt) {
  
  var usePound = false;

  if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return threeToSixHex((usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16));

}

export const validateURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str) || str==="";
};
export const formatDate = (date)=> date.toLocaleDateString('default', { month: 'short', day: "numeric", year: "numeric" });
export const formatTime = (time)=> time.toLocaleTimeString('en-GB', {hour: '2-digit', minute:'2-digit'});
export const onlyUnique = (value, index, self) => self.findIndex(v => v.id === value.id && v.name === value.name) === index;

export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}

export function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}
export const toMysqlFormat = (date) => {
  return date.getFullYear() + "-" + twoDigits(1 + date.getMonth()) + "-" + twoDigits(date.getDate()) + " " + twoDigits(date.getHours()) + ":" + twoDigits(date.getMinutes()) + ":" + twoDigits(0);
};

export function TextAbstract(text, length) {
  if (text == null) {
      return "";
  }
  if (text.length <= length) {
      return text;
  }
  text = text.substring(0, length);
  return text + "...";
}

function compare( a, b ) {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
}


export const SortNotes = (a, b, sortBy, notes, fields)=>{
  if(sortBy==="Creation Time"||!fields) {
    return compare(a, b);
  }
  if(sortBy==="Title") {
    if ( a.header < b.header ) return -1;
    if ( a.header > b.header ) return 1;
    return 0;
  }

  const SBtype = JSON.parse(fields).find(f=>f.name===sortBy).type;
  const Avalue = JSON.parse(a.feilds).find(f=>f.name===sortBy).value;
  const Bvalue = JSON.parse(b.feilds).find(f=>f.name===sortBy).value;

  if(SBtype==="date"||SBtype==="time"||SBtype==="timer") {
    var keyA = new Date(Avalue);
    var keyB = new Date(Bvalue);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  }
  if(SBtype==="number") {
    if ( Number(Avalue) < Number(Bvalue) ) return -1;
    if ( Number(Avalue) > Number(Bvalue) ) return 1;
    return 0;
  }
  if(SBtype==="checkbox") {
    return (Avalue === Bvalue) ? 0 : (Avalue? -1 : 1);
  }
  if ( Avalue < Bvalue ) return -1;
  if ( Avalue > Bvalue ) return 1;
  return 0;
};

export const reverseIfNeeded = (array, isReverse) => {
  return isReverse ? array.reverse() : array;
};

export const setSpecialOrder = (notes, category) => {
  if(category.specialSort) {
    const SBtype = JSON.parse(category.feilds).find(f=>f.name===category.sortBy).type;
    if(SBtype==="date") {
      const allPassed = notes.filter(n=>{
        const value = JSON.parse(n.feilds).find(f=>f.name===category.sortBy).value;
        return new Date(value) < new Date().setHours(0,0,0,0);
      });
      const rest = notes.filter(n=>!allPassed.includes(n));
      return rest.concat(allPassed);
    }
  }
  return notes;
};

export const moveToFront = (data, matchingId) => {
  //find the index of the element in the array
  const index = data.findIndex(({id}) => id === matchingId);
  if(index !== -1) {
    //if the matching element is found, 
    const updatedData = [...data];
    //then remove that element and use `unshift`
    updatedData.unshift(...updatedData.splice(index, 1));
    return updatedData;
  }
  //if the matching element is not found, then return the same array
  return data;
};

export const whiteColor = "#c9c9c9";

export const SaveNewNote = async (id) => {
  var recentsArr = JSON.parse(await getDataSave("recents")) || [];
  if (!recentsArr.includes(id)) recentsArr.unshift(id);
  else {
    recentsArr.sort(function(x,y){ return x == id ? -1 : y == id ? 1 : 0; });
  }
  if (recentsArr.length > 20) recentsArr.pop();
  setDataSave("recents", JSON.stringify(recentsArr));
};

export function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export const CreateImageFormData = (data, name) => {
  console.log(Platform.OS);
  var formData = new FormData();
  if(Platform.OS === "web") 
    formData.append('photo', DataURIToBlob(data), name);
  else
    formData.append('photo', { uri: data, name: name, type: "image/jpeg" });

  return formData;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};


export const sortNotes = (notes, category) => {
  return setSpecialOrder(reverseIfNeeded(notes.filter(n=>n.categoryId===category.id).sort((a, b)=>SortNotes(a, b, category.sortBy, notes, category.feilds)), category.reverseSort), category);
};
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const adminColor = "#00b91e";
export const regularColor = "#53db34";
export const sharedColorNotes = "#36abf5";