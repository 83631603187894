import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Col, Grid, Row } from "react-native-easy-grid";
import { View, StyleSheet, Image, ScrollView, Platform } from "react-native";
import { Switch } from "react-native-paper";
import { Button } from "../../../components/elements/Button";
import { ChangeName } from "./changeName";
import { ChangePassword } from "./changePassword";
import { REACT_APP_API_PASSWORD } from "@env";
import { ChangePhone } from "./changePhone";
import { ChangeEmail } from "./changeEmail";
import { DeleteAccountPopup } from "./deleteAccountPopup";
import DZ from "../../../icons/danger_zone.png";
import { ChangeProfileImage } from "./changeProfileImage";
import { SetFontSizeToFit } from "../../../utils/utils";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { getDataSave, setDataSave } from '../../../utils/dataSaver';
var appjson = require('../../../../app.json');

const FS = 17.5;

const AccountDetailsTable = ({
  user,
  url,
  setChangePassP,
  setChangePhoneP,
  setChangeEmailP,
}) => {
  const [password, setPassword] = useState(user.password);
  const [seePass, setSeePass] = useState(false);

  const [currentFont, setCurrentFont] = useState(FS);

  useEffect(() => {

    fetch(`${url}/lm_api/decrypt`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        text: user.password,
      }),
    })
      .then((res) => res.json())
      .then((pass) => {
        console.log(pass, user.password);
        setPassword(pass||user.password);
      });
  }, [user]);
  const icon = !seePass
    ? require("../../../icons/eye.png")
    : require("../../../icons/eyeNot.png");

  return (
    <View style={{  width: "100%", padding: 10, paddingRight: 0 }}>
      {user.userType > 0 ? null : (
        <View>
          <LMText style={ChangeButton(user.theme).tableText}>Username</LMText>
          <View>
            <LMText style={{ fontSize: FS, margin: 10, marginLeft: 30 }}>
              {user.username}
            </LMText>
          </View>
        </View>
      )}
      <View>
        <LMText style={ChangeButton(user.theme).tableText}>Email</LMText>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <LMText
            style={{ fontSize: FS, margin: 10, marginLeft: 30 }}
            numberOfLines={1}
            adjustsFontSizeToFit
            onTextLayout={(e) =>SetFontSizeToFit(e, currentFont, setCurrentFont)}
          >
            {user.email.includes("appleid") ? "" : user.email}
          </LMText>
          {user.userType === 1 ? null : (
            <Button
              text="Change"
              style={ChangeButton(user.theme).changeButton}
              onPress={() => setChangeEmailP(true)}
              abideByTheme={true}
            />
          )}
        </View>
      </View>
      <View>
        <LMText style={ChangeButton(user.theme).tableText}>Phone No.</LMText>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <LMText style={{ fontSize: FS, margin: 10, marginLeft: 30 }}>
            {user.phone == "" ? "None" : user.phone}
          </LMText>
          <Button
            text="Change"
            style={ChangeButton(user.theme).changeButton}
            onPress={() => setChangePhoneP(true)}
            abideByTheme={true}
          />
        </View>
      </View>
      <View>
        <LMText style={ChangeButton(user.theme).tableText}>Password</LMText>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View
            style={{ flexDirection: "row", width: 140, alignItems: "center" }}
          >
            <LMText
              style={{
                flexWrap: "wrap",
                margin: 10,
                fontSize: FS,
                marginLeft: 30,
              }}
            >
              {seePass ? password : "•••••••"}
            </LMText>
            {user.userType > 0 ? null : (
              <Button
                icon={icon}
                style={styles.eyeIcon}
                onPress={() => setSeePass(!seePass)}
                abideByTheme={true}
              />
            )}
          </View>
          {user.userType === 1 ? null : (
            <Button
              text="Change"
              style={ChangeButton(user.theme).changeButton}
              onPress={setChangePassP}
              abideByTheme={true}
            />
          )}
        </View>
      </View>
    </View>
  );
};

AccountDetailsTable.propTypes = {
  setChangeEmailP: PropTypes.func,
  setChangePassP: PropTypes.any,
  setChangePhoneP: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.any,
    phone: PropTypes.string,
    theme: PropTypes.any,
    userType: PropTypes.number,
    username: PropTypes.any
  })
};
const StatsTable = ({ notes, categories }) => {
  const {user} = useContext(AppContext);

  const avg = Math.round(notes.length / (categories.filter(c=>notes.find(n=>n.categoryId==c.id)!=null||!c.adminCategory)).length);
  return (
    <Grid style={{ width: "100%", height: 100 }}>
      <Row style={styles.dtRow}>
        <Col style={textCellStyle(0.9).textCell}>
          <LMText style={ChangeButton(user.theme).tableText}>Number Of notes</LMText>
        </Col>
        <Col style={textCellStyle(0.3).textCell2}>
          <LMText style={{ fontSize: FS }} testID="notesNum">{notes.length}</LMText>
        </Col>
      </Row>
      <Row style={styles.dtRow}>
        <Col style={textCellStyle(0.9).textCell}>
          <LMText style={ChangeButton(user.theme).tableText}>Number of categories</LMText>
        </Col>
        <Col style={textCellStyle(0.3).textCell2}>
          <LMText style={{ fontSize: FS }} testID="categoryNum">
            {categories.filter((c) => !c.adminCategory).length}
          </LMText>
        </Col>
      </Row>
      <Row style={styles.dtRow}>
        <Col style={textCellStyle(0.9).textCell}>
          <LMText style={ChangeButton(user.theme).tableText}>Avg notes per category</LMText>
        </Col>
        <Col style={textCellStyle(0.3).textCell2}>
          <LMText style={{ fontSize: FS }} testID="averageNum">{avg}</LMText>
        </Col>
      </Row>
    </Grid>
  );
};

StatsTable.propTypes = {
  categories: PropTypes.array,
  notes: PropTypes.array
};
const switchStyle = { transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] };
const switchColor = "#2327fc";
const SecurityTable = ({
  twoFA,
  setTwoFA,
  ftId,
  setFtId,
  enablePass,
  setEnablePass,
}) => {
  const {user} = useContext(AppContext);
  return (
    <Grid style={{ width: "100%", alignItems: "left" }}>
      <Row style={styles.dtRowHigh}>
        <Col style={textCellStyle(0.8).textCell}>
          <LMText style={ChangeButton(user.theme).tableText}>Enable 2FA</LMText>
        </Col>
        <Col style={[textCellStyle().textCell2, { bottom: 5 }]}>
          <Switch
            trackColor={{ false: "white", true: switchColor }}
            ios_backgroundColor="white"
            style={switchStyle}
            onValueChange={() => setTwoFA(!twoFA)}
            value={twoFA}
          />
        </Col>
      </Row>
      <Row style={styles.dtRowHigh}>
        <Col style={textCellStyle(0.8).textCell}>
          <LMText style={ChangeButton(user.theme).tableText}>Enable Face/Touch ID</LMText>
        </Col>
        <Col style={[textCellStyle().textCell2, { bottom: 5 }]}>
          <Switch
            trackColor={{ false: "white", true: switchColor }}
            ios_backgroundColor="white"
            style={switchStyle}
            onValueChange={() => setFtId(!ftId)}
            value={ftId}
          />
        </Col>
      </Row>
      <Row style={styles.dtRowHigh}>
        <Col style={textCellStyle(0.8).textCell}>
          <LMText style={ChangeButton(user.theme).tableText}>Enable Passcode</LMText>
        </Col>
        <Col style={[textCellStyle().textCell2, { bottom: 5 }]}>
          <Switch
            trackColor={{ false: "white", true: switchColor }}
            ios_backgroundColor="white"
            style={switchStyle}
            onValueChange={() => setEnablePass(!enablePass)}
            value={enablePass}
          />
        </Col>
      </Row>
    </Grid>
  );
};

SecurityTable.propTypes = {
  enablePass: PropTypes.any,
  ftId: PropTypes.any,
  setEnablePass: PropTypes.func,
  setFtId: PropTypes.func,
  setTwoFA: PropTypes.func,
  twoFA: PropTypes.any,
  user: PropTypes.any,
};

export const AccountSettings = ({ notes, categories }) => {
  const {user, url, reload } = useContext(AppContext);
  // const [twoFA, setTwoFA] = useState(false);
  // const [ftId, setFtId] = useState(false);
  // const [enablePass, setEnablePass] = useState(false);

  //popups
  const [changeNameP, setChangeNameP] = useState(false);
  const [changePassP, setChangePassP] = useState(false);
  const [changePhoneP, setChangePhoneP] = useState(false);
  const [changeEmailP, setChangeEmailP] = useState(false);
  const [deleteUserP, setDeleteUserP] = useState(false);
  const [changePhoto, setChangePhoto] = useState(false);
  const [image64, setImage64] = useState("");

  const setImageCache = async (image) => {await setDataSave(`profile_image`, image);};
  const getImageCache = async () => {
    try {
      const value = await getDataSave(`profile_image`);
      setImage64(value);
    } catch(e) {null;}
  };
  const setUserTheme = (theme) => {
    fetch(`${url}/lm_api/user/theme`, {
      method: "put",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD },
      body: JSON.stringify({ theme, id: user.id, }),
    }).then(()=>{
      reload();
    });
  };


  useEffect(() => {
    getImageCache();
    if (user.image != null && user.image != "") {
      fetch(`${url}/lm_api/image`, {
        method: "get",
        headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: user.image, bucket: "users-prf" },
      })
        .then((res) => res.json())
        .then((image) => {
          setImage64("data:image/png;base64," + image);
          setImageCache("data:image/png;base64," + image);
        });
    }
    return () => {
      
    };
  }, [user]);

  const accIconSize = 200;

  return ( <ScrollView style={{height: "100%", paddingTop: Platform.OS === "web" ? 40 : 0}}>
    <View style={{ width: "100%", flex: 1, paddingLeft: 15, height: "80%" }}>
      <View style={{ alignItems: "flex-start" }}>
        <LMText style={styles.header}>My Profile</LMText>
        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          {image64 === "" || image64 == null ? (
            <Image
              source={require("../../../icons/accountGrayCircle.png")}
              style={styles.accountIcon}
            />
          ) : (
            <Image source={{uri: image64}} style={[styles.accountIcon, {borderRadius: 100}]}/>
          )}
          <View
            style={{
              backgroundColor: "transparent",
              height: accIconSize,
              width: accIconSize,
              position: "absolute",
            }}
          />
        </View>
        <Button
          text="Change"
          style={[ChangeButton(user.theme).changeButton, { top: "10%" }]}
          onPress={() => setChangePhoto(!changePhoto)}
          abideByTheme={true}
        />
        <View style={{ flexDirection: "row", width: "100%" }}>
          <LMText style={styles.nameText} testID="userFullName">
            {user.firstName} {user.lastName}
          </LMText>
          <Button
            text="Change"
            style={[ChangeButton(user.theme).changeButton, { marginLeft: 10 }]}
            onPress={() => setChangeNameP(!changeNameP)}
            abideByTheme={true}
          />
        </View>
        <LMText style={styles.tableHeader}>Account details</LMText>
        <AccountDetailsTable
          user={user}
          changePassP={changePassP}
          setChangePassP={() => setChangePassP(!changePassP)}
          url={url}
          setChangePhoneP={setChangePhoneP}
          setChangeEmailP={setChangeEmailP}
        />
        <LMText style={[styles.tableHeader, { marginTop: 40 }]}>Statistics</LMText>
        <StatsTable user={user} notes={notes} categories={categories} />
        {/* <LMText style={[styles.tableHeader,{marginTop:40}]}>Security Settings</LMText> */}
        {/* <SecurityTable 
        user={user}
        twoFA={twoFA} setTwoFA={setTwoFA}
        ftId={ftId} setFtId={setFtId}
        enablePass={enablePass} setEnablePass={setEnablePass}
      /> */}

        <LMText style={styles.tableHeader}>Settings</LMText>
        <View style={{flexDirection: "row", alignItems: "center", marginLeft: 15}}>
          <LMText style={{ fontSize: 16, fontWeight: !user.theme ? "bold" : "100", marginLeft: 10 }}>Dark Mode</LMText>
          <Switch
            trackColor={{ false: "white", }}
            ios_backgroundColor="white"
            style={switchStyle}
            onValueChange={setUserTheme}
            value={user.theme ? true : false}
          />
          <LMText style={{ fontSize: 16, fontWeight: user.theme ? "bold" : "100", marginLeft: 10 }}>Light Mode</LMText>
        </View>
        <ChangeName
          isVisible={changeNameP}
          close={() => setChangeNameP(false)}
        />
        <ChangeProfileImage
          isVisible={changePhoto}
          close={() => setChangePhoto(false)}
        />
        <ChangePassword
          isVisible={changePassP}
          close={() => setChangePassP(false)}
        />
        <ChangePhone
          isVisible={changePhoneP}
          close={() => setChangePhoneP(false)}
        />
        <ChangeEmail
          isVisible={changeEmailP}
          close={() => setChangeEmailP(false)}
        />
        <DeleteAccountPopup
          isVisible={deleteUserP}
          close={() => setDeleteUserP(false)}
          user={user}
          url={url}
          reload={reload}
        />
        <View style={{ marginTop: 50 }}>
          <View style={styles.dangerZone}>
            <Image source={DZ} style={styles.DZ_image} />
            <LMText style={{ fontSize: 16, fontWeight: "400" }}>
              Delete account
            </LMText>
            <LMText style={{ fontSize: 11, width: 300 }}>
              Note: if you delete this account, all of your categories and notes
              will be
              deleted permanently. This action cannot be reversed. Please be
              certain.
            </LMText>
            <Button
              text="Delete Account"
              style={[styles.DA_button, user.theme ? {} : {borderWidth: 0}]}
              textStyle={styles.DA_buttonT}
              onPress={() => setDeleteUserP(true)}
            />
          </View>
        </View>
      </View>
    </View>
    <View style={{height: 30}}/>
    <LMText testID="versionText" style={{position: "absolute", bottom: 20, right: 20, marginTop: 20}}>{appjson.expo.version}</LMText>
  </ScrollView>
  );
};

AccountSettings.propTypes = {
  categories: PropTypes.any,
  notes: PropTypes.any,
  reload: PropTypes.any,
  url: PropTypes.any,
  user: PropTypes.shape({
    firstName: PropTypes.any,
    image: PropTypes.any,
    lastName: PropTypes.any,
  }),
};

const styles = StyleSheet.create({
  header: {
    fontSize: 40,
    fontWeight: "400",
    marginLeft: 10,
  },
  nameText: {
    fontSize: 30,
    fontWeight: "400",
    marginLeft: 10,
  },
  tableHeader: {
    fontSize: 20,
    fontWeight: "400",
    margin: 10,
  },
  accountIcon: {
    width: 150,
    height: 150,
    margin: 10,
    marginLeft: 15,
  },
  dtRow: { borderBottomWidth: 0, height: 30, width: 300, marginLeft: 0, flex: 1 },
  dtRowHigh: { borderBottomWidth: 0, height: 30, width: 300, marginLeft: 0 },
  eyeIcon: {
    width: 25,
    height: 15,
    marginLeft: 10,
    backgroundColor: "transparent",
  },
  DZ_image: {
    width: 360,
    height: 180,
    marginLeft: -10,
    aspectRatio: 2,
    zIndex: -1, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, resizeMode: 'cover'
  },
  dangerZone: {
    marginBottom: 30,
    paddingTop: 40,
    width: 350
  },
  DA_button: {
    width: 120,
    height: 25,
    backgroundColor: "red",
    padding: 0,
    margin: 20,
    marginRight: 30,
    borderRadius: 5,
    borderColor: "#d7d7d7",
    borderWidth: 1,
    alignSelf: "flex-end"
  },
  DA_buttonT: {
    fontSize: 12,
    fontWeight: "400",
    margin: 0,
  },
});
const textCellStyle = (flex = 0.3) =>
  StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    textCell: {
      borderRightColor: "#525252",
      borderRightWidth: 1.5,
      flex: flex,
      justifyContent: "center",
      width: 30,
      paddingRight: 10,
      alignItems: "flex-end",
    },
    // eslint-disable-next-line react-native/no-unused-styles
    textCell2: {
      flex: flex + 0.1,
      justifyContent: "center",
      width: 30,
      paddingLeft: 10,
      alignItems: "flex-start",
    },
  });

const ChangeButton = (theme) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  changeButton: {
    width: 80,
    padding: 0,
    borderRadius: 5,
    backgroundColor: theme ? "white" : "#4d4d4d",
    borderColor: "#d7d7d7",
    borderWidth: theme ? 1 : 0,
    height: 40,
    alignSelf: "flex-end",
    position: "absolute",
    right: "5%",
  },
  // eslint-disable-next-line react-native/no-unused-styles
  tableText: {
    fontSize: 15,
    color: theme ? "#525252" : "#c2c2c2",
  },
});
