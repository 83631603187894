import PropTypes from "prop-types";
import React from 'react';
import {DropDown} from './DropDown';
import { days, months } from '../../utils/utils';

function range(startAt, size) {
  return [...Array(size).keys()].map(i => i + startAt);
}

export const DayMonthYearSelect = ({value, type, onChange}) => {
  return <DropDown 
    value={value} onDropChange={onChange} buttonStyle={{width: 200, maxWidth: 300, marginRight: -3}}
    data={type==="day" ? days : (type === "month" ? months : range(1970, 130).map(y=>y.toString()))} 
    abideByTheme={false} type={type} />;
};
DayMonthYearSelect.propTypes = {
  onChange: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any
};
