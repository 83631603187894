import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Platform, Dimensions, FlatList, Pressable } from 'react-native';
import { LMText } from "../../../components/elements/Text";
import { Table, Row, } from 'react-native-table-component';
import { RichTextEditor } from "../../../components/elements/RichTextEditor";
import { ScrollView } from "react-native";
import { ImageCell } from "./imageCell";
import { CheckBox } from "../../../components/elements/Checkbox";
import { TextAbstract, formatDate, formatTime, screenWidthF, sortNotes } from "../../../utils/utils";
import { Linking } from "react-native";
import { Button } from "../../../components/elements/Button";
import { Text } from "react-native";
import { deleteNote } from "../noteDisplay";
import { AppContext } from "../../../../AppContext";
import { TouchableWithoutFeedback } from "react-native";

const flexByType = {
  "text": 2, "number": 1,
  "checkbox": 0.5, "photo": 1.5, "free text": 3,
  "date": 1.5, "time": 1.5, "timer": 1.5,
  "url": 2, "phone": 1.5,
  "email": 1.5,
  "user": 1, "password": 1.5
};
const PasswordField = ({value}) => {
  const [visible, setVisible] = useState(false);
  const icon = visible ? require("../../../icons/eye.png") : require("../../../icons/eyeNot.png");

  return <View style={{alignSelf: "center", flexDirection: "row", flexWrap: "wrap"}}>
    <LMText>{visible ? value : "•".repeat(value.length)}</LMText>
    <Button icon={icon} style={styles.eyeIcon} onPress={()=>setVisible(!visible)}/>
  </View>;
};


PasswordField.propTypes = {
  value: PropTypes.string,
};

export const TableView = ({category, notes, setHomeRoute, EnterViewNote}) => {
  const {url, reload, setReload, Alert} = useContext(AppContext);

  const [screenWidth, setScreenWidth] = useState(screenWidthF());
  const [sort, setSort] = useState(category.sortBy);
  const [reverse, setReverse] = useState(category.reverse);

  const [hidePassed, setHidePassed] = useState(category.specialSort);

  const deleteNoteTable = (n) =>{
    deleteNote(url, reload, setReload, n, notes, Alert)();
  };

  const scrollRef = useRef();
  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      x: 0,
      y: 0,
      animated: false,
    });
  };

  useEffect(()=>{
    scrollToTop();
  }, []);

  useEffect(() => {
    Dimensions.addEventListener('change', ()=>setScreenWidth(screenWidthF()));
  });

  const tableHead = ["Title", ...JSON.parse(category.feilds).map(f=>f.name), "Actions"];
  const flexArr = [2, ...JSON.parse(category.feilds).map(f=>flexByType[f.type]), 1.5];
  const widthArr = flexArr.map(flex=>flex*100);

  const specialF = JSON.parse(category.feilds).find(f=>f.name===sort);
  var notesInCat = sortNotes(notes, {...category, sortBy: sort, reverseSort: reverse, specialSort: false});
  if(hidePassed) notesInCat = notesInCat.filter(n=>{
    const f = JSON.parse(n.feilds).find(f=>f.name===specialF.name);
    return f && new Date(f.value) > new Date();
  });
  const tableData = notesInCat.map(n=>JSON.parse(n.feilds));

  const displayTableHead = tableHead.map((text, i)=>{
    return <Pressable key={i} style={{alignItems: "center", height: "100%", justifyContent: "center", flexDirection: "row"}} onPress={()=>{
      if(sort===text) setReverse(!reverse);
      else {
        setSort(text);
        setReverse(false);
      }
    }}>
      <Text
        numberOfLines={2}
        adjustsFontSizeToFit
        style={{textAlign:'center', fontSize: 17}}
      >
        {text}
      </Text>
      { sort===text && <Text style={{fontSize: 17}}> {!reverse ? "▼" : "▲"}</Text>}
    </Pressable>;
  });
  const sizeProp = widthArr.reduce((partialSum, a) => partialSum + a, 0) < screenWidth  ? {flexArr} : {widthArr};

  const displayData = tableData.map((fields, i)=>{
    const catFields = JSON.parse(category.feilds);
    const noteF = catFields.map(f=>{
      return fields.find(f2=>f2.name===f.name);
    });
    const actions = <View style={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
      <Button icon={require("../../../icons/edit.png")} style={styles.addButton} onPress={()=>EnterViewNote({...notesInCat[i], edit: true})}/>
      <Button icon={require("../../../icons/trash.png")} style={styles.addButton} onPress={()=>deleteNoteTable(notesInCat[i])}/>
      <Button icon={require("../../../icons/eye.png")} style={styles.eyeButton} onPress={()=>EnterViewNote(notesInCat[i])}/>
    </View>;
    return [notesInCat[i].header,...noteF.map(field=>{
      if(!field) return "";

      var returnValue = field.value;
      switch(field.type) {
        case "free text":
          returnValue = <View style={{alignItems: "center", top: Platform.OS === "web" ? 0 : -32}}><RichTextEditor border={false} initialValue={field.value} height={65} disabled={true}/></View>;
          break;
        case "password":
          returnValue = <PasswordField value={returnValue} />;
          break;
        case "photo":
          returnValue = <ImageCell height={65} imageName={field.value} cellWidth={sizeProp.flexArr ? null : widthArr[i] * 0.75}/>;
          break;
        case "checkbox":
          returnValue = <View style={{alignItems: "center"}}><CheckBox isChecked={field.value} disabled={true}/></View>;
          break;
        case "time":
        case "timer":
          returnValue = formatTime(new Date(field.value));
          break;
        case "date":
          returnValue = formatDate(new Date(field.value));
          break;
        case "url":
          returnValue = <Button style={{alignSelf: "center", backgroundColor: "transparent", height: 60, width: "100%"}} onPress={()=>Linking.openURL(field.value.startsWith("http") ? field.value : "https://" + field.value)}>
            <LMText style={{color: "blue", textDecorationLine: 'underline', flexWrap: 'wrap'}}>{TextAbstract(field.value, 22)}</LMText></Button>;
      }

      return returnValue;
    }), actions];
  });
  const borderStyle = Platform.OS == "android" ? {} : {borderStyle: {borderWidth: 1, borderColor: '#C1C0B9'}};
  return <View style={styles.container}>
    <LMText style={{fontSize: 30, textAlign: "center"}}>Table of {category.name}</LMText>
    <Button 
      icon={require("../../../icons/plus.png")}
      onPress={()=>setHomeRoute("addNote")}
      style={styles.addButton} abideByTheme={true}
    />
    {category.specialSort
      ?<TouchableWithoutFeedback onPress={()=>setHidePassed(!hidePassed)}>
        <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
          <CheckBox
            isChecked={hidePassed}
            onClick={()=>setHidePassed(!hidePassed)}
            style={{ transform: [{ scaleX: 1.3 }, { scaleY: 1.3 }] }}
            abideByTheme={true}
          />
          <LMText style={{fontSize: 20, fontWeight: 800, marginLeft: 10}}>Hide Past dates</LMText>
        </View>
      </TouchableWithoutFeedback>
      : null
    }
    <ScrollView ref={scrollRef} horizontal={true} style={{width: "98%", paddingBottom: 50,}}>
      <View>
        <Table {...borderStyle}>
          <Row data={displayTableHead} {...sizeProp} style={styles.header} textStyle={styles.text}/>
        </Table>
        <View style={styles.dataWrapper}>
          <Table {...borderStyle} style={sizeProp.flexArr ? {width: screenWidth - (Platform.OS === "web" ? 110 : 0)} : {}}>
            <FlatList 
              scrollEnabled={false}
              data={displayData}
              renderItem={(rowData) => (
                <Row
                  key={rowData.index}
                  data={rowData.item}
                  {...sizeProp}
                  style={styles.row}
                  textStyle={styles.text}
                />
              )}
            />
          </Table>
          <View style={{height: 100}}/>
        </View>
      </View>        

    </ScrollView>
  </View>;
};

TableView.propTypes = {
  EnterViewNote: PropTypes.func,
  category: PropTypes.shape({
    feilds: PropTypes.any,
    name: PropTypes.any,
    reverse: PropTypes.any,
    sortBy: PropTypes.any,
    specialSort: PropTypes.any
  }),
  notes: PropTypes.any,
  setHomeRoute: PropTypes.func,
  setSelectedCategory: PropTypes.any
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    flexDirection: "column",
    flexBasis: "100%",
    paddingTop: 50,
    alignSelf: "center",
    width: "100%",
  },
  eyeButton: {
    width: 50,
    height: 30,
    backgroundColor: "transparent",
  },
  eyeIcon: {
    width: 35,
    height: 20,
    marginLeft: 10,
    backgroundColor: "transparent",
  },
  addButton: {
    width: 50,
    height: 45,
    backgroundColor: "transparent",
  },
  header: { height: 50, backgroundColor: '#a0dbff', },
  text: { textAlign: 'center', fontSize: 20, flexWrap: "wrap"},
  dataWrapper: { marginTop: -1, width: "100%" },
  row: { backgroundColor: 'white', height: 65, width: "100%" }

});