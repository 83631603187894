import PropTypes from "prop-types";
import React from 'react';
import Modal from "react-native-modal";
import { LMText } from './Text';
import { View, StyleSheet, Platform } from 'react-native';
import { Button } from "./Button";

const OKButton = ({text, onPress}) => {
  return <Button 
    text={text} onPress={onPress}
    gradient={["#277cb9", "#000000"]}
    gradientStart={[0,0.5]} style={styles.submit}
    gradientEnd={[1.5,0]} textStyle={{color: "white"}}
  />;
};

OKButton.propTypes = {
  onPress: PropTypes.any,
  text: PropTypes.any
};

export const Alert = ({alertPopup, alertTitle, alertText, setAlertPopup, alertOptions}) => {
  return <Modal isVisible={alertPopup} onBackdropPress={()=>setAlertPopup(false)} style={{margin: "auto", alignItems: "center"}}>
    <View style={{ backgroundColor: "white", borderRadius: 25, padding: 20, width: Platform.OS === "web" ? "140%" : "90%"}}>
      <LMText style={styles.header}>{alertTitle}</LMText>
      {alertText && alertText.length > 0 && <LMText style={styles.subheader}>{alertText}</LMText>}

      <View style={styles.buttonCon}>
        {alertOptions && alertOptions.length > 0
          ? alertOptions.map(option=>{
            return option.onPress
              ? <OKButton text={option.text} onPress={()=>{setAlertPopup(false);option.onPress();}}/>
              : <Button 
              text={option.text} onPress={()=>setAlertPopup(false)}
              style={styles.backButton}
            />;
          })
          : <OKButton text="OK" onPress={()=>setAlertPopup(false)}/>
        }
      </View>
    </View>
  </Modal>;
};
Alert.propTypes = {
  alertOptions: PropTypes.array,
  alertPopup: PropTypes.any,
  alertText: PropTypes.string,
  alertTitle: PropTypes.any,
  setAlertPopup: PropTypes.func
};

const styles = StyleSheet.create({
  header: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: "center",
    maxWidth: 400,
    alignSelf: "center"
  },
  subheader: {
    fontSize: 15,
    marginBottom: 10,
    textAlign: "center",
    maxWidth: 400,
    alignSelf: "center"
  },
  buttonCon: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center"
  },
  submit: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5
  },
  backButton: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5,
    borderWidth: 2,
    borderColor: "#277cb9",
    backgroundColor: "transparent"
  }
});