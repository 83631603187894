import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import * as ExpoImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { Platform, View } from 'react-native';
import { Button } from './Button';
import { AutoScaleImage } from './Image';

export const ImagePicker = ({callback, setReload, defaultValue=null, imageStyle, isHeight=false, abideByTheme=true}) => {
  const [image, setImage] = useState(defaultValue);

  const pickImageCamera = async () => {
    let status = await ExpoImagePicker.requestCameraPermissionsAsync();
    if(status.granted) {
      setReload(true);
      let result = await ExpoImagePicker.launchCameraAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        storageOptions: {
          skipBackup: true,
          path: 'images',
        },
        maxWidth: 500,
        maxHeight: 500,
        quality: 0.5,
      });
      if (!result.canceled) {
        const manipResult = await ImageManipulator.manipulateAsync(
          result.assets[0].uri, [{ resize: { width: 250} }], { format: 'jpeg', base64: true }
        );
        
        const manipResult2 = await ImageManipulator.manipulateAsync(
          result.assets[0].uri, [{ resize: { width: 1180} }], { format: 'jpeg', base64: true }
        );
        setImage('data:image/png;base64,' + manipResult.base64);
        callback({assets: [{uri: manipResult2.uri}]}, manipResult);
      }
    }
    setReload(false);
  };
  const pickImage = async () => {
    setReload(Platform.OS !== "web");
    let result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 0.5,
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
      maxWidth: 500,
      maxHeight: 500,
    });
    if (!result.canceled) {
      const manipResult = await ImageManipulator.manipulateAsync(
        result.assets[0].uri, [{ resize: { width: 250} }], { format: 'jpeg', base64: true }
      );
      const manipResult2 = await ImageManipulator.manipulateAsync(
        result.assets[0].uri, [{ resize: { width: 1180} }], { format: 'jpeg', base64: true }
      );
      setImage('data:image/png;base64,' + manipResult.base64);
      callback({assets: [{uri: manipResult2.uri}]}, manipResult);
    }
    console.log("HEY");
    setReload(false);
  };

  const getBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  // const urlToBase64 = (url, callback) =>  {
  //   var request = new XMLHttpRequest();
  //   request.open('GET', url, true);
  //   request.responseType = 'blob';
  //   request.onload = function() {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(request.response);
  //       reader.onload =  function(e){
  //           callback(e.target.result);
  //       };
  //   };
  //   request.send();
  // };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setReload(true);
    const {files} = e.dataTransfer;
    console.log(e.dataTransfer);
    var base64 = null;

    if (files && files.length) {
      base64 = await getBase64(files[0]);
    } 
    // else {
    //   var imageUrl = e.dataTransfer.getData('text/html');
    //   var rex = /src="?([^"\s]+)"?\s*/;
    //   var url;
    //   url = rex.exec(imageUrl);
    //   urlToBase64(url[0], async (base64)=>{
    //     setImage(base64);
    //     const manipResult = await ImageManipulator.manipulateAsync(
    //       base64, [{ resize: { width: 250} }], { format: 'jpeg', base64: true }
    //     );
    //     callback({assets: [{uri: base64}]}, manipResult);
    //   });
    // }
    // console.log(typeof base64);
    if(base64) {
      setImage(base64);
      const manipResult = await ImageManipulator.manipulateAsync(
        base64, [{ resize: { width: 250} }], { format: 'jpeg', base64: true }
      );
      callback({assets: [{uri: base64}]}, manipResult);
    }

    setReload(false);
  };

  const drop = useRef(null);

 useEffect(() => {
    if(Platform.OS === "web") {
      drop.current.addEventListener('dragover', handleDragOver);
      drop.current.addEventListener('drop', handleDrop);
    }
  }, []);
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const picker = <>
  <View>
    <View style={{flexDirection: "row", justifyContent: "flex-start", width: "30%"}}>
      {Platform.OS !== "web"
        ?<Button abideByTheme={abideByTheme} icon={require("../../icons/camera.png")} style={{backgroundColor: "transparent", margin: 10}} iconStyle={{width: 45, height: 35}} onPress={pickImageCamera} />
        : null
      }
      <Button abideByTheme={abideByTheme} icon={require("../../icons/import_Image.png")} style={{backgroundColor: "transparent", margin: 10}} iconStyle={{width: 45, height: 35}} onPress={pickImage} />
    </View>
    <View style={isHeight ? {height: 180} : {}}>
      <AutoScaleImage source={{uri: image}} style={isHeight ? {height: 200, ...imageStyle} : {width: 200, ...imageStyle}}/>
    </View>
  </View>
  </>;

  if(Platform.OS === "web") {
    return <div ref={drop}>
      {picker}
    </div>;
  }
  return picker;
};
ImagePicker.propTypes = {
  abideByTheme: PropTypes.bool,
  callback: PropTypes.func,
  defaultValue: PropTypes.string,
  imageStyle: PropTypes.any,
  isHeight: PropTypes.bool,
  setReload: PropTypes.func
};
