import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { Image,  StyleSheet, TouchableOpacity, View, } from 'react-native';
import { convertUTCDateToLocalDate, twoDigits } from "../../utils/utils";
import { REACT_APP_API_PASSWORD } from "@env";
//import WebView from "react-native-webview";
import { Popup } from "../../components/elements/popup";
import { Button } from "../../components/elements/Button";
import { AppContext } from "../../../AppContext";
import { LMText } from "../../components/elements/Text";
import { Row } from "react-native-table-component";

export const NotificationRow = ({notification, changeShare, flexArr, setIsUnreadNotification}) => {
  const { url, reload } = useContext(AppContext);
  const [image64, setImage64] = useState("");
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (notification.image != null && notification.image != "") {
      fetch(`${url}/lm_api/image`, {
        method: "get",
        headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: notification.image, bucket: "users-prf" },
      })
        .then((res) => res.json())
        .then((image) => {
          setImage64("data:image/png;base64," + image);
        });
    }
  }, []);

  const setRead = () => {
    fetch(`${url}/lm_api/notifications`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'put',
      body: JSON.stringify({id: notification.id, password: REACT_APP_API_PASSWORD})
    }).catch(err=>console.log(err));
    reload();
    setIsUnreadNotification(false);
  };

  const dateTime = notification.createDate;

  let dateTimeParts= dateTime.split(/[- :]/); 
  dateTimeParts[1]++; 

  var createDate = convertUTCDateToLocalDate(new Date(...dateTimeParts));
  var dTextFormat = `${createDate.getDate()}/${createDate.getMonth()}/${createDate.getFullYear()} ${twoDigits(createDate.getHours())}:${twoDigits(createDate.getMinutes())}`;
  const isRead = <Image 
    source={notification.isRead 
      ? require("../../icons/messageRead.png") 
      : require("../../icons/messageUnRead.png")} 
      style={styles.envelop}
  />;
  const image = image64 === "" ? (
    <Image
      source={require("../../icons/accountGrayCircle.png")}
      style={{width: 50, height: 50}}
    />
  ) : (
    <Image source={{uri: image64}} style={{width: 50, height: 50, borderRadius: 100}}/>
  );
  const data = [image, dTextFormat, notification.firstName + " " + notification.lastName, notification.header, isRead];
  

  return <TouchableOpacity onPress={()=>{setPopup(true);setRead();}}>
  <Row data={data}  textStyle={styles.text} style={styles.row} flexArr={flexArr}/>
  <Popup isVisible={popup} close={()=>setPopup(false)}>
      <View style={styles.profileImageCon}>
        {image}  
        <LMText>{notification.firstName}{"\n"}{notification.lastName} </LMText>
      </View>
      <LMText style={styles.notificationHeader}>{notification.header}</LMText>
      <LMText style={styles.notificationMessage}>{notification.message}</LMText>
      {notification.shareId&&!notification.accepted
        ? <>
          <LMText style={{textAlign: "center", marginTop: 30}}>There Is a Shared Item Attached To This Message.{'\n'}Do You Accept?</LMText>
          <View style={styles.buttonCon}>
            <Button 
              text="Reject" onPress={()=>{changeShare(notification.shareId, 'delete');setPopup(false);}}
              style={styles.backButton}
            />
            <Button 
              text="Accept!" onPress={()=>{changeShare(notification.shareId, 'put');setPopup(false);}}
              gradient={["#1d5a24", "#000000"]}
              gradientStart={[0,0.5]} style={styles.submit}
              gradientEnd={[1.5,0]} textStyle={{color: "white"}}
            />
          </View>
          <LMText style={{textDecorationLine: 'underline'}} onPress={()=>setPopup(false)}>ignore for now</LMText>
        </>
        : null
      }
    </Popup>
</TouchableOpacity>;
};

NotificationRow.propTypes = {
  changeShare: PropTypes.func,
  flexArr: PropTypes.array,
  notification: PropTypes.shape({
    accepted: PropTypes.any,
    createDate: PropTypes.shape({
      split: PropTypes.func
    }),
    firstName: PropTypes.string,
    header: PropTypes.any,
    id: PropTypes.any,
    image: PropTypes.string,
    isRead: PropTypes.any,
    lastName: PropTypes.any,
    message: PropTypes.any,
    shareId: PropTypes.any
  }),
  setIsUnreadNotification: PropTypes.func,
  url: PropTypes.any
};

const styles = StyleSheet.create({
  envelop: {
    height: 30,
    width: 30,
    alignSelf: "center",
  },
  text: { textAlign: 'center', fontSize: 13, flexWrap: "wrap"},
  row: { backgroundColor: "rgba(255,255,255,0.6)", height: 65, width: "100%" },
  notificationHeader: {
    fontSize: 35,
    marginBottom: 5,
  },
  notificationMessage: {
    fontSize: 17,
    fontWeight: "400",
    textAlign: "left",
    alignSelf: "flex-start",
  },

  buttonCon: {
    marginTop: 10,
    flexDirection: "row",
    marginBottom: 15
  },
  submit: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5
  },
  backButton: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5,
    borderWidth: 2,
    borderColor: "#197220",
    backgroundColor: "transparent"
  },
  profileImageCon: {
    width: 100,
    height: 100,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
  },
});