import PropTypes from "prop-types";
import React, { useContext } from 'react';

import { Image, StyleSheet, View } from 'react-native';
import { LMText } from './Text';
import { capitalizeFirstLetter, screenHeightF } from '../../utils/utils';
import SelectDropdown from 'react-native-select-dropdown';
import { Platform } from "react-native";
import { AppContext, MainScrollContext } from "../../../AppContext";

export const DropDown = ({defaultValue, data, onDropChange, buttonStyle, abideByTheme=true, value, type=""}) => {
  const {user} = useContext(AppContext);
  const {setDoingAction} = useContext(MainScrollContext);
  
  return <SelectDropdown
    defaultValue={defaultValue}
    dropdownIconPosition="right"
    data={data}
    onBlur={()=>setDoingAction(false)}
    onSelect={onDropChange}
    onFocus={()=>setDoingAction(true)}
    buttonStyle={[styles.buttonStyle, user.theme == 0 && abideByTheme ? {backgroundColor: "#4d4d4d", borderWidth: 0} : {}, buttonStyle]}
    defaultButtonText={defaultValue}
    rowStyle={[styles.dropDownButtonStyle, user.theme == 0 && abideByTheme ? {backgroundColor: "#4d4d4d", borderWidth: 0} : {}]}
    dropdownStyle={{backgroundColor: Platform.OS === "web" ? (abideByTheme&&!user.theme ? "#4d4d4d" : "white") : "transparent", shadowColor: "transparent", maxHeight: screenHeightF() * 0.3}}
    dropdownOverlayColor="transparent"
    buttonTextStyle={{color: user.theme == 0 && abideByTheme ? "white" : "black"}}
    renderCustomizedRowChild={(item)=><LMText abideByTheme={abideByTheme} testID={"field_" + capitalizeFirstLetter(item)} style={{textAlign: "center"}}>{capitalizeFirstLetter(item)}</LMText>}
    renderCustomizedButtonChild={(item)=><LMText abideByTheme={abideByTheme} style={{alignSelf: "center"}}>{capitalizeFirstLetter(value||item||defaultValue||`Select: ${capitalizeFirstLetter(type)}`)}</LMText>}
    renderDropdownIcon={()=>
      <View style={[styles.dropDownIcon, user.theme == 0 && abideByTheme ? {borderWidth: 0} : {}]} testID="typeDropdown">
        <Image style={{width: 20, height: 20, left: 0, tintColor: user.theme == 0 && abideByTheme ? "white" : "black"}} source={require("../../icons/down_arrow.png")}/>
      </View>
    }
  />;
};
DropDown.propTypes = {
  abideByTheme: PropTypes.bool,
  buttonStyle: PropTypes.any,
  data: PropTypes.any,
  defaultValue: PropTypes.any,
  onDropChange: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string
};


const styles = StyleSheet.create({
  dropDownButtonStyle: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    backgroundColor: "white",
    height: 40,
  },
  dropDownIcon: {
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    justifyContent: "center",
    height: "100%",
    alignSelf: "flex-end",
    width: 30,
    alignItems: "center",
    left: 10 * (Platform.OS==="web" ? 1 : -1),
  },
  buttonStyle: {
    height: 35,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    backgroundColor: "white",
    width: "30%",
    maxWidth: 180,
    left: 5,
  },
});