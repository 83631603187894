import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/elements/Button';
import { View, StyleSheet } from 'react-native';
import { Popup } from '../../components/elements/popup';
import { LMText } from '../../components/elements/Text';
import * as Calendar from 'expo-calendar';
import SelectDropdown from "react-native-select-dropdown";
import { Platform } from "react-native";
import { Image } from "react-native";
import { days, regularColor } from "../../utils/utils";

const compare = ( a, b ) => {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
};

export const ImportFromCalender = ({category={}, isVisible, close, notes=[], createNewCategory, user, url, pass, Alert, reload, setReload}) => {
  const [calendars, setCalendars] = useState([]);
  const [id, setId] = useState(null);

  useEffect(() => {
    if(Platform.OS==="web" || !isVisible) return;
    
    (async () => {
      const { status } = await Calendar.requestCalendarPermissionsAsync();
      console.log(status);
      if (status === 'granted') {
        await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT).then(data=>{
          setCalendars(data);
          console.log(data[0].title, data.length);
          if(data.length == 1 && data[0].title == "Calendar" && isVisible) {
            Alert("Please change your calendar permission to allow access to your calendar");
            close();
          }
        });
      } else {
        Alert("Please enable calendar permissions: " + status);
      }
    })();
  }, [isVisible]);

  const addNotes = (fields, events, cat, newCat=false) => {
    const addEvents = events.filter(e=>!notes.find(n=>n.importId===e.id));
    if(addEvents.length===0) {
      setReload(false);
      return Alert("No new events to add");
    }
    addEvents.forEach(e=>{
      var fs = fields;
      console.log(e);
      if(e.allDay && newCat)
        fs = fs.filter(f=>f.type!=="time");
      fetch(`${url}/lm_api/notes`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: pass,
          header: e.title, userId: user.id,
          feilds: fs.map(f=>({name: f.name, type: f.type, 
            value: f.type==="date"||f.type=="time" 
              ? e.startDate 
              : (f.type==="text"||f.type==="free text" 
                ? e.notes 
                : (f.type==="url" 
                  ? e.url 
                  : (f.type==="day"
                    ? days[new Date(e.startDate).getDay()]
                    : (f.type==="month"
                      ? new Date(e.startDate).getMonth()+1
                      : (f.type==="year"
                        ? new Date(e.startDate).getFullYear()
                        : ""
                      ))
                  )
          ))})),
          categoryId: cat.id, importId: e.id,
        }),
      });
    });
    Alert("Added events from calander");
    setReload(false);
    close();
    reload();
  };

  const importFromCalendar = async () => {
    setReload(true);
    const events = await Calendar.getEventsAsync([id], new Date(), new Date().setFullYear(new Date().getFullYear()+1));
    let fields = category.feilds ? JSON.parse(category.feilds) : null;
    if(createNewCategory) {
      const f = [{name: "Date", type: "date"}, {name: "Notes", type: "text"}, {name: "Day", type: "day"}];
      fetch(`${url}/lm_api/categories`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: pass,
          name: "Calender Events", userId: user.id,
          feilds: f, admin: 0,
          sortBy: "Date", image: "",
          specialSort: false, reverseSort: false, color: JSON.stringify({color: regularColor, darkness: 0}),
          returnCat: true
        }),
      }).then((res) => res.json()).then((cats) => {
        addNotes(f, events, cats.sort(compare)[cats.length-1], true);
      }).catch(e=>console.log(e));
    }
    else if(fields.find(f=>f.type==="date") || category.adminCategory)
      addNotes(fields, events, category);
    else {
      fields = fields.concat({name: "Date", type: "date"});
      fetch(`${url}/lm_api/categories/fields`, {
        method: "put",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: pass,
          id: category.id,
          feilds: fields, notes: notes,
        }),
      }).then((res) => res.json()).then(() => {
        addNotes(fields, events, category);
      });
    }


  };

  return <Popup isVisible={isVisible} close={close}>
      <LMText style={styles.header}>Import From Calendar</LMText>
      <LMText style={styles.subHeader}>Select calendar to import from: </LMText>
      <SelectDropdown
        dropdownIconPosition="right"
        data={calendars.map(c=>c.title)}
        onSelect={(selectedItem) => setId(calendars.find(c=>c.title===selectedItem).id)}
        buttonStyle={{...styles.buttonStyle, marginLeft: 10, width: 250}}
        defaultButtonText={"Select Calendar"}
        rowStyle={styles.dropDownButtonStyle}
        dropdownStyle={{backgroundColor: "transparent", shadowColor: "transparent"}}
        dropdownOverlayColor="transparent"
        renderDropdownIcon={()=>
          <View style={styles.dropDownIcon}>
            <Image style={{width: 20, height: 20, left: 3}} source={require("../../icons/down_arrow.png")}/>
          </View>
        }
      />  

      <Button 
        text="OK!" onPress={importFromCalendar}
        gradient={["#277cb9", "#000000"]}
        gradientStart={[0,0.5]} style={styles.submit}
        gradientEnd={[1.5,0]} textStyle={{color: "white"}}
        disabled={id == null}
      />
    </Popup>;
};

ImportFromCalender.propTypes = {
  Alert: PropTypes.func,
  category: PropTypes.shape({
    adminCategory: PropTypes.any,
    feilds: PropTypes.any,
    id: PropTypes.any
  }),
  close: PropTypes.func,
  createNewCategory: PropTypes.any,
  isVisible: PropTypes.any,
  notes: PropTypes.array,
  pass: PropTypes.any,
  reload: PropTypes.func,
  setReload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any
  })
};

const styles = StyleSheet.create({
  header: {
    fontSize: 26,
    fontWeight: "bold",
  },
  dropDownButtonStyle: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    backgroundColor: "white",
    height: 40
  },
  dropDownIcon: {
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    justifyContent: "center",
    height: "100%",
    alignSelf: "flex-end",
    width: 30,
    alignItems: "center",
    left: 10 * (Platform.OS==="web" ? 1 : -1),
  },
  buttonStyle: {
    height: 35,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 10,
    backgroundColor: "white",
    width: "30%",
    left: 5,
    top: 10
  },
  subHeader: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
  },
  submit: {
    top: 30,
    height: 40,
    width: 120,
    borderRadius: 70,
  },
});