import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Popup } from "../../../components/elements/popup";
import { View, StyleSheet } from "react-native";
import { Button } from "../../../components/elements/Button";
import { InputText } from "../../../components/elements/InputText";
import { Col, Grid, Row } from "react-native-easy-grid";
import { REACT_APP_API_PASSWORD } from "@env";
import { validateEmail } from "../../../utils/utils";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { OKButton } from "../../../components/elements/OKButton";

export const ChangeEmail = ({ isVisible, close }) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);

  const [newEmail, setNewEmail] = useState("");
  const [newEmailValid, setNewEmailValid] = useState(false);
  const [verification, setVerification] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    setNewEmail("");
    setVerification(false);
    setCode("");
    setNewEmailValid(false);
  }, [isVisible, user]);

  const onSubmit = () => {
    setNewEmailValid(validateEmail(newEmail));
    if (validateEmail(newEmail)) {
      setReload(true);
      fetch(`${url}/lm_api/setVerificationCode`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: REACT_APP_API_PASSWORD,
          id: user.id,
          email: user.email,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          Alert(
            "An Email Was Sent To Your Old Email Containing The Code You Need To Enter To Change Your Email"
          );
          setVerification(true);
          setCode("");
          setReload(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const verifyEmail = () => {
    setReload(true);
    setCode(true);
    fetch(`${url}/lm_api/changeEmail`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        id: user.id,
        email: newEmail,
        code: code,
        date: new Date(),
        user: user,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setReload(false);
        if (res === true) {
          Alert("Email Has Changed");
          reload();
          close();
        } else {
          Alert("Code Is Incorrect Or Expired");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Popup isVisible={isVisible} style={styles.popup} close={close}>
      <LMText style={styles.header}>Change Email</LMText>
      {verification ? (
        <View style={{ alignItems: "center" }}>
          <InputText
            defaultValue={code}
            onChange={(nativeEvent) => setCode(nativeEvent.text)}
            style={[styles.textBox, { width: 300 }]}
            placeholder="Verification Code..." abideByTheme={false}
          />
          <View style={{ flexDirection: "row" }}>
            <Button
              text="Verify"
              onPress={verifyEmail}
              style={styles.verificationButton}
            />
            <Button
              text="Cancel"
              onPress={close}
              style={styles.verificationButton}
            />
          </View>
        </View>
      ) : (
        <>
          <View style={{ height: 80, marginTop: 15 }}>
            <Grid>
              <Row style={styles.dtRow}>
                <Col style={styles.cell}>
                  <LMText style={styles.label}>New Email</LMText>
                </Col>
                <Col style={styles.cell2}>
                  <InputText
                    defaultValue={newEmail}
                    onChange={(nativeEvent) => {
                      setNewEmail(nativeEvent.text);
                      setNewEmailValid(validateEmail(nativeEvent.text)&&nativeEvent.text !== "");
                    }} abideByTheme={false}
                    style={[
                      styles.textBox,
                      { borderColor: newEmailValid ? "#dddddd" : "red" },
                    ]} autoCapitalize={false} type="email"
                    onBlur={(event) =>
                      setNewEmailValid(validateEmail(event.nativeEvent.text))
                    }
                    placeholder="New Email..."
                  />
                </Col>
              </Row>
            </Grid>
          </View>
          <View style={styles.flexRow}>
            <OKButton onClick={onSubmit} disabled={!newEmailValid}/>
          </View>
        </>
      )}
    </Popup>
  );
};

ChangeEmail.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any,
  reload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    email: PropTypes.any,
    id: PropTypes.any
  })
};

const styles = StyleSheet.create({
  popup: {
    width: 300,
  },
  header: {
    fontSize: 30,
    marginLeft: 50,
    marginRight: 50,
    fontWeight: "500",
    width: "100%",
    textAlign: "center"
  },
  dtRow: { borderBottomWidth: 0, height: 45, width: 330, marginLeft: 20, flex: 1 },
  cell: {
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 0.5,
    justifyContent: "center",
    width: 30,
    paddingRight: 10,
    alignItems: "flex-end",
  },
  cell2: {
    flex: 1,
    justifyContent: "center",
    width: 30,
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    fontWeight: "400",
  },
  textBox: {
    borderWidth: 2,
    borderColor: "#dddddd",
    width: 200,
    height: 30,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    right: -100,
  },
  verificationButton: {
    width: 70,
    height: 30,
    borderWidth: 2,
    borderColor: "#dddddd",
    backgroundColor: "white",
  },
});
