import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { Button } from '../../components/elements/Button.js';
import { InputText } from '../../components/elements/InputText.js';
import { setDataSave } from '../../utils/dataSaver.js';
import {REACT_APP_API_PASSWORD} from "@env";
//import * as LocalAuthentication from 'expo-local-authentication';
import { Popup } from '../../components/elements/popup.js';
import { Col, Grid, Row } from 'react-native-easy-grid';
import * as AppleAuthentication from 'expo-apple-authentication';
import jwt_decode from 'jwt-decode';
import { toMysqlFormat } from '../../utils/utils.js';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import {AppContext} from '../../../AppContext.js';
import { LMText } from '../../components/elements/Text.js';
import { useColorScheme } from "react-native";
import {OKButton} from "../../components/elements/OKButton.js";
var appjson = require('../../../app.json');

WebBrowser.maybeCompleteAuthSession();


const ForgotPasswordPopup = ({isVisible, close}) => {
  const {url, Alert, setReload} = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassC, setNewPassC] = useState('');

  const [popupState, setPopupState] = useState('email');
  const [emailSent, setEmailSent] = useState(false);
  useEffect(() => {
    setEmail("");
    setCode("");
    setNewPass("");
    setNewPassC("");
    setPopupState("email");
    setEmailSent(false);
  }, [isVisible]);

  const sendEmail =() => {
    setReload(true);
		fetch(`${url}/lm_api/forgetPassword`, {
			method: "post", headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				password: REACT_APP_API_PASSWORD,
        email: email
			}),
		}).then((res) => res.json()).then(() => {
      Alert("Email with code sent");
      setEmailSent(true);
      setReload(false);
		}).catch((err) => console.log(err));
  };

  const verifyEmail = () => {
    setReload(true);
		fetch(`${url}/lm_api/verifyCode`, {
			method: "post", headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				password: REACT_APP_API_PASSWORD,
        email: email, code: code
			}),
		}).then((res) => res.json()).then((res) => {
      if(res) {
        setPopupState("password");
      } else {
        Alert("incorrect code");
      }
      setReload(false);
		}).catch((err) => console.log(err));
  };

  const resetPassword = () => {
    console.log("HELLO");
		if(newPass === newPassC && newPass.length > 0) {
      setReload(true);
      fetch(`${url}/lm_api/rsetPassword`, {
        method: "post", headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: REACT_APP_API_PASSWORD,
          email: email, newPass: newPass
        }),
      }).then((res) => res.json()).then(() => {
        Alert("Password changed.");
        close();
        setReload(false);
      }).catch((err) => console.log(err));
    } else {
      Alert("confirm password incorrect");
    }
  };

  return <Popup isVisible={isVisible} close={close} style={styles.popup}>
    <View style={{width: 350}}/>
    <LMText style={{...styles.title, fontSize: 30}}>Forgot Passowrd</LMText>
    {popupState === "email"
      ?<>
        {emailSent
          ?<>
            <LMText style={styles.resentText}>If you did not recieve an email,</LMText>
            <LMText style={{...styles.resentText, color: "blue"}} onPress={sendEmail}>click here</LMText>
            <InputText
              onChange={(nativeEvent)=>setCode(nativeEvent.text)}
              style={{...styles.input, height: 40}}
              placeholder="Verification Code..."
              type="number"
            />
            <Button 
              text="Verify" onPress={verifyEmail}
              style={styles.sendButton}
            />
          </>
          :<>
            <LMText style={{...styles.title, fontSize: 20, marginTop: 0}}>Please enter your email address </LMText>
            <InputText 
              placeholder="Type Your Email" 
              style={{...styles.input, height: 40}} type="email"
              onChange={(nativeEvent)=>setEmail(nativeEvent.text)}
            />
            <Button text="Send Email" style={styles.sendButton} onPress={sendEmail}/>
          </>
        }
      </>
      : <View  style={{ height: 200, marginTop: 15, width: "100%" }}>
      <Grid>
        <Row style={styles.gRow}>
          <Col style={styles.textCell}>
            <LMText style={styles.label}>New Password</LMText>
          </Col>
          <Col style={[styles.textCell, styles.cell]}>
            <InputText 
              placeholder="New Password" password={true}
              style={{...styles.input, height: 40, width: 200}}
              onChange={(nativeEvent)=>setNewPass(nativeEvent.text)}
            />
          </Col>
        </Row>
        <Row style={styles.gRow}>
          <Col style={styles.textCell}>
            <LMText style={styles.label}>Confirm</LMText>
          </Col>
          <Col style={[styles.textCell, styles.cell]}>
            <InputText 
              placeholder="Confirm New Password" password={true}
              style={{...styles.input, height: 40, width: 200}}
              onChange={(nativeEvent)=>setNewPassC(nativeEvent.text)}
            />
          </Col>
        </Row>
        <View style={styles.flexRow}>
          <OKButton onClick={resetPassword}/>
        </View>
      </Grid>
      </View>

    }
  </Popup>;
};

ForgotPasswordPopup.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any
};

export const Signinpage = ({setRoute, setUser}) => {
  const {url, reload, setReload, Alert} = useContext(AppContext);
  const [user, setUserName] = useState('');
  const [pass, setPass] = useState('');
  const [forgotPassPopup, setForgotPassPopup] = useState(false);

  const theme = useColorScheme();

  const onSubmit = () => {
    setReload(true);
		fetch(`${url}/lm_api/user`, {
			method: "get", 
      headers: { 
        "Content-Type": "application/json",
        password: REACT_APP_API_PASSWORD, user: user, UPassword: pass,
				isEmail: user.includes("@"), encrypt: false,
      },
		}).then((res) => res.json()).then((user) => {
      if(user === "invalid")
        Alert("Invalid account.");
      if(user === false)
        Alert("Invalid User Or Password.");
      else {
        setUser(user);
				setDataSave("userdata", user.username + "," + user.password);
        setDataSave("recents", "[]");
				setRoute('home');
        reload();
      }
      setReload(false);
		}).catch((err) => console.log(err));
  };

  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId: '577999943798-1a72ik1c97gro5q7ma1gksjuh90rhr1a.apps.googleusercontent.com',
    iosClientId: '577999943798-euvrk0dtbc0ko6j69u0vn04bmcg20534.apps.googleusercontent.com',
    androidClientId: '577999943798-cqpc50uq390ftvgtlsr8nd6rksjpdmd5.apps.googleusercontent.com',
    webClientId: '577999943798-pb33ai7rinada6a8n921uitrvfm1foan.apps.googleusercontent.com',
    scope: ["email", "profile"],
  });

  React.useEffect(() => {
    if (response?.type === 'success') {
      getGoogleData(response.authentication.accessToken);
    }
  }, [response]);

  const getGoogleData = async (token) => {
    const response = await fetch(
      "https://www.googleapis.com/userinfo/v2/me",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const user = await response.json();

    console.log(user);
    googleReq(user);
  };

  const googleReq = (result) => {
    fetch(`${url}/lm_api/user/google`, {
      method: "get", headers: { 
        "Content-Type": "application/json",
        password: REACT_APP_API_PASSWORD, 
        email: result.email, id: result.id,
      },
    }).then((res) => res.json()).then((user) => {
      if(user === false)
        Alert("Invalid User Or Password.");
      else {
        if(user.signupReq) {
          fetch(`${url}/lm_api/user/google`, {
            method: "post", headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              password: REACT_APP_API_PASSWORD,
              date: toMysqlFormat(new Date()),
              username: result.email.split("@")[0], email: result.email,
              phone: "", Upassword: result.id,
              name: result.givenName, LName: result.familyName,
              emailUrl: url, theme: theme==="dark" ? 0 : 1,
            }),
          }).then((res) => res.json()).then(() => {
            googleReq(result);
          }).catch((err)=>console.log(err));
        } else {
          setUser(user);
          setDataSave("userdata", user.username + "," + user.password);
          setDataSave("recents", "[]");
          setRoute('home');
          reload();
        }
      }
    }).catch((err) => console.log(err));
  };

  const AppleLogin = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      const { email  } = jwt_decode(credential.identityToken);
      
      fetch(`${url}/lm_api/AppleLogin`, {
        method: "post", headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD,  },
        body: JSON.stringify({
          password: REACT_APP_API_PASSWORD, 
          email: email, id: credential.user,
        }),
      }).then((res) => res.json()).then((user) => {
        if(user === false)
          Alert("Invalid User Or Password. You must sign up if you did not do that.");
        else {
          setUser(user);
          setDataSave("userdata", user.username + "," + user.password);
          setDataSave("recents", "[]");
          setRoute('home');
          reload();
        }
      }).catch((err) => console.log(err));

    } catch (e) {
      if (e.code === 'ERR_CANCELED') {
        console.log("canceled");
      } else {
        console.log("error", e);
      }
    }
  };

  // const touchIdSignin = async () => {
  //   const result = await LocalAuthentication.authenticateAsync({
  //     promptMessage: "log in with touch id",
  //     disableDeviceFallback: false
  //   });
  // };
  

  return <View style={styles.container}>
    <LMText style={styles.title}>Sign In</LMText>
    <View style={styles.form}>
      <InputText 
        placeholder="Type Your Email/Username" style={styles.input}
        onChange={(nativeEvent)=>setUserName(nativeEvent.text)}
        icon={require("../../icons/accountIconGray.png")} iconColor="gray"
        topLabel="Username/Email"
      />
      <InputText 
        placeholder="Type Your Password" style={styles.input}
        onChange={(nativeEvent)=>setPass(nativeEvent.text)}
        icon={require("../../icons/lock.png")} iconColor="gray"
        password={true} topLabel="Password"
      />
    </View>
    <View style={{width: 300}}><LMText style={styles.FP} onPress={()=>setForgotPassPopup(true)}>Forgot Passowrd?</LMText></View>
    <ForgotPasswordPopup isVisible={forgotPassPopup} close={()=>setForgotPassPopup(false)}/>
    <View style={styles.buttonCon}>
      <Button 
        text="SIGN IN" 
        onPress={()=>onSubmit()}
        style={styles.submit}
        textStyle={{...styles.submitText, fontWeight: "400"}}
        gradient={["#4065b2", "#00b2e8"]}
        gradientStart={[0,1]}
        gradientEnd={[1,0]}
      />
      
      <View style={styles.seperationText}><LMText>OR</LMText></View>
      <Button 
        text="Sign in with Google" style={styles.wideButton} 
        textStyle={styles.submitText} onPress={()=>{
          promptAsync().catch(err=>console.log(err));
        }}
        disabled={!request}
      ><Image source={require("../../icons/google.png")} style={styles.googleIcon} /></Button>
      <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
        cornerRadius={5}
        style={{...styles.wideButton, backgroundColor: "transparent", marginBottom: 0}}
        onPress={AppleLogin}
      />
      
      <View style={[styles.seperationText,{width:180}]}><LMText>Not a member yet?</LMText></View>
      <Button 
        testID={'registerButton'}
        text="REGISTER NOW!" style={styles.wideButton} 
        textStyle={styles.submitText} onPress={()=>setRoute("register")}
      />
    </View>
    <LMText testID="versionText" style={{position: "absolute", bottom: 20, right: 20}}>{appjson.expo.version}</LMText>
  </View>;
};

Signinpage.propTypes = {
  setRoute: PropTypes.func,
  setUser: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    padding: 50,
    //backgroundColor: "#91d1ff"
  },
  title: {
    fontSize: 35,
    fontWeight: "400",
    margin: 20,
  },
  input: {
    backgroundColor: "white",
    height: 50
  },
  submit: {
    height: 40,
    width: 300,
    borderRadius: 70
  },
  submitText: {color: "white", fontWeight: "400"},
  form: {textAlign: "left"},
  FP: {alignSelf: "flex-end"},
  buttonCon: {
    marginTop: 20,
    alignItems: "center"
  },
  seperationText: {
    marginTop: 10,
    marginBottom: 10,
    borderTopColor: "#ededed",
    borderBottomColor: "#ededed",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    width: 80,
    alignItems: "center",
    padding: 10
  },
  wideButton: {
    backgroundColor: "#5e98f7",
    width: 300,
    height: 40,
    borderRadius: 5,
    marginBottom: 20,
  },
  googleIcon: {
    width: 30,
    height: 30,
    position: 'absolute',
    left: "10%"
  },
  popup: {
    width: 300
  },
  sendButton: {
    width: 90,
    padding: 0,
    height: 30,
    borderRadius: 5,
    marginTop: "2%",
    backgroundColor: "white",
    borderColor: "#d7d7d7",
    borderWidth: 1
  },
  textCell: {
    borderRightColor: "black", 
    borderRightWidth: 1, 
    flex: 0.5, 
    width: 50,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingRight: 25,
    paddingLeft: 25,
  },
  cell: {
    flex: 1, 
    borderRightColor: "transparent", 
    alignItems: "flex-start"
  },
  label: {
    fontSize: 20,
    fontWeight: "400",
    width: 200,
    textAlign: "right",
    marginRight: -15
  },
  gRow: {borderBottomWidth: 0, height: 50, width: "100%", flex: 1},
  flexRow: {
    flexDirection: "row",
    justifyContent: "center", 
    marginTop: 30,
  },
  resentText: {
    fontSize: 20
  }
});