import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView, Image, TouchableWithoutFeedback, Animated, Dimensions, Platform } from 'react-native';
import { Button } from '../../../components/elements/Button';
import { TextAbstract, clamp, onlyUnique, screenWidthF, } from '../../../utils/utils';
import { getDataSave, setDataSave } from '../../../utils/dataSaver';
import { Category, noteSize } from "./category";
import { CheckBox } from "../../../components/elements/Checkbox";
import { LMText } from "../../../components/elements/Text";
import { Popup } from "../../../components/elements/popup";
import { ImportFromCalender } from "../importFromCalander";
import { AppContext } from "../../../../AppContext";
import {REACT_APP_API_PASSWORD} from "@env";

const adminColor = "#00b91e";
// const regularColor = "#53db34";
// const sharedColor = "#4472b8";
// const sharedColorNotes = "#36abf5";

function compareAdmin( a, b ) {
  if ( a.adminCategory < b.adminCategory ){
    return -1;
  }
  if ( a.adminCategory > b.adminCategory ){
    return 1;
  }
  return 0;
}


const width = 115 * 5;

export const CategoryManagement = ({setHomeRoute, goToTop, categories, notes, recentNotes, EnterViewNote, AddNewNote, EnterViewCat, setCopyCategory, setHomeSelectedCategory, homeSelectedCategory, setCategories}) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);
  
  const [seeUnusedCats, setSeeUnusedCats] = useState(true);
  const [singleNoteRow, setSingleNoteRow] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [noteSettingsPopup, setNoteSettingsPopup] = useState(false);
  const [calenderPopup, setCalenderPopup] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(homeSelectedCategory);
  const [isMoving, setIsMoving] = useState(false);
  const widthAnim = useRef(new Animated.Value(115)).current;
  const heightAnim = useRef(new Animated.Value(100)).current;

  const animationDuration = 250;

  const getCatWidthHeight = (category) => {
    if(category === null) return [115, 100];
    const toWidth = Dimensions.get('window').width * 0.95 < width ? Dimensions.get('window').width * 0.95 : width;
    const notesInRow = Math.floor(toWidth / noteSize);
    const numRows = clamp(Math.round(notes.filter(n=>n.categoryId==category.id).length/notesInRow), 1, 3);
    return [toWidth, 155 + numRows * noteSize];
  };

  const openMenu = (category) => {
    if(selectedCategory != null) {
      closeMenu();
      return;
    }
    setIsMoving(true);
    setSelectedCategory(category);
    setHomeSelectedCategory(category);
    const [toWidth, toHeight] = getCatWidthHeight(category);
    Animated.timing(widthAnim, {
      // toValue: screenWidth * 0.95,
      toValue:  toWidth,
      duration: animationDuration,
      useNativeDriver: false
    }).start();
    Animated.timing(heightAnim, {
      toValue: toHeight,
      duration: animationDuration,
      useNativeDriver: false
    }).start(()=>setIsMoving(false));
  };
  const closeMenu = () => {
    setIsMoving(true);
    if(Object.keys(homeSelectedCategory).length > 0)
      setHomeSelectedCategory({});
    Animated.timing(widthAnim, {
      toValue: 115,
      duration: animationDuration,
      useNativeDriver: false
    }).start();
    Animated.timing(heightAnim, {
      toValue: 100,
      duration: animationDuration,
      useNativeDriver: false
    }).start(()=> {
      setSelectedCategory(null);
      setIsMoving(false);
    });
  };

  useEffect(() => {
    if(Object.keys(homeSelectedCategory).length === 0) {
      closeMenu();  
    }
  }, [homeSelectedCategory]);

  useEffect(() => {
    startSetSUC();
    if(Object.keys(homeSelectedCategory).length > 0) {
      setSelectedCategory(homeSelectedCategory);
      const [toWidth, toHeight] = getCatWidthHeight(selectedCategory);
      widthAnim.setValue(toWidth);
      heightAnim.setValue(toHeight);
    }
    return () => {
      
    };
  }, [notes]);

  useEffect(()=>{
    goToTop();
  }, [selectedCategory]);
  

  const startSetSUC = async () => {
    getDataSave("seeUnusedCats").then((data)=>{
      if(data !== null)
        setSeeUnusedCats(Number(data)?true:false);
    });
    await getDataSave("singleNoteRow").then((data)=>{
      if(data !== null)
        setSingleNoteRow(Number(data)?true:false);
    });
  };

  const setUnusedCats = async () => {
    setDataSave("seeUnusedCats", ((!seeUnusedCats)?1:0).toString()).then(()=>{
      setSeeUnusedCats(!seeUnusedCats);
      setTimeout(() => {
        setSettingsPopup(false);
      }, 200);
    });
  };

  const setNoteRows = async () => {
    await setDataSave("singleNoteRow", ((!singleNoteRow)?1:0).toString()).then(()=>{
      setSingleNoteRow(!singleNoteRow);
      setNoteSettingsPopup(false);
      setTimeout(() => {
        
      }, 200);
    });
  };

  const sortedCats = categories;

  const catsInRow = Math.floor(screenWidthF() / 115);


  return <View style={{flex: 1, flexDirection: "column"}}>
    <TouchableWithoutFeedback onPress={closeMenu}>
      <View style={{marginLeft: 15}}>
        <View style={styles.headerRow}>
          <LMText style={styles.header}>Notes:</LMText>
          <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
            <Button 
              icon={require("../../../icons/plus.png")}
              style={styles.addIcon}
              onPress={()=>{AddNewNote(categories.find(c=>c.id === -1));}}
              abideByTheme={true} testID="Add_Custom_Note"
            />
            <Button 
              icon={require("../../../icons/settings.png")}
              style={styles.settingsIcon}
              onPress={()=>setNoteSettingsPopup(true)}
              abideByTheme={true}
            />
          </View>
        </View>
        <ScrollView horizontal={true} style={{height: singleNoteRow ? 91 : 120}} contentContainerStyle={[styles.recentNotes]}>
          {recentNotes.length > 0
            ?recentNotes.map((note, i)=>{
              const width = singleNoteRow ? 90 : 60;
              return <Button
                key={i} style={notesStyles(width).note} icon={require("../../../icons/recent_note.png")}
                onPress={()=>EnterViewNote(note)} iconStyle={notesStyles(width).noteIcon}
              >
                <LMText abideByTheme={false} style={{width: "60%", textAlign: "center", fontSize: 11 * (singleNoteRow ? 1.2 : 1)}}>{note.header===""?"No Title":TextAbstract(note.header, 14)}</LMText>
              </Button>;
            }) : null
          }
        </ScrollView>
        <Popup isVisible={noteSettingsPopup} close={()=>setNoteSettingsPopup(false)}>
          <View style={{flexDirection: "column", alignItems: "center"}}>
            <LMText style={{...styles.header, fontSize: 25}}>Note View Setting: </LMText>
            <TouchableWithoutFeedback onPress={setSingleNoteRow} style={{cursor: 'pointer'}}>
              <View  style={{marginTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start", marginLeft: 15}}>
                <CheckBox
                  isChecked={singleNoteRow ? true : false}
                  onClick={setNoteRows}
                  style={{ transform: [{ scaleX: 1.3 }, { scaleY: 1.3 }] }}
                />
                <LMText style={{fontSize: 20, fontWeight: 800, marginLeft: 10}}>Single Note Row</LMText>
              </View>
            </TouchableWithoutFeedback>     
          </View>
        </Popup>
      </View>
    </TouchableWithoutFeedback> 
    
    <TouchableWithoutFeedback onPress={closeMenu} style={{flexBasis: "auto", flex: 1, flexShrink: 1}}>
      <View>
        <View style={{ flexDirection: "row", justifyContent: "space-between"}}>
          <View style={{marginLeft: 15}}>
            <View style={styles.headerRow}>
              <LMText style={styles.header}>Categories:</LMText>
              {!selectedCategory 
                && <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
                <Button 
                  icon={require("../../../icons/plus.png")}
                  style={styles.addIcon}
                  onPress={()=>setHomeRoute("addCategory")}
                  abideByTheme={true} testID="Add_New_Category"
                />
                <Button 
                  icon={require("../../../icons/settings.png")}
                  style={styles.settingsIcon}
                  onPress={()=>setSettingsPopup(true)}
                  abideByTheme={true}
                />
              </View>
              }
              
            </View>
            <Popup isVisible={settingsPopup} close={()=>setSettingsPopup(false)}>
              <View style={{flexDirection: "column", alignItems: "center"}}>
                <LMText style={{...styles.header, fontSize: 25}}>Category View Setting: </LMText>
                <TouchableWithoutFeedback onPress={setUnusedCats} style={{cursor: 'pointer'}}>
                  <View  style={{marginTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "center", alignSelf: "flex-start", marginLeft: 15}}>
                    <CheckBox
                      isChecked={seeUnusedCats ? true : false}
                      onClick={setUnusedCats}
                      style={{ transform: [{ scaleX: 1.3 }, { scaleY: 1.3 }] }}
                    />
                    <LMText style={{fontSize: 20, fontWeight: 800, marginLeft: 10}}>See Unused Categories</LMText>
                  </View>
                </TouchableWithoutFeedback>     
              </View>
              {Platform.OS !== "web"
                ?<View style={{borderTopWidth: 2, borderColor: "black", justifyContent: "center", alignItems: "center", width: 350, marginTop: 14}}>
                  <LMText style={{fontSize: 23, marginTop: 10}}>Import from calender</LMText>
                  <Button 
                    text="Import" 
                    onPress={()=>{setCalenderPopup(true);setSettingsPopup(false);}}
                    style={styles.submit}
                    textStyle={{fontWeight: "800", color: "white"}}
                    gradient={["#277cb9", "#000000"]}
                    gradientStart={[0,1]}
                    gradientEnd={[1,0]}
                  />
                </View>
                : null
              }
            </Popup>
            <ImportFromCalender 
              pass={REACT_APP_API_PASSWORD} user={user} url={url} 
              isVisible={calenderPopup} close={()=>setCalenderPopup(false)} createNewCategory={true}
              reload={reload} setReload={setReload} Alert={Alert}
            />  
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
    <TouchableWithoutFeedback onPress={Platform.OS === "ios" ? null : closeMenu}>
      <View
        style={{...styles.categoriesCon, width: catsInRow * 115}}
      >
        {
          sortedCats.filter((c)=>{
            return (seeUnusedCats||notes.filter(n=>n.categoryId==c.id).length > 0||c.notes > 0);
          }).filter(onlyUnique).reverse().sort(compareAdmin).sort(function(x,y){ return selectedCategory ? (x.id == selectedCategory.id ? -1 : y.id == selectedCategory.id ? 1 : 0) : 0; }).map((category, i)=>{
            if(category.accepted === 0 && category.shared)
              return null;
            return <View style={{padding: Platform.OS === "web" ? 10 : 0, alignSelf: "flex-start"}} key={category.id}>
              <Category 
                EnterViewCat={EnterViewCat}
                category={category} AddNewNote={AddNewNote} notes={notes} 
                EnterViewNote={EnterViewNote} i={i}
                selectedCategory={selectedCategory} setSelectedCategory={setHomeSelectedCategory}
                openMenu={openMenu} closeMenu={closeMenu} isMoving={isMoving}
                widthAnim={widthAnim} heightAnim={heightAnim} addCategory={()=>setHomeRoute("addCategory")}
                setCopyCategory={setCopyCategory} setHomeRoute={setHomeRoute}
                removeCat={(c)=>setCategories(categories.filter(c2=>c2.id !== c.id))}
              />
            </View>;
          })
        }
        <View style={{...styles.category, }} >
          <TouchableOpacity 
            style={{...styles.categoryIn, bottom: Platform.OS === "web" ? -2 : 7, backgroundColor: "transparent", flexDirection: "row", justifyContent: "center", alignItems: "center"}}  
            onPress={()=>setHomeRoute("addCategory")}
          >
            <Image 
              source={require("../../../icons/category.png")} 
              style={{
                position: "absolute", 
                width: "100%", height: 100, 
                tintColor: adminColor, zIndex: -1
              }}
            />
            <LMText abideByTheme={false} style={{fontSize: 35}}>+</LMText>
            <LMText abideByTheme={false} style={{fontWeight: 800,fontSize: 15}} numberOfLines={2}>Add{"\n"}Category</LMText>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableWithoutFeedback>    
    

  </View>;
};

CategoryManagement.propTypes = {
  AddNewNote: PropTypes.func,
  EnterViewCat: PropTypes.func,
  EnterViewNote: PropTypes.func,
  categories: PropTypes.array,
  goToTop: PropTypes.func,
  homeSelectedCategory: PropTypes.any,
  notes: PropTypes.array,
  recentNotes: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func
  }),
  setCategories: PropTypes.func,
  setCopyCategory: PropTypes.array,
  setHomeRoute: PropTypes.func,
  setHomeSelectedCategory: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.any
  })
};
const styles = StyleSheet.create({
  header: {
    fontWeight: 800,
    fontSize: 25,
    marginBottom: 0
  },
  categoriesCon: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 100,
    alignSelf: Platform.OS!=="web" ? "center" : "flex-start",

//    paddingLeft: Platform.OS === "web" ? 10 : 5,

  },
  category: {
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: 115,
    alignItems: 'center',
    justifyContent: "flex-start",
    height: 80,
    marginTop: 14,
  },
  categoryIn: {
    backgroundColor: "#accfb1",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: "100%",
    justifyContent: 'center',
    shadowColor: "#000",
    shadowOffset:{
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,
    elevation: 16,
    marginTop: 20,
  },
  recentNotes: {
    zIndex: 100,
    height: "100%",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
  settingsIcon: {
    width: 40,
    height: 40,
    backgroundColor: "transparent",
  },
  addIcon: {
    width: 40,
    height: 40,
    backgroundColor: "transparent",
  },
  submit: {
    height: 40,
    width: 120,
    borderRadius: 70,
    marginTop: 5
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "100%",
    width: 400,
    margin: -2
  }
});

const notesStyles = (width) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  note: {
    backgroundColor: "transparent",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    width: width,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: "center",
    height: width,
  },

  // eslint-disable-next-line react-native/no-unused-styles
  noteIcon: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 21,
    },
    shadowOpacity: 0.48,
    shadowRadius: 11.95,
    elevation: 18, 
    width: width,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: "center",
    height: width,
  },
});