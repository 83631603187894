import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { AutoScaleImage } from '../../../components/elements/Image';
import { AppContext } from '../../../../AppContext';
import {REACT_APP_API_PASSWORD} from "@env";

export const ImageCell = ({imageName, height, cellWidth}) => {
  const {url} = useContext(AppContext);

  const [image, setImage] = useState("");
  console.log(cellWidth);

  useEffect(()=>{
    fetch(`${url}/lm_api/image`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: imageName, resized: true},
    }).then((res) => res.json()).then((img) => {
      setImage(img);
    });
  }, []);

  return <AutoScaleImage source={{uri: 'data:image/png;base64,' + image}}  style={{height: height, alignSelf: "center"}} maxWidth={cellWidth}/>;
};
ImageCell.propTypes = {
  height: PropTypes.any,
  imageName: PropTypes.any,
  cellWidth: PropTypes.number,
};
