import React, { createContext } from "react";

export const AppContext = React.createContext({
  url: "",
  reload: ()=>null,
  setReload: ()=>null,
  Alert: ()=>null,
  setAlertFunc: ()=>null,
  user: {},
  isReload: false
});

export const CacheContext = React.createContext({
  categoriesCache: [],
});

export const BackButtonContext = createContext({
  backButtonFunction: ()=>null,
  setBackButtonFunction: ()=>null
});

export const MainScrollContext = createContext({
  setDoingAction: ()=>null
});