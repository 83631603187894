import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Clipboard, Linking, Image, Platform } from 'react-native';
import { Button } from '../../components/elements/Button';
import { InputText } from '../../components/elements/InputText';
import { CreateImageFormData, formatDate, formatTime, screenWidth, SetFontSizeToFit, useForceUpdate, validateEmail, validateURL } from '../../utils/utils';
import {REACT_APP_API_PASSWORD} from "@env";
import {CheckBox} from '../../components/elements/Checkbox';
import { RichTextEditor } from '../../components/elements/RichTextEditor';
import { AddEventToCalender } from "./AddEventToCalender";
import { AddTimer } from "./addTimerpopup";
import { DateTimePickerButton } from "../../components/elements/DateTimePickerButton";
import { ShareNotePopup } from "./shareNotePopup";
import { FullImagePopup } from "./fullImagePopup";
//import GestureRecognizer from 'react-native-swipe-gestures';
import { AutoScaleImage } from "../../components/elements/Image";
import { ImagePicker } from "../../components/elements/ImagePicker";
import { AppContext, BackButtonContext, MainScrollContext } from "../../../AppContext";
import { useCrtlEnterSubmit } from "../../components/elements/useCtrlEnter";
import { LMText } from "../../components/elements/Text";
import { Keyboard } from "react-native";
import ColorPicker from "react-native-wheel-color-picker";
import { GetColorName } from "hex-color-to-color-name";
import { getDefaultValue } from "./addNote";
import { DropDown } from "../../components/elements/DropDown";
import { OKButton } from "../../components/elements/OKButton";
import { DayMonthYearSelect } from "../../components/elements/DayMonthYearSelect";
const DS = 17.5;
const headerDefaultSize = 30;
var appJson = require('../../../app.json');

export const deleteNote = (url, reload, setReload, note, notes, Alert, onFinish=()=>0) => {

  const deleteImage = (field) => {
    if(field.type==="photo") {
      fetch(`${url}/lm_api/image`, {
        method: 'delete',
        body: JSON.stringify({password: REACT_APP_API_PASSWORD, imageName: field.value}),
        headers: { "Content-Type": "application/json" },
      });
    }
  };
  const deleteNote = () => {
    setReload(true);
    if(note.shared && note.permission !== 1) {
      fetch(`${url}/lm_api/share`, {
        method: "delete",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({password: REACT_APP_API_PASSWORD, id: note.shareId}),
      }).then((res) => res.json()).then(() => {
        //setHomeRoute("main");
        setReload(false);
      }).catch((err) => console.log(err));
      return;
    }
    JSON.parse(note.feilds).map((field)=>{
      deleteImage(field);
    });
    fetch(`${url}/lm_api/notes`, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({password: REACT_APP_API_PASSWORD, id: note.id}),
    }).then((res) => res.json()).then(() => {
      notes.splice(notes.indexOf(note), 1);
      onFinish();
      reload();
      setReload(false);
    }).catch((err) => console.log(err));
  };

  const showDeleteNoteConfirm = () => {
    return Alert(
      "Really Delete?",
      "This Action Can not be reversed.",
      [
        {text: "No",},
        {text: "Yes", onPress: () =>deleteNote()},
      ]
    );
  };

  return showDeleteNoteConfirm;
};

export const NoteDisplay = ({selectedNote, setHomeRoute, moveToNextNote, notes}) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);
  const {setBackButtonFunction} = useContext(BackButtonContext);
  const {setDoingAction} = useContext(MainScrollContext);
  
  const [edit, setEdit] = useState(selectedNote.edit);
  const [header, setHeader] = useState(selectedNote.header==="No Title" ? "" : selectedNote.header);
  const [fieldValues, setFieldValues] = useState([]);
  const [setDtValue] = useState(new Date());
  const [images] = useState([]);
  const [currentFont, setCurrentFont] = useState(DS);
  const forceUpdate = useForceUpdate();
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [fieldTypes, setFieldTypes] = useState([]);

  const [loadingImages, setLoadingImages] = useState(false);

  const showDeleteNoteConfirm = deleteNote(url, reload, setReload, selectedNote, notes, Alert, ()=>setHomeRoute("main"));

  useEffect(()=>{
    fetch(`${url}/lm_api/fields`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, version: appJson.expo.version },
    }).then((res) => res.json()).then((types) => {
      setFieldTypes(types);
    }).catch((err) => console.log(err));
  }, []);

  const updateFields = ()=> {
    const fields = JSON.parse(selectedNote.feilds);
    setFieldValues(fields.map(f=>({...f, 
      seePass: false, 
      valid: true, 
      validName: true,
      value: f.type==="date"||f.type==="time"||f.type==="timer" ? (f.value==="" ? (f.type!=="timer" ? new Date() : new Date().setHours(0,0,0)) : f.value) : f.value
    })));
  };

  useEffect(() => {
     const keyboardDidShowListener = Keyboard.addListener(
       'keyboardDidShow',
       () => {
         setKeyboardVisible(true); // or some other action
       }
     );
     const keyboardDidHideListener = Keyboard.addListener(
       'keyboardDidHide',
       () => {
         setKeyboardVisible(false); // or some other action
       }
     );
     updateFields();
 
     return () => {
       keyboardDidHideListener.remove();
       keyboardDidShowListener.remove();
     };
     
   }, []);

  useEffect(() => {
    if(edit) {
      setBackButtonFunction({func: ()=>setEdit(false)});
      return;
    }
    setBackButtonFunction({func: ()=>setHomeRoute("main")});
  }, [edit]);
  

  useEffect(() => {
    global.Buffer = global.Buffer || require('buffer').Buffer;
    console.log(selectedNote.feilds);
    const fields = JSON.parse(selectedNote.feilds);
    updateFields();
    setHeader(selectedNote.header);

    fields.forEach((field, i) => {
      if(field.value64) {
        images[i] = field.value64;
        return;
      }
      if(field.type==="photo" && field.value !== "") {
        fields[i].ogName = field.value;
        fetch(`${url}/lm_api/image`, {
          method: "get",
          headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: field.value, resized: true},
        }).then((res) => res.json()).then((image) => {
          images[i] = 'data:image/png;base64,' + image;
          selectedNote.feilds = JSON.stringify(fields.map(f=>{
            if(f.name===field.name)
              return {...f, value64: 'data:image/png;base64,' + image};
            return f;
          }));
          updateFields();
        });
      }
    });
  }, [selectedNote]);

  const onChange = (nativeEvent, index) => {
    const fields = fieldValues;

    fields[index].value = fields[index].type=="number" ? nativeEvent.text.replace(/[^\d.-]/g, '') : nativeEvent.text;
    setFieldValues(fields);
  };

  const setSeePass = (val, name) => {
    const fields = fieldValues.slice();
    const index = fields.indexOf(fields.find(f=>f.name===name));

    fields[index].seePass = val;
    setFieldValues(fields);
  };

  const onDTChange = (selectedDate, index) => {
    const fields = fieldValues;

    fields[index].value = selectedDate || fields[index].value;
    setFieldValues(fields);
  };

  const onCBChange = (value, index) => {
    const fields = fieldValues.slice();
    fields[index].value = value;
    setFieldValues(fields);
  };

  const validate = (event, index) => {
    const fields = fieldValues.slice();
    if(fieldValues[index].type==="email") {
      fields[index].valid = validateEmail(event.nativeEvent.text);
      setFieldValues(fields);
      return validateEmail(event.nativeEvent.text);
    }
    if(fieldValues[index].type==="url") {
      fields[index].valid = validateURL(event.nativeEvent.text);
      setFieldValues(fields);
      return validateURL(event.nativeEvent.text);
    }
    forceUpdate();
    return true;
  };

  const onSubmit = async () => {
    // const valids = fieldValues.map(f=>{
    //   return validate({nativeEvent:{text: f.value}}, f.name);
    // });
    // if(valids.includes(false))
    //   return;
    if(edit)
      setReload(true);
    const fields = fieldValues.map((field, i)=>{
      const f = uploadImage(field, i);
      return f;
    });

    fetch(`${url}/lm_api/notes`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD, header: header, 
        id: selectedNote.id, feilds: fields,
      }),
    }).then((res) => res.json()).then(() => {
      setReload(false);
      reload();
      setEdit(false);
      setLoadingImages(true);
      setFieldValues(fields);
    });
  };


  const uploadImage = (field) => {
    if(field.type==="photo") {
      if(field.value.endsWith(".jpeg") || field.value.length < 2) {
        return field;
      }
      let newName = JSON.parse(selectedNote.feilds).find(f=>f.name===field.name).value.split(".")[0];
      newName = newName==="" ? `${user.id}-${selectedNote.categoryId}-${new Date().getTime()}` : newName;

      fetch(`${url}/lm_api/image`, {
        method: 'post',
        body: CreateImageFormData(field.valueResized, newName),
        headers: {bucket: "notes-resized"},
      }).then(()=>{

        fetch(`${url}/lm_api/image`, {
          method: 'post',
          body: CreateImageFormData(field.value, newName),
        }).then(()=>setLoadingImages(false));
      });
      return {type: field.type, value: newName + ".jpeg", name: field.name};
    }
    return field;
  };


  const OnImagePick = (image, smallImage, index) => {
    const fields = fieldValues.slice();
    fields[index].value = image.assets[0].uri;
    fields[index].valueResized = smallImage.uri;
    images[index] = 'data:image/png;base64,' + smallImage.base64;
    setFieldValues(fields);
  };

  const onChangeFieldName = (nativeEvent, index) => {
    const fields = fieldValues;
    fields[index].name = nativeEvent.text;
    setFieldValues(fields);
    validateNames();
  };
  const onTypeChange = (selectedItem, index) => {
    const fields = fieldValues.splice(0, fieldValues.length);
    fields[index].type = selectedItem;
    fields[index].value = getDefaultValue(selectedItem);
    delete fields[index].valueResized;
    setFieldValues(fields);
  };
  const addField = () => {
    const NewFields = fieldValues.slice(0,fieldValues.length);
    NewFields.push({name: "", type: "free text", valid: true, editableField: true, validName: false});
    setFieldValues(NewFields);
  };
  const deleteField = (index) => {
    const NewFields = fieldValues.slice(0,fieldValues.length);
    NewFields.splice(index, 1);
    setFieldValues(NewFields);
  };
  const validateNames = () => {
    const newFields = fieldValues.slice();
    newFields.forEach((field, i) => {
      const similarNames = newFields.filter(f=>f.name===field.name).map((f, i)=>i);
      if(similarNames.length > 1) {
        similarNames.forEach((index)=>{
          newFields[index].validName = false;
        });
      }
      else if(field.name === "") {
        newFields[i].validName = false;
      } else {
        newFields[i].validName = true;
      }
    });
    setFieldValues(newFields);
  };


  useCrtlEnterSubmit(edit ? onSubmit : ()=>null, [header, fieldValues, edit]);
  return <View style={{backgroundColor: `rgba(255, 255, 255, ${user.theme ? 0.3 : 0.6})`}}>
    <View
        onSwipeLeft={edit ? null : () => moveToNextNote(1, selectedNote.categoryId)}
        onSwipeRight={edit ? null : () => moveToNextNote(-1, selectedNote.categoryId)}
        config={{
          velocityThreshold: 0.65,
          directionalOffsetThreshold: 180
        }}
        style={{
          height: "100%",
        }}
    >
      <View style={NoteBookStyle(user.theme).redBorder}/>
      <View style={styles.container}>
        {edit
          ?<InputText 
            placeholder="Title" onChange={(nativeEvent)=>setHeader(nativeEvent.text)} 
            defaultValue={header} style={styles.headerTB}
            abideByTheme={false}
          />
          : <LMText style={{...styles.header, fontSize: headerDefaultSize}} 
            numberOfLines={ 1 } adjustsFontSizeToFit abideByTheme={false}
            onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
          >{header==="" ? "No Title" : header}</LMText>
        }
        {!edit
          ?<View style={styles.buttonCon}>
            
            {selectedNote.permission === 1 || !selectedNote.shared
              ?<ShareNotePopup note={selectedNote}/>
              : null
            }
            {selectedNote.permission === 1 || !selectedNote.shared
              ?<Button 
                icon={require("../../icons/edit.png")} style={styles.deleteButton}
                onPress={()=>setEdit(true)}
              />
              : null
            }
            <Button 
              icon={require("../../icons/trash.png")} style={styles.deleteButton}
              onPress={showDeleteNoteConfirm}
            />
          </View>
          : null
        }
        <View style={{width: "97%"}}>
          {/* <ScrollView keyboardDismissMode="none" keyboardShouldPersistTaps="always" style={{height: 200}}> */}
          {
            fieldValues.map((field, i)=>{
              const normalView = field.type!=="date"&&field.type!=="time"&&field.type!=="timer";
              const dtValue = field.type==="date" ? formatDate(new Date(field.value)) : formatTime(new Date(field.value));
              const icon = !field.seePass ? require("../../icons/eye.png") : require("../../icons/eyeNot.png");
              const text = field.type==="password" && !field.seePass ? "•".repeat((normalView?field.value:dtValue).length) : (normalView?field.value:dtValue);
              var uri = images[i];
              var phantomRowsLength = 0;
              if(field.type === "free text") phantomRowsLength = 4;
              if(field.type === "url" && !edit) phantomRowsLength = 1;
              if((field.type === "color"&&edit)||field.type === "photo") phantomRowsLength = 5;

              if(field.value===""&&!edit)
                return null;
              return <View key={i} style={{width: "100%"}}>
                {
                  Array.from({length: phantomRowsLength}, (_, i)=>100 + 50 * i).map(margin=>{
                    return <View key={margin} style={[NoteBookStyle(user.theme).phantomNotebookRow, {marginTop: margin}]}/>;
                  })
                }
                <View>
                <View style={{alignSelf: "flex-start", ...styles.notebookRow}}>
                  {field.editableField && edit
                    ?<View style={{flexDirection: "row", alignItems: "center", paddingLeft: 0}}>
                    <Button 
                      icon={require("../../icons/remove.png")} 
                      style={styles.removeButton} 
                      onPress={()=>deleteField(i)}
                    />
                    <View style={{width: "89%", flexDirection: "row", alignItems: "center"}}>
                      <InputText 
                        onChange={(nativeEvent)=>onChangeFieldName(nativeEvent, i)}
                        placeholder="Field Name" autoFocus={i!==0} selectTextOnFocus={true}
                        defaultValue={field.name} style={{...textBoxStyles(field.validName).textBox, height: 30, width: "63%", marginLeft: 10}}
                        onFocus={()=>setKeyboardVisible(true)}
                        onBlur={()=>setKeyboardVisible(false)} abideByTheme={false}
                      />
                      <DropDown abideByTheme={false} buttonStyle={{height: 35, width: "35%", maxWidth: "35%"}} defaultValue={field.type} data={fieldTypes.map(t=>t.name)} onDropChange={(selectedItem) => onTypeChange(selectedItem, i)} style={{width: 200}}/>
                    </View>
                   </View>
                  : <LMText
                      style={{fontSize: DS, ...styles.label, marginBottom: 5, alignSelf: "flex-start", fontWeight: "bold"}}
                      numberOfLines={ 1 } adjustsFontSizeToFit abideByTheme={false}
                      onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
                    >{field.name} {(field.type === "timer")&&edit ? "(hour:minute)" : ""}</LMText>
                }
                </View>
                </View>
                <View style={{flexDirection: field.type==="url" ? "column" : "row",...styles.notebookRow, paddingLeft: Platform.OS === "web" ? 55 : 40, width: "104%"}}>
                  {field.type==="photo"
                    ?<View style={{width: "100%"}}>
                      {edit
                        ? <View style={{marginBottom: 50}}><ImagePicker abideByTheme={false} isHeight={true} callback={(r, r2)=>OnImagePick(r, r2,  i)} setReload={setReload} defaultValue={images[i]}/></View>
                        : uri && uri.length > 22
                          ? <AutoScaleImage source={{uri: images[i]}}  style={{height: 240}}/>
                          : <Image source={require("../../icons/imgLoading.png")} style={{width: 240, height: 240}}/>
                      }
                      
                    </View>
                    :<>
                    {field.type==="checkbox"
                      ?<CheckBox
                        style={{paddingLeft: 10, padding: 5}}
                        onClick={()=>{
                          onCBChange(!field.value, i);
                          if(!edit)
                            onSubmit();
                        }}
                        isChecked={field.value}
                      />
                      :<>
                        {edit
                          ?(normalView
                              ?<>
                              {field.type==="free text"
                                ?<RichTextEditor onChange={onChange} initialValue={field.value} name={i}/>
                                :(field.type==="color"
                                  ?<View style={{right: 20, width: 350, paddingBottom: 70, height: 400, maxWidth: screenWidth-50}}>
                                  <ColorPicker
                                    color={field.value}
                                    onColorChange={(color) => onCBChange(color, i)}
                                    onInteractionStart={()=>setDoingAction(true)}
                                    onColorChangeComplete={()=>setDoingAction(false)}
                                    thumbSize={30}
                                    sliderSize={25}
                                    noSnap={true}
                                    row={false}
                                  /><LMText abideByTheme={false} style={[styles.colorText, {backgroundColor: field.value, position: "absolute", bottom: 20}]}>
                                    {GetColorName(field.value)}</LMText>
                                  </View>
                                  :( field.type === "day" || field.type === "month" || field.type === "year"
                                    ?  <DayMonthYearSelect value={field.value} type={field.type} onChange={(value)=>onCBChange(value, i)}/>
                                    : <InputText 
                                    onChange={(nativeEvent, name)=>{onChange(nativeEvent, name);validate({nativeEvent}, i);}} name={i} 
                                    value={field.value} 
                                    type={field.type} style={textBoxStyles(field.valid).textBox}
                                    password={field.type==="password"}
                                    abideByTheme={false}
                                  />
                                  )
                                )
                              }
                              </>
                              :<DateTimePickerButton index={i} field={field} onDTChange={onDTChange} dtValue={dtValue} setDtValue={setDtValue} style={{width: "95%"}}/>
                            )
                          :<>
                            {field.type==="free text"
                              ?<View style={{width: "100%",paddingBottom: 40}}>
                                <RichTextEditor initialValue={field.value} disabled={true}/>
                              </View>
                              : (field.type === "text"
                                ? <LMText
                                  abideByTheme={false} selectable={true}
                                  style={{fontSize: 23}}
                                >{text}</LMText>
                                :(field.type === "color"
                                  ?<LMText abideByTheme={false} style={[styles.colorText, {backgroundColor: field.value}]}>{GetColorName(field.value)}</LMText>
                                  :<LMText
                                  style={{fontSize: DS+7, paddingTop: 4, height: 40}}
                                  numberOfLines={ 1 } adjustsFontSizeToFit abideByTheme={false}
                                  onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
                                  selectable={true}
                                >
                                  {text}
                                </LMText>
                                )
                              )
                            }
                          </>
                        }
                      </>
                    }
                  </>
                  }
                  {!edit
                    ?<View style={{flexDirection: "row", left: 0, alignItems: "center", height: 50, paddingTop: field.type === "url" ? 20 : 0}}>
                      {field.type==="password" ? <Button icon={icon} style={styles.eyeIcon} onPress={()=>setSeePass(field.seePass ? false : true, field.name)}/> : null}
                      {field.type==="password"||field.type==="url"||field.type==="user" ? <Button icon={require("../../icons/copy.png")} style={styles.copyIcon} onPress={()=>Clipboard.setString(field.value)}/> : null}
                      {field.type==="url" ? <Button icon={require("../../icons/open_in_browser.png")} style={styles.copyIcon} onPress={()=>Linking.openURL(field.value.startsWith("http") ? field.value : "https://" + field.value)}/> : null}
                      {field.type === "date" && Platform.OS !== "web"
                        ? <AddEventToCalender note={selectedNote} date={field.value} noteHeader={header}/>
                        : null
                      }
                      {field.type === "timer" && Platform.OS !== "web"
                        ? <AddTimer note={selectedNote} defaultTime={new Date(field.value)} header={header} fieldName={field.name}/>
                        : null
                      }
                    </View>
                    : null
                  }
                </View>
                {!edit
                  ?<>{field.type==="photo" ? <FullImagePopup loadingImages={loadingImages} imageName={field.value} note={selectedNote} field={field}/> : null}</>
                  : null
                }
              </View>;
            })
          }
          <View style={styles.notebookRow}/>
          {/* </ScrollView> */}
        </View>

      
      </View>
      { selectedNote.categoryId === -1 && edit &&
        <Button style={styles.addFieldButton} onPress={addField} disabled={fieldValues.length > 19}>
          <Image style={{width:20, height: 20, marginLeft: 15}} source={require("../../icons/plus.png")}/>
          <LMText abideByTheme={false}>Add Field</LMText>
        </Button>
      }
      {edit
      ?<Buttons fieldValues={fieldValues} onSubmit={onSubmit}/>
      : null
    }
    <View style={{height: 130  * (isKeyboardVisible ? 2 : 1)}}/>
    </View>

  </View>;
};

const Buttons = ({fieldValues, onSubmit,}) => {
  const disabled = fieldValues.map(f=>f.valid).includes(false) || fieldValues.map(f=>f.validName).includes(false);

  return <View style={{...styles.flexRow}}>
    <OKButton disabled={disabled} onClick={onSubmit}/>
  </View>;
};

Buttons.propTypes = {
  fieldValues: PropTypes.array,
  onSubmit: PropTypes.any,
  setEdit: PropTypes.func,
  updateFields: PropTypes.func
};

NoteDisplay.propTypes = {
  moveToNextNote: PropTypes.func,
  notes: PropTypes.any,
  reload: PropTypes.func,
  scrollTo: PropTypes.func,
  selectedNote: PropTypes.shape({
    categoryId: PropTypes.any,
    edit: PropTypes.any,
    feilds: PropTypes.any,
    header: PropTypes.string,
    id: PropTypes.any,
    permission: PropTypes.number,
    shareId: PropTypes.any,
    shared: PropTypes.any,
    userId: PropTypes.any
  }),
  setBackButtonFunction: PropTypes.any,
  setHomeRoute: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any
  })
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    flexDirection: "column",
    height: "100%",
    
  },
  label: {
    fontSize: 25,
    fontWeight: "400",
    paddingLeft: 30
  },
  header: {
    fontSize: 30,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "flex-end", 
    alignSelf: "flex-end",
    right: 20,
    marginTop: 5,
  },
  headerTB: {
    width: "85%",
    height: 40,
    fontSize: 20,
    alignSelf: "flex-start",
    marginLeft: Platform.OS === "web" ? 70 : 45
  },

  buttonCon: {
    alignSelf: "flex-end",
    margin: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0
  },
  deleteButton: {
    width: 30,
    height: 35,
    backgroundColor: "transparent",
    borderRadius: 0,
    marginRight: 10
  },
  eyeIcon: {
    width: 35,
    height: 20,
    marginLeft: 10,
    backgroundColor: "transparent",
  },
  copyIcon: {
    width: 30,
    height: 35,
    marginLeft: 10,
    backgroundColor: "transparent",
    left: 0,
    marginBottom: 10
  },
  notebookRow: {
    width: "100%",
    borderColor: "rgba(186, 224, 255, 0.3)",
    borderWidth: 0,
    borderTopWidth: 3,
    padding: 3
  },
  colorText: {
    fontSize: 25, 
    borderColor: "black", 
    borderWidth: 0.7, 
    paddingHorizontal: 20, 
    height: 40
  },
  addFieldButton: {
    width: 120,
    backgroundColor: "white",
    borderColor: "#d7d7d7",
    borderRadius: 6,
    borderWidth: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  removeButton: {
    width: 40, 
    height: 40,
    margin: Platform.OS === "web" ? 0 : -7, 
    backgroundColor:"transparent",
  },
});
const textBoxStyles = (valid) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  textBox: {
    borderColor: valid ? "transparent" : "red",
    borderWidth: 2,
    width: Platform.OS === "ios" ? "94%" : "89%",
    height: 30,
    margin: 2.5
  }
});

const NoteBookStyle = (theme) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  redBorder: {
    width: Platform.OS === "web" ? 50 : 40,
    alignSelf: "flex-start",
    borderColor: `rgba(255, 0, 0, ${theme ? 0.3 : 0.5})`,
    borderWidth: 0,
    borderRightWidth: 3,
    height: "100%",
    position: "absolute"
  },
  // eslint-disable-next-line react-native/no-unused-styles
  phantomNotebookRow: {
    width: '100%',
    borderColor: `rgba(186, 224, 255, ${theme ? 0.3 : 0.6})`,
    borderWidth: 0,
    borderTopWidth: 3,
    padding: 3,
    height: 40, 
    position: "absolute",
  },
});