import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Keyboard, } from 'react-native';
import { Button } from '../../components/elements/Button';
import { InputText } from '../../components/elements/InputText';
import { CreateImageFormData, SetFontSizeToFit, useForceUpdate, validateEmail, validateURL } from '../../utils/utils';
import {REACT_APP_API_PASSWORD} from "@env";
import {CheckBox} from '../../components/elements/Checkbox';
import { RichTextEditor } from '../../components/elements/RichTextEditor';
import { DateTimePickerButton } from "../../components/elements/DateTimePickerButton";
import { ImagePicker } from "../../components/elements/ImagePicker";
import { AppContext, MainScrollContext } from "../../../AppContext";
import { useCrtlEnterSubmit } from "../../components/elements/useCtrlEnter";
import { LMText } from "../../components/elements/Text";
import ColorPicker from "react-native-wheel-color-picker";
import { GetColorName } from "hex-color-to-color-name";
import { Image } from "react-native";
import { Platform } from "react-native";
import { DropDown } from "../../components/elements/DropDown";
import { OKButton } from "../../components/elements/OKButton";
import { DayMonthYearSelect } from "../../components/elements/DayMonthYearSelect";

const DS = 17.5;

var appJson = require('../../../app.json');
export const getDefaultValue = (type) => 
  type==="year" ? new Date().getFullYear().toString() : type==="color" ? "ffffff" : (type==="number" 
  ? "" 
  : (type!=="date"&&type!=="time"&&type!=="timer" ? (type!=="checkbox" ? "" : false) : (type!=="timer" ? new Date() : new Date().setHours(0,0,0))));


export const AddNote = ({selectedCategory, setHomeRoute, addRecentNote}) => {
  const {user, url, reload, setReload} = useContext(AppContext);
  const {setDoingAction} = useContext(MainScrollContext);
  
  const [header, setHeader] = useState('');
  const [fieldValues, setFieldValues] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [currentFont, setCurrentFont] = useState(DS);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

 useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true); // or some other action
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false); // or some other action
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    var category = {...selectedCategory};
    const noFields = !category.feilds || category.feilds === "";

    if(noFields) {
      category.feilds = JSON.stringify([{name: "text", type: "free text", value: "", valid: true}]);
      category.id = -1;
      category.shared = false;
      category.userId = user.id;
    }
    const fields = JSON.parse(category.feilds).map(f=>{
      return {
        name: f.name, 
        type: f.type, 
        value: getDefaultValue(f.type), 
        valid: true,
        validName: true,
        editableField: noFields
      };
    });
    setFieldValues(fields);
  }, []);

  useEffect(()=>{
    fetch(`${url}/lm_api/fields`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, version: appJson.expo.version },
    }).then((res) => res.json()).then((types) => {
      setFieldTypes(types);
    }).catch((err) => console.log(err));
  }, []);

  const onChange = (nativeEvent, index) => {
    const fields = fieldValues;
    
    fields[index].value = fields[index].type=="number" ? nativeEvent.text.replace(/[^\d.-]/g, '') : nativeEvent.text;
    setFieldValues(fields);
  };
  const onChangeFieldName = (nativeEvent, index) => {
    const fields = [...fieldValues];
    fields[index].name = nativeEvent.text;
    setFieldValues(fields);
    validateNames();
  };
  const onTypeChange = (selectedItem, index) => {
    const fields = fieldValues.splice(0, fieldValues.length);
    fields[index].type = selectedItem;
    fields[index].value = getDefaultValue(selectedItem);
    delete fields[index].valueResized;
    setFieldValues(fields);
  };


  const validate = (event, index) => {
    const fields = fieldValues.slice();
    if(fieldValues[index].type==="email") {
      fields[index].valid = validateEmail(event.nativeEvent.text);
      setFieldValues(fields);
      return validateEmail(event.nativeEvent.text);
    }
    if(fieldValues[index].type==="url") {
      fields[index].valid = validateURL(event.nativeEvent.text);
      setFieldValues(fields);
      return validateURL(event.nativeEvent.text);
    }
    forceUpdate();
    return true;
  };
  const validateNames = () => {
    const newFields = fieldValues.slice();
    newFields.forEach((field, i) => {
      const similarNames = newFields.filter(f=>f.name===field.name).map((f, i)=>i);
      if(similarNames.length > 1) {
        similarNames.forEach((index)=>{
          newFields[index].validName = false;
        });
      }
      else if(field.name === "") {
        newFields[i].validName = false;
      } else {
        newFields[i].validName = true;
      }
    });
    setFieldValues(newFields);
  };

  const onCBChange = (value, index) => {
    const fields = fieldValues.slice();
    fields[index].value = value;
    setFieldValues(fields);
  };
  const onDTChange = (selectedDate, index) => {
    const fields = fieldValues;

    fields[index].value = new Date(selectedDate) || fields[index].value;
    setFieldValues(fields);
  };

  const onSubmit = async () => {
    setReload(true);
    const fields = fieldValues.map( (field)=>{
      const f = uploadImage(field);
      return f;
    });

    fetch(`${url}/lm_api/notes`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        header: header, userId: selectedCategory.shared ? selectedCategory.userId : user.id,
        feilds: fields, categoryId: selectedCategory.id,
        returnNote: true
      }),
    }).then((res) => res.json()).then((note) => {
      addRecentNote({...note, feilds: JSON.stringify(fields)});
      //setNotes(notes.concat([{header, feilds: JSON.stringify(fields), userId: user.id, categoryId: selectedCategory.id, id: notes.length > 0 ? notes[notes.length-1].id : 1}]));
      setHomeRoute("main");
      reload();
    });
    setTimeout(() => {
      reload();
    }, 1000);
  };

  const addField = () => {
    const NewFields = fieldValues.slice(0,fieldValues.length);
    NewFields.push({name: "", type: "free text", valid: true, editableField: true, validName: false, value: ""});
    setFieldValues(NewFields);
  };
  const deleteField = (index) => {
    const NewFields = fieldValues.slice(0,fieldValues.length);
    NewFields.splice(index, 1);
    setFieldValues(NewFields);
  };

  const uploadImage = (field) => {
    if(field.type==="photo" && field.value !== "") {
      let newName = `${user.id}-${selectedCategory.id}-${new Date().getTime()}`;
      console.log(field);

      fetch(`${url}/lm_api/image`, {
        method: 'post',
        body: CreateImageFormData(field.valueResized, newName),
        headers: {bucket: "notes-resized"},
      }).then(()=>{
        fetch(`${url}/lm_api/image`, {
          method: 'post',
          body: CreateImageFormData(field.value, newName),
        });
      });
      return {type: field.type, value: newName + ".jpeg", name: field.name, editableField: field.editableField};
    }
    return {type: field.type, value: field.value, name: field.name, editableField: field.editableField};
  };

  const OnImagePick = (image, smallImage, index) => {
    const fields = fieldValues.slice();

    fields[index].value = image.assets[0].uri;
    fields[index].valueResized = smallImage.uri;
    setFieldValues(fields);
  };

  useCrtlEnterSubmit(onSubmit, [header, fieldValues]);
  return <View style={[styles.container, {backgroundColor: `rgba(255, 255, 255, ${user.theme ? 0.3 : 0.6})`}]}>
      <View style={NoteBookStyle(user.theme).redBorder}/>
      <InputText 
        placeholder={"Title"} onChange={(nativeEvent)=>setHeader(nativeEvent.text)} 
        defaultValue={header} style={styles.headerTB} autoFocus={true} abideByTheme={false}
      />
      <View style={{width: "100%"}}>
      {
        fieldValues.map((field, i)=>{
          var phantomRowsLength = 0;
          if(field.type === "free text") phantomRowsLength = 4;
          if(field.type === "photo") phantomRowsLength = 5;
          if(field.type === "color") phantomRowsLength = 6;
          console.log(field.type, field.value);

          return <View key={i} style={{width: "100%", position: "relative"}}>
            {
              Array.from({length: phantomRowsLength}, (_, i)=>100 + 50 * i).map(margin=>{
                return <View key={margin} style={[NoteBookStyle(user.theme).phantomNotebookRow, {marginTop: margin}]}/>;
              })
            }
            <View style={{}}>
              <View style={{alignSelf: "flex-start", ...styles.notebookRow}}>
                {field.editableField
                  ? <View style={{flexDirection: "row", alignItems: "center", justifyContent: "flex-start", paddingLeft: 5}}>
                    <Button 
                      icon={require("../../icons/remove.png")} 
                      style={styles.removeButton} 
                      onPress={()=>deleteField(i)}
                    />
                    <View style={{width: "87%", flexDirection: "row", alignItems: "center"}}>
                      <InputText 
                        onChange={(nativeEvent)=>onChangeFieldName(nativeEvent, i)}
                        placeholder="Field Name"  autoFocus={i !== 0}
                        value={field.name} style={{...textBoxStyles(field.validName).textBox, height: 32, width: "63%", marginLeft: 12}}
                        onFocus={()=>{
                          setKeyboardVisible(true);
                          if(i == 0 && field.editableField && !field.removedDefault) {
                            field.removedDefault = true;
                            onChangeFieldName({text: ""}, i);
                          }
                        }} selectTextOnFocus={true}
                        onBlur={()=>setKeyboardVisible(false)} abideByTheme={false}
                      />
                      <DropDown abideByTheme={false} defaultValue={field.type} buttonStyle={{height: 35, width: "35%", maxWidth: "35%"}} data={fieldTypes.map(t=>t.name)} onDropChange={(selectedItem) => onTypeChange(selectedItem, i)} style={{width: 200}}/>

                    </View>
                  </View>
                  : <LMText
                    style={{fontSize: DS, ...styles.label}} abideByTheme={false}
                    numberOfLines={ 1 } adjustsFontSizeToFit
                    onTextLayout={ (e) => SetFontSizeToFit(e, currentFont, setCurrentFont)}
                  >{field.name} {(field.type === "timer") ? "(hour:minute)" : ""}</LMText>

                }
              </View>
            </View>
            <View style={{width: "100%"}}>
              <View style={{...styles.notebookRow, paddingLeft: Platform.OS === "web" ? 60 : 45}}>
                {field.type==="photo"
                  ? <View style={{marginBottom: 50}}><ImagePicker abideByTheme={false} isHeight={true} callback={(r, r2)=>OnImagePick(r, r2,  i)} setReload={setReload} /></View>
                  : <>
                    {field.type==="checkbox"
                      ?<CheckBox
                          style={{paddingLeft: 10, padding: 5}}
                          onClick={()=>{
                            onCBChange(!field.value, i);
                          }} abideByTheme={false}
                          isChecked={field.value}
                      />
                      :<>
                        {field.type!=="date"&&field.type!=="time"&&field.type!=="timer"
                          ?<>
                            {field.type === "free text"
                              ?<RichTextEditor onChange={onChange} initialValue={field.value} name={i}/>
                              :(field.type === "color"
                                ?<View style={{right: 20, maxWidth: 350, paddingBottom: 70, height: 400}}>
                                <ColorPicker
                                  color={field.value}
                                  onColorChange={(color) => onCBChange(color, i)}
                                  onInteractionStart={()=>setDoingAction(true)}
                                  onColorChangeComplete={()=>setDoingAction(false)}
                                  thumbSize={30}
                                  sliderSize={25}
                                  noSnap={true}
                                  row={false}
                                /><LMText abideByTheme={false} style={[styles.colorText, {backgroundColor: field.value, position: "absolute", bottom: 20}]}>
                                  {GetColorName(field.value||"")}</LMText>
                                </View>
                                : ( field.type === "day" || field.type === "month" || field.type === "year"
                                  ? <DayMonthYearSelect value={field.value} type={field.type} onChange={(value)=>onCBChange(value, i)}/>
                                  : <InputText 
                                  onChange={(nativeEvent, name)=>{onChange(nativeEvent, name);validate({nativeEvent}, i);}} name={i} 
                                  value={field.value} placeholder={field.type}
                                  defaultValue=""
                                  type={field.type} style={textBoxStyles(field.valid).textBox}
                                  onFocus={()=>setKeyboardVisible(true)} abideByTheme={false}
                                  onBlur={()=>setKeyboardVisible(false)}
                                />
                                )

                              )
                            }
                          </>
                          :<DateTimePickerButton index={i} field={field} onDTChange={onDTChange} style={{width: "95%"}}/>
                        } 
                      </>
                    }
                  </>
                }
              </View>
            </View>
          </View>;
        })
      }
      { selectedCategory.id === -1 &&
        <>
        <View style={styles.notebookRow}/>
        <Button style={styles.addFieldButton} onPress={addField} disabled={fieldValues.length > 19}>
          <Image style={{width:20, height: 20, marginLeft: 15}} source={require("../../icons/plus.png")}/>
          <LMText abideByTheme={false}>Add Field</LMText>
        </Button>
        </>
      }
      <View style={styles.notebookRow}/>
      <View style={{height: 10}}/>
      </View>
      
      <Buttons fieldValues={fieldValues} onSubmit={onSubmit}/>

      <View style={{height: 130 * (isKeyboardVisible ? 2 : 1)}}/>
    </View>;
};

const Buttons = ({fieldValues, onSubmit}) => {
  const disabled = fieldValues.map(f=>f.valid).includes(false) || fieldValues.map(f=>f.validName).includes(false);

  return <View style={{...styles.flexRow, marginBottom: 20}}>
    <OKButton disabled={disabled} onClick={onSubmit} text="Done"/>
  </View>;
};

Buttons.propTypes = {
  fieldValues: PropTypes.array,
  onSubmit: PropTypes.any,
  setHomeRoute: PropTypes.func
};

AddNote.propTypes = {
  addRecentNote: PropTypes.func,
  notes: PropTypes.array,
  reload: PropTypes.func,
  scrollTo: PropTypes.func,
  selectedCategory: PropTypes.shape({
    feilds: PropTypes.any,
    id: PropTypes.any,
    shared: PropTypes.any,
    userId: PropTypes.any
  }),
  setHomeRoute: PropTypes.func,
  setNotes: PropTypes.func,
  url: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.any
  })
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flexBasis: "100%",
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "flex-end", 
    alignSelf: "flex-end",
    right: 20,
    width: 1000,
    marginTop: 5,
    bottom: -10,
  },
  label: {
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 2,
    paddingLeft: 45,
    textAlign: "left",
  },
  headerTB: {
    width: "85%",
    height: 40,
    fontSize: 20,
    marginLeft: Platform.OS === "web" ? 60 : 45
  },

  notebookRow: {
    width: "100%",
    borderColor: "rgba(186, 224, 255, 0.3)",
    borderWidth: 0,
    borderTopWidth: 3,
    padding: 3
  },

  colorText: {
    fontSize: 25, 
    borderColor: "black", 
    borderWidth: 0.7, 
    paddingHorizontal: 20, 
  },
  addFieldButton: {
    width: 120,
    backgroundColor: "white",
    borderColor: "#d7d7d7",
    borderRadius: 6,
    borderWidth: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  removeButton: {
    width: 40, 
    height: 40,
    margin: Platform.OS === "web" ? 0 : -7, 
    backgroundColor:"transparent",
  },
});
const textBoxStyles = (valid) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  textBox: {
    borderColor: valid ? "#d7d7d7" : "red",
    borderWidth: valid ? 1 : 2,
    width: Platform.OS === "ios" ? "94%" : "89%",
    height: 30,
    margin: 2.5
  }
});

const NoteBookStyle = (theme) => StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  redBorder: {
    width: Platform.OS === "web" ? 50 : 40,
    alignSelf: "flex-start",
    borderColor: `rgba(255, 0, 0, ${theme ? 0.3 : 0.6})`,
    borderWidth: 0,
    borderRightWidth: 3,
    height: "100%",
    position: "absolute"
  },
  // eslint-disable-next-line react-native/no-unused-styles
  phantomNotebookRow: {
    width: '100%',
    borderColor: `rgba(186, 224, 255, ${theme ? 0.3 : 0.6})`,
    borderWidth: 0,
    borderTopWidth: 3,
    padding: 3,
    height: 40, 
    position: "absolute",
  },
});