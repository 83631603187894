import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Popup } from "../../../components/elements/popup";
import { View, StyleSheet } from "react-native";
import { Button } from "../../../components/elements/Button";
import { InputText } from "../../../components/elements/InputText";
import { Col, Grid, Row } from "react-native-easy-grid";
import { REACT_APP_API_PASSWORD } from "@env";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const DeleteAccountPopup = ({ isVisible, close }) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);
  const [pass, setPass] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setPass("");
  }, [isVisible, user]);

  const deleteImage = () => {
    fetch(`${url}/lm_api/image`, {
      method: "delete",
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        imageName: user.image,
        bucket: "users-prf"
      }),
      headers: { "Content-Type": "application/json" },
    });
  };

  const onSubmit = () => {
    setReload(true);
    deleteImage();
    if ("Delete Now" == pass) {
      fetch(`${url}/lm_api/deleteteUser`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: REACT_APP_API_PASSWORD, id: user.id }),
      })
        .then((res) => res.json())
        .then(() => {
          close();
          reload();
          deleteCache();
          if(Platform.OS === "web")
            location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      setReload(false);
      Alert("incorrect password");
    }
  };

  const deleteCache = async () => {
    try {
      await AsyncStorage.clear();
    } catch(e) {
      // clear error
    }
  };

  return (
    <Popup isVisible={isVisible} close={close} style={styles.popup}>
      <LMText style={styles.header}>Delete Account</LMText>
      <View style={{ height: 80, marginTop: 15 }}>
        <Grid>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>
                Write {'"'}Delete Now{'"'}
              </LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                onChange={(nativeEvent) => {
                  setPass(nativeEvent.text);
                  setValid(nativeEvent.text == "Delete Now");
                }} abideByTheme={false}
                style={styles.textBox}
                placeholder="Delete Now"
              />
            </Col>
          </Row>
        </Grid>
      </View>
      <LMText style={styles.AYSText}>
        Are you sure you want to delete your account?
      </LMText>
      <Button
        text="Delete my account"
        onPress={() => onSubmit()}
        style={styles.deleteAccButton}
        textStyle={{ ...styles.deleteAccButtonText, fontWeight: "400" }}
        gradient={valid ? ["#ab0000", "#440000"] : ["#9c8c8c", "#3d3838"]}
        gradientStart={[0, 1]}
        gradientEnd={[1, 0]}
        disabled={!valid}
      />
      <Button
        text="No, go back"
        onPress={() => close()}
        style={styles.goBackButton}
        textStyle={{ ...styles.goBackButtonText, fontWeight: "400" }}
        gradient={["#ffffff", "#ffffff"]}
        gradientStart={[0, 1]}
        gradientEnd={[1, 0]}
      />
    </Popup>
  );
};

DeleteAccountPopup.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any,
  reload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any,
    image: PropTypes.any,
  }),
};

const styles = StyleSheet.create({
  popup: {
    width: 200,
  },
  header: {
    fontSize: 35,
    marginBottom: 10,
    marginLeft: 50,
    marginRight: 50,
    fontWeight: "500",
  },
  deleteAccButton: {
    height: 40,
    width: 200,
    borderRadius: 70,
    marginTop: 15,
  },
  deleteAccButtonText: { color: "white" },
  goBackButton: {
    height: 40,
    width: 200,
    borderRadius: 70,
    marginTop: 15,
    borderColor: "#008aff",
    borderWidth: 2,
  },
  goBackButtonText: { color: "black" },
  AYSText: {
    fontSize: 20,
    textAlign: "center",
  },
  dtRow: {
    borderBottomWidth: 0,
    height: 50,
    width: 300,
    marginLeft: 0,
    justifyContent: "center",
    flex: 1
  },
  cell: {
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 1.2,
    justifyContent: "center",
    width: 30,
    paddingRight: 10,
    alignItems: "center",
    paddingBottom: 10,
  },
  cell2: {
    flex: 1,
    justifyContent: "center",
    width: 30,
    paddingLeft: 10,
    paddingTop: 15,
    paddingBottom: 10,
  },
  textBox: {
    borderWidth: 2,
    borderColor: "#dddddd",
    width: "100%",
    height: 30,
  },
  label: {
    fontSize: 16,
    fontWeight: "400",
    alignSelf: "center",
    marginTop: 15,
  },
});
