import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { screenWidth } from "../../utils/utils";

export const AutoScaleImage = ({style, source, maxWidth=(screenWidth*0.8), ...props}) => {
  const [width, setWidth] = useState(style.width||100);
  const [height, setHeight] = useState(style.height||100);

  useEffect(()=>{
    
    if(source.uri && source.uri.length > 50 && !(style.width && style.height)) {
      Image.getSize(source.uri, (width, height) => {

        var finalWidth = style.width ? style.width : width;
        var finalHeight = style.width ? (style.width / width) * height : height;
        if(style.height) {
          finalWidth = (style.height / height) * width;
          finalHeight = style.height;
        }
        if(style.width) {
          finalWidth = style.width;
          finalHeight = (style.width / width) * height;
        } 
        if(maxWidth && finalWidth > maxWidth) {
          finalWidth = maxWidth;
          finalHeight = (maxWidth / width) * height;
        }
        setWidth(finalWidth);
        setHeight(finalHeight);
      }, (err) => console.log(err));
    }
  }, [source]);
  return <Image 
    style={[style, {width: width, height: height}]}
    source={source}
    {...props}
  />; 
};
AutoScaleImage.propTypes = {
  maxWidth: PropTypes.number,
  source: PropTypes.shape({
    uri: PropTypes.string
  }),
  style: PropTypes.object
};
