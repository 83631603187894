import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Platform, StyleSheet, TouchableOpacity,  View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { LMText } from "./Text";
import { Image } from "react-native";
import { hexToGrayscale, whiteColor } from "../../utils/utils";
import { AppContext } from "../../../AppContext";

export const Button = ({
  style = {},
  textStyle = {},
  text = "",
  onPress = ()=>null,
  icon = null,
  iconStyle = {},
  disabled = false,
  gradient = [],
  gradientStart = [0, 0],
  gradientEnd = [1, 0],
  onLongPress=null,
  testID = "button",
  abideByTheme=false,
  ...props
}) => {
  const { user } = useContext(AppContext);

  const defaultWidthHeight = {
    width: style.width / 1.3,
    height: style.height / 1.3,
  };
  if(testID === "button")
    testID = "button_" + text.replace(' ', '_') + (icon ?  "_" + icon.toString().split('/').pop().split('.')[0] : "");
  if(disabled) {
    gradient = gradient.map(color=>hexToGrayscale(color));
  }

  const imageStyle = [
    disabled ? (Platform.OS==="web" ? {filter: "grayscale(100%)"} : {opacity: 0.1}) : {opacity: 1},
    !("width" in style) && icon !== null ? {} : defaultWidthHeight,
    styles.image,
    iconStyle,
    user.theme===undefined||user.theme>0||!abideByTheme ? {} : {tintColor:whiteColor}
  ];

  return (
    <TouchableOpacity
      style={[styles.button, user.theme ? {backgroundColor: whiteColor} : {backgroundColor: "#4d4d4d"}, style]}
      onPress={onPress}
      disabled={disabled}
      onLongPress={onLongPress}
      testID={testID}
    >
      {props.children}
      {icon == null ? (
        <LMText
          style={[styles.buttonText, textStyle, abideByTheme ? {color: user.theme ? "black" : whiteColor} : {}]}
          numberOfLines={1}
        >
          {text}
        </LMText>
      ) : (
        <View style={{position: "absolute", zIndex: -1}}>
          {disabled&&Platform.OS!=="web"&&<Image source={icon} style={[...imageStyle, {tintColor: "gray", opacity: 0.8, position: "absolute"}]}/>}
          <Image source={icon} style={imageStyle}/>
        </View>
      )}
      {gradient.length > 1 ? (
        <View style={gradientStyle(style.borderRadius).buttonBackground}>
          <LinearGradient
            colors={gradient}
            style={styles.buttonBackground}
            start={gradientStart}
            end={gradientEnd}
          />
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

Button.propTypes = {
  abideByTheme: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  gradient: PropTypes.array,
  gradientEnd: PropTypes.array,
  gradientStart: PropTypes.array,
  icon: PropTypes.shape({
    toString: PropTypes.func
  }),
  iconStyle: PropTypes.object,
  onLongPress: PropTypes.any,
  onPress: PropTypes.func,
  style: PropTypes.object,
  testID: PropTypes.string,
  text: PropTypes.string,
  textStyle: PropTypes.object
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#c2c2c2",
    width: 40,
    height: 40,
    padding: 0,
  },
  buttonText: {
    color: "black",
  },
  image: {},
  buttonBackground: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
});
const gradientStyle = (borderRadius) =>
  StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    buttonBackground: {
      width: "100%",
      height: "100%",
      position: "absolute",
      borderRadius: borderRadius || 10,
      zIndex: -1,
      overflow: "hidden",
    },
  });
