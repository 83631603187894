import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, Linking, Share, Platform } from "react-native";
import { getDataSave, setDataSave } from "../../utils/dataSaver";
import { Button } from "../elements/Button";
import { AnimatedMenu } from "./animatedMenu";
import { Popup } from "../elements/popup";
import { REACT_APP_API_PASSWORD } from "@env";
import { InputText } from "../elements/InputText";
import { AppContext, BackButtonContext } from "../../../AppContext";
import { LMText } from "../elements/Text";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { regularColor } from "../../utils/utils";

const AddButton = ({ EnterNewScreen }) => {
  const plusC = require("../../icons/addCategoryside.png");
  return (
    <>
      <Button
        style={{
          ...styles.button,
          width: 149,
          height: 43,
        }}
        icon={plusC}
        onPress={EnterNewScreen}
      />
    </>
  );
};

AddButton.propTypes = {
  EnterNewScreen: PropTypes.any,
};

export const HelpPopup = ({ isVisible, close}) => {
  const lmEmail = "contact.lifemngr@gmail.com";
  return  <Popup isVisible={isVisible} close={close}>
    <LMText style={{ fontSize: 40, fontWeight: "400", marginBottom: 20 }}>
      Need support?
    </LMText>
    <LMText style={{ fontSize: 20 }}>
      If you have any question or want to make any suggestion, contact us at
    </LMText>
    <LMText
      onPress={() => Linking.openURL(`mailto:${lmEmail}`)}
      style={{
        fontWeight: "400",
        color: "blue",
        fontSize: 20,
        textDecorationLine: "underline",
      }}
    >
      {lmEmail}
    </LMText>
  </Popup>;
};

HelpPopup.propTypes = {
  close: PropTypes.any,
  isVisible: PropTypes.any
};

export const InvitePopup = ({isVisible, close}) => {
  const [invitePopupEmail, setInvitePopupEmail] = useState(false);
  const {user, url, Alert} = useContext(AppContext);

  const ShareApp = async () => {
    try {
      const result = await Share.share({
        message: "Check out this new amazing app - Life Manager!!",
        url: "https://lifemngr.com/download",
      });
      if (result.action === Share.sharedAction) {
        Alert("Invite Sent!!");
        close();
      }
    } catch (error) {
      Alert(error.message);
    }
  };

  const ShareByEmail = () => {
    fetch(`${url}/lm_api/inviteWithEmail`, {
      method: "post",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        email: invitePopupEmail,
        fromUserID: user.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, invitePopupEmail);
        if (!res) {
          Alert("user already signed up!");
        } else {
          Alert("Invite Sent!!");
         close();
        }
      }).catch((err) => console.log(err));
  };

  

  return <Popup isVisible={isVisible} close={close}>
  <LMText style={{ fontSize: 40, fontWeight: "400", marginBottom: 20 }}>
    Invite a friend
  </LMText>
  <LMText style={{ fontSize: 20 }}>Invite your friend to the app!</LMText>
  <View>
    <LMText style={styles.emailText}>send invite by email:</LMText>
    <View>
      <InputText
        onChange={(nativeEvent) => setInvitePopupEmail(nativeEvent.text)}
        style={styles.textBox}
        placeholder="Email" type="email"
      />
      <View style={styles.buttonCon2}>
        <Button
          text="No, go back"
          onPress={close}
          style={styles.backButton}
        />
        <Button
          text="OK!"
          onPress={ShareByEmail}
          gradient={["#277cb9", "#000000"]}
          gradientStart={[0, 0.5]}
          style={styles.submit}
          gradientEnd={[1.5, 0]}
          textStyle={{ color: "white" }}
        />
      </View>
    </View>
  </View>
  {Platform.OS !== "web"
    ?<>
      <LMText>{"\n"}OR, share by any method: </LMText>
      <LMText
        onPress={ShareApp}
        style={{
          fontWeight: "400",
          color: "blue",
          fontSize: 20,
          textDecorationLine: "underline",
        }}
      >
        SHARE
      </LMText>
    </>
    : null
  }
</Popup>;
};

InvitePopup.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any
};

export const TopBar = ({
  homeRoute,
  setHomeRoute,
  setSearchKey,
  setCopyMode,
}) => {
  const {reload, user, url} = useContext(AppContext);
  const { backButtonFunction } = useContext(BackButtonContext);


  const [accountMenu, setAccountMenu] = useState(false);
  const [helpPopup, setHelpPopup] = useState(false);
  const [invitePopup, setInvitePopup] = useState(false);
  const [image64, setImage64] = useState("");

  const setImageCache = async (image) => {await setDataSave(`profile_image`, image);};
  const getImageCache = async () => {
    try {
      const value = await getDataSave(`profile_image`);
      setImage64(value);
    } catch(e) {null;}
  };


  useEffect(() => {
    getImageCache();
    if (user.image != null && user.image != "") {
      fetch(`${url}/lm_api/image`, {
        method: "get",
        headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, key: user.image, bucket: "users-prf" },
      })
        .then((res) => res.json())
        .then((image) => {
          setImage64("data:image/png;base64," + image);
          setImageCache("data:image/png;base64," + image);
        });
    }
    return () => {
      
    };
  }, [user]);

  const backNav =
    homeRoute !== "addNote" &&
    homeRoute !== "viewNote" &&
    homeRoute !== "categoryDisplay" &&
    homeRoute !== "addCategory";
  const accountSettings = homeRoute === "account";

  const logOut = async () => {
    setAccountMenu(false);
    // setDataSave("userdata", " , ");
    // setDataSave("recents", "[]");
    try {
      await AsyncStorage.clear();
    } catch(e) {
      // clear error
    }
    reload();
  };

  return (
    <View style={styles.navBar}>
      <View style={styles.buttonCon}>
        {backNav && !accountSettings && homeRoute !== "searchScreen" && homeRoute !== "table" && homeRoute !== "notifications" ? (
          // <AddButton
          //   EnterNewScreen={() => {
          //     setHomeRoute("addCategory");
          //   }}
          // />
          <View style={{ ...styles.button, height: 55, width: 55 }}></View>
        ) : (
          <>
            <Button
              style={{ ...styles.button, height: 55, width: 55 }}
              icon={require("../../icons/backArrow.png")}
              abideByTheme={true}
              onPress={() => {
                backButtonFunction.func();
                setSearchKey("");
                setCopyMode(false);
              }}
            />
          </>
        )}
        <View
          style={{
            position: "absolute",
            right: 150,
            top: 50
          }}
        >
          <AnimatedMenu
            close={() => setAccountMenu(false)}
            show={accountMenu}
          >
            <Button
              text="Add Category"
              onPress={() => {
                setHomeRoute("addCategory");
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button
              text="Account Settings"
              onPress={() => {
                setHomeRoute("account");
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button
              text="Help"
              onPress={() => {
                setAccountMenu(false);
                setHelpPopup(true);
              }}
              style={styles.listItem}
            />
            <Button
              text="Invite a Friend"
              onPress={() => {
                setInvitePopup(true);
                setAccountMenu(false);
              }}
              style={styles.listItem}
            />
            <Button text="Log Out" onPress={logOut} style={styles.listItem} />
          </AnimatedMenu>
        </View>
        <Button
          style={{ ...styles.button, width: 35, height: 35, borderRadius: 40,}}
          iconStyle={{width: 40, height: 40,borderRadius: 100}}
          testID="accountIcon"
          icon={image64 === "" || !image64 ? require("../../icons/accountIcon.png") : {uri: image64}}
          onPress={() => setAccountMenu(!accountMenu)}
        >

        </Button>
      </View>
      <HelpPopup isVisible={helpPopup} close={() => setHelpPopup(false)}/>
      <InvitePopup isVisible={invitePopup} close={() => setInvitePopup(false)} />
    </View>
  );
};

TopBar.propTypes = {
  categories: PropTypes.any,
  homeRoute: PropTypes.string,
  reload: PropTypes.func,
  setCopyMode: PropTypes.func,
  setHomeRoute: PropTypes.func,
  setSearchKey: PropTypes.func,
  setSelectedCategory: PropTypes.any,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any,
  }),
};

const styles = StyleSheet.create({
  navBar: {
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
    padding: 20,
    paddingTop: Platform.OS === "ios" ? 20 : 0,
    paddingBottom: 0,
    top: Platform.OS === "ios" ? 20 : 0
  },
  buttonCon: {
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    width: 40,
    height: 40,
    backgroundColor: "transparent",
    marginTop: 0,
  },
  listItem: {
    backgroundColor: regularColor,
    justifyContent: "center",
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    flex: 1,
    height: 40,
    padding: 10,
    alignItems: "center",

    width: 155,
    marginBottom: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 4,
    elevation: 16,
    alignSelf: "flex-start",
  },
  textBox: {
    borderWidth: 2,
    borderColor: "#dddddd",
    width: 200,
    height: 30,
    alignSelf: "center"
  },
  emailText: {
    fontSize: 20,
    textAlign: "center",
  },
  buttonCon2: {
    marginTop: 10,
    flexDirection: "row",
  },
  submit: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5,
  },
  backButton: {
    height: 40,
    width: 120,
    borderRadius: 70,
    margin: 5,
    borderWidth: 2,
    borderColor: "#277cb9",
    backgroundColor: "transparent",
  },
});
