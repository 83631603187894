import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Popup } from "../../../components/elements/popup";
import { View, StyleSheet } from "react-native";
import { InputText } from "../../../components/elements/InputText";
import { Col, Grid, Row } from "react-native-easy-grid";
import { REACT_APP_API_PASSWORD } from "@env";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { OKButton } from "../../../components/elements/OKButton";

export const ChangeName = ({ isVisible, close }) => {
  const {user, url, reload, setReload} = useContext(AppContext);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
  }, [isVisible, user]);

  const onSubmit = () => {
    setReload(true);
    fetch(`${url}/lm_api/changeUserName`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        firstName: firstName,
        lastName: lastName,
        id: user.id,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        reload();
        close();
      });
  };

  return (
    <Popup isVisible={isVisible} style={styles.popup} close={close}>
      <LMText style={styles.header}>Change Name</LMText>
      <View style={{ height: 150, marginTop: 15 }}>
        <Grid>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>First Name</LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                defaultValue={firstName}
                onChange={(nativeEvent) => setFirstName(nativeEvent.text)}
                style={styles.textBox} abideByTheme={false}
                placeholder="First Name..."
              />
            </Col>
          </Row>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>Last Name</LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                defaultValue={lastName}
                onChange={(nativeEvent) => setLastName(nativeEvent.text)}
                style={styles.textBox} abideByTheme={false}
                placeholder="Last Name..."
              />
            </Col>
          </Row>
        </Grid>
      </View>
      <View style={styles.flexRow}>
        <OKButton onClick={onSubmit} />
      </View>
    </Popup>
  );
};

ChangeName.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any,
  reload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    firstName: PropTypes.any,
    id: PropTypes.any,
    lastName: PropTypes.any,
  }),
};

const styles = StyleSheet.create({
  popup: {
    width: 300,
    height: 400
  },
  header: {
    fontSize: 30,
    marginLeft: 70,
    marginRight: 70,
    fontWeight: "500",
    width: "100%",
    textAlign: "center"
  },
  dtRow: { borderBottomWidth: 0, height: 45, width: 300, marginLeft: 20, flex: 1 },
  cell: {
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 0.5,
    justifyContent: "center",
    width: 30,
    paddingRight: 10,
    alignItems: "flex-end",
  },
  cell2: {
    flex: 0.7,
    justifyContent: "center",
    width: 30,
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    fontWeight: "400",
  },
  textBox: {
    borderWidth: 2,
    borderColor: "#dddddd",
    width: "100%",
    height: 30,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    right: -100,
  },
});
