import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Popup } from "../../../components/elements/popup";
import { View, StyleSheet } from "react-native";
import { InputText } from "../../../components/elements/InputText";
import { Col, Grid, Row } from "react-native-easy-grid";
import { REACT_APP_API_PASSWORD } from "@env";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { OKButton } from "../../../components/elements/OKButton";

export const ChangePhone = ({  isVisible, close }) => {
  const {user, url, reload, setReload} = useContext(AppContext);
  const [newPhone, setNewPhone] = useState("");

  useEffect(() => {
    setNewPhone("");
  }, [isVisible, user]);

  const onSubmit = () => {
    setReload(true);
    fetch(`${url}/lm_api/changeUserPhone`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: REACT_APP_API_PASSWORD,
        phone: newPhone,
        id: user.id,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        close();
        reload();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Popup isVisible={isVisible} style={styles.popup} close={close}>
      <LMText style={styles.header}>Change Phone</LMText>
      <View style={{ height: 140 }}>
        <Grid>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>New Phone no.</LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                defaultValue={newPhone}
                onChange={(nativeEvent) => setNewPhone(nativeEvent.text)}
                style={styles.textBox}
                placeholder="New Phone..."
                type="phone" abideByTheme={false}
              />
            </Col>
          </Row>
        </Grid>
      </View>
      <View style={styles.flexRow}>
        <OKButton onClick={onSubmit} />
      </View>
    </Popup>
  );
};

ChangePhone.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any,
  reload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any,
  }),
};

const styles = StyleSheet.create({
  popup: {
    width: 300,
  },
  header: {
    fontSize: 30,
    marginLeft: 50,
    marginRight: 50,
    fontWeight: "500",
    width: "100%",
    textAlign: "center"
  },
  dtRow: { borderBottomWidth: 0, height: 45, width: 330, marginLeft: 20, flex: 1 },
  cell: {
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 0.5,
    justifyContent: "center",
    paddingRight: 10,
    alignItems: "flex-end",
  },
  cell2: {
    flex: 0.6,
    justifyContent: "center",
    width: 30,
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    fontWeight: "400",
  },
  textBox: {
    borderWidth: 2,
    borderColor: "#dddddd",
    width: "100%",
    height: 30,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    right: -100,
  },
});
