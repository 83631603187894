/* eslint-disable react-native/no-unused-styles */
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, Keyboard, Platform } from "react-native";
import { Button } from "../elements/Button";
import { InputText } from "../elements/InputText";
import { AppContext } from "../../../AppContext";

export const NavBar = ({ setHomeRout, homeRoute, setSearchKey, searchKey, isUnreadNotification}) => {
  const { user } = useContext(AppContext);
  const [isSearch, setIsSearch] = useState(false);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      Platform.OS == "android" ? "keyboardDidShow" : "keyboardWillShow",
      () => {
        setKeyboardVisible(true);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      Platform.OS == "android" ? "keyboardDidHide" : "keyboardWillHide",
      () => {
        setKeyboardVisible(false);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  useEffect(() => {
    if (homeRoute !== "searchScreen") Keyboard.dismiss();
  }, [homeRoute]);

  const inMain = homeRoute == "main" || homeRoute == "searchScreen";
  const inAdd = homeRoute == "addNote" || homeRoute == "addCategory";
  const inAcc = homeRoute == "account";
  const inNotifications = homeRoute == "notifications";
  const canSearch =
    !inAcc &&
    !inAdd &&
    homeRoute != "categoryDisplay" &&
    homeRoute != "viewNote";

  return <View
      style={{
        ...styles(false, isSearch).container,
        backgroundColor: !canSearch ? "transparent" : (user.theme > 0 ? "rgba(0, 0, 0, 0.3)" : "rgba(64, 64, 64, 0.6)"),
        flex: canSearch ? (isSearch ? 0.03 : 0.16) : 0.035,
        bottom: isKeyboardVisible&&!canSearch ? -80 : -2
      }}
    >
      {canSearch ? (
        <InputText
          placeholder="search..."
          onChange={(nativeEvent) => setSearchKey(nativeEvent.text)}
          style={styles(false, isSearch,).search}
          value={searchKey}
          icon={require("../../icons/searchIcon.png")}
          onFocus={() => {
            setIsSearch(true);
            setHomeRout("searchScreen");
          }}
          onBlur={() => {
            setIsSearch(false);
          }}
        />
      ) : null}
      {isSearch ? null : (
        <View style={styles(false, isSearch, user.theme > 0).container2}>
          <Button
            icon={require("../../icons/home.png")}
            style={styles().button}
            onPress={() => setHomeRout("main")}
            iconStyle={styles(inMain, true, user.theme).buttonImage}
          />
          <Button
            icon={isUnreadNotification && !inNotifications ? require(`../../icons/bell2.png`) : require(`../../icons/bell.png`)}
            style={styles().button}
            iconStyle={styles(inNotifications, true, user.theme).buttonImage}
            onPress={() => setHomeRout("notifications")}
          />
          <Button
            icon={require("../../icons/accountIconGray.png")}
            style={styles().button}
            onPress={() => setHomeRout("account")}
            iconStyle={styles(inAcc, true, user.theme).buttonImage}
          />
        </View>
      )}
    </View>;
};

NavBar.propTypes = {
  homeRoute: PropTypes.string,
  isUnreadNotification: PropTypes.any,
  searchKey: PropTypes.any,
  setHomeRout: PropTypes.func,
  setSearchKey: PropTypes.func
};

const styles = (isBlack = false, isSearch = false, isLightTheme=true) => {
  const tintColor = {
    tintColor: isBlack ? (isLightTheme ? "black" : "white") : "#b2b2b2",
  };
  if(!isLightTheme && !isBlack)
  tintColor.tintColor = "#454545";
 else if(!isBlack)
    delete tintColor.tintColor;
  return StyleSheet.create({
    container: {
      flex: 0.1,
      flexBasis: 50,
      height: 80,
    },
    container2: {
      flex: 1,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      bottom: 0,
      width: "100%",
      backgroundColor: isLightTheme ? "white" : "black",
      height: 70,
      paddingBottom: "3%",
      display: isSearch ? "none" : "flex",
      paddingLeft: "15%",
      paddingRight: "15%",
    },
    button: {
      width: 40,
      height: 50,
      backgroundColor: "transparent",
      margin: 0,
      marginTop: "5%",
    },
    buttonImage: isSearch ? {
      ...tintColor,
    } : {},
    search: {
      width: "95%",
      height: 40,
    },
  });
};
