import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, Modal, Platform, } from "react-native";
import { setDataSave } from "../../utils/dataSaver";
import { REACT_APP_API_PASSWORD } from "@env";
import { TopBar } from "../../components/mainComponents/topBar";
import { AddNote } from "./addNote";
import { NoteDisplay } from "./noteDisplay";
import { SearchScreen } from "./searchScreen";
import { NavBar } from "../../components/mainComponents/NavBar";
import { CategoryManagement } from "./mainScreen/categories";
import { CategoryDisplay } from "./categoryDisplay";
import { AddCategory } from "./addCategory";
import { AccountSettings } from "./accountSettings/accountSettings";
import { Button } from "../../components/elements/Button";
import { Notifications } from "./notifications";
import { IsJsonString, onlyUnique, reverseIfNeeded, screenHeight, setSpecialOrder, SortNotes } from "../../utils/utils";
import { AppContext, BackButtonContext, CacheContext, MainScrollContext } from "../../../AppContext";
import { WebTopBar } from "../../components/mainComponents/webTopBar";
import { WebSideBar } from "../../components/mainComponents/webSideBar";
import { LMText } from "../../components/elements/Text";
import { TableView } from "./tableView/tableView";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ScrollView } from "react-native";

const appjson = require("../../../app.json");

export const Home = ({forceUpdate}) => {
  const {user, url, reload, setReload} = useContext(AppContext);
  const {categoriesCache} = useContext(CacheContext);

  const [categories, setCategories] = useState(categoriesCache||[]);
  const [notes, setNotes] = useState([]);
  const [recentNotes, setRecentNotes] = useState([]);
  const [homeRoute, setHomeRoute] = useState("main");
  const [searchKey, setSearchKey] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedNote, setSelectedNote] = useState({});
  const [canScroll, setCanScroll] = useState(true);
  const [copyMode, setCopyMode] = useState(false);
  const [copyCategory, setcopyCategory] = useState({});
  const [tutorialPopup, setTutorialPopup] = useState(false);
  const [isUnreadNotification, setIsUnreadNotification] = useState(false);
  const [doingAction, setDoingAction] = useState(false);
  const scrollRef = React.useRef();
  

  const [notifications, setNotifications] = useState([]);
  const [backButtonFunction, setBackButtonFunction] = useState(null);

  const setCats = async (c) => {await setDataSave("categories", JSON.stringify(c));};


  useEffect(() => {
    setReload(false);
    setBackButtonFunction({func: ()=>{setHomeRoute("main");setSelectedCategory({});}});
  }, [homeRoute]);
  


  const getData = () => {
    console.log("Getting data!");
    setReload(true);
    fetch(`${url}/lm_api/notes`, {
      method: "get",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, id: user.id, },
    }).then((res) => res.json()).then((notes) => {
      fetch(`${url}/lm_api/notes/shared`, {
        method: "get",
        headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, id: user.id,},
      }).then((res) => res.json()).then((newNotes) => {
        const sharedNotes = newNotes.map((note) => ({
          ...note,
          shared: note.shareId ? true : false,
        }));
        setNotes(notes.concat(sharedNotes));
        setReload(false);

        fetch(`${url}/lm_api/categories`, {
          method: "get",
          headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD, id: user.id, version: appjson.expo.version },
        }).then((res) => res.json()) .then((categories) => {
          fetch(`${url}/lm_api/categories/shared`, {
            method: "get",
            headers: { "Content-Type": "application/json", id: user.id, password: REACT_APP_API_PASSWORD,},
          }).then((res) => res.json()).then((newCategories) => {
            const newCats = categories.map(category=>{
              const sharedCat = newCategories.find(c=>c.id===category.id);
              return sharedCat ? sharedCat : category;
            });
            const sharedCats = newCategories.filter(c=>c.fromUser !== user.id).map(c=>({...c, shared: true}));
            setCategories(newCats.concat(sharedCats));
    
            setCats(newCats.concat(sharedCats).map(c=>{
              return {...c, notes: notes.filter(n=>n.categoryId==c.id).length + newNotes.filter(n=>n.categoryId==c.id).length};
            }));
          }).catch((err) => console.log(err));
        }).catch((err) => console.log(err));
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));

    
    fetch(`${url}/lm_api/notifications`, {
      headers: { 'Content-Type': 'application/json', id: user.id, password: REACT_APP_API_PASSWORD },
      method: 'get',
    }).then(response=>response.json()).then(resNotifications=>{
      setNotifications(resNotifications.reverse());
      resNotifications.forEach((notification) => {
        if (!notification.isRead) {
          setIsUnreadNotification(true);
        }
      });
    }).catch(err=>console.log(err));
  };
  console.log(forceUpdate);
  useEffect(() => {
    getData();
  }, [forceUpdate]);

  useEffect(() => {
    setTutorialPopup(user.watchedTutorial == 1 ? false : true);
    const userRecentNotes = IsJsonString(user.recentNotes) ? JSON.parse(user.recentNotes) : [];
    if(userRecentNotes && userRecentNotes.length > 0)
      setRecentNotes(userRecentNotes.map(noteId=>notes.find(note=>note.id===noteId)).filter(n=>n));
  }, [notes]);

  const AddNewNote = (category) => {
    setSelectedCategory(category);
    setHomeRoute("addNote");
  };

  const EnterViewCat = (category) => {
    setSelectedCategory(category);
    setHomeRoute("categoryDisplay");
  };

  const addRecentNote = (note) => {
    var newNotes = recentNotes.slice();
    newNotes.unshift(note);
    newNotes = newNotes.filter(onlyUnique);
    if (newNotes.length > 20) newNotes.pop();

    setRecentNotes(newNotes);
    fetch(`${url}/lm_api/user/recentNotes`, {
      method: "put",
      headers: { "Content-Type": "application/json", password: REACT_APP_API_PASSWORD },
      body: JSON.stringify({ recentNotes: JSON.stringify(newNotes.map(n=>n.id)), id: user.id }),
    }).catch((err) => console.log(err));
  };

  const EnterViewNote = (note) => {
    addRecentNote(note);
    setSelectedNote(note);
    setHomeRoute("viewNote");
  };

  const scrollTo = (y=0) => {
    if (scrollRef.current) {
      scrollRef.current?.scrollTo({ y, animated: true });
    }
  };

  const closeTutorialPopup = () => {

    fetch(`${url}/lm_api/setUserTutorial`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: REACT_APP_API_PASSWORD, id: user.id }),
    })
      .then((res) => res.json())
      .then(() => {
        
        reload();
      })
      .catch((err) => console.log(err));
    setTutorialPopup(false);
  };

  const moveToNextNote = (addIndex, categoryId) => {
    const category = categories.find(c=>c.id===categoryId);
    const newNotes = setSpecialOrder(reverseIfNeeded(notes.filter(n=>n.categoryId===selectedNote.categoryId).sort((a, b)=>SortNotes(a, b, category.sortBy, notes, category.feilds)), category.reverseSort), category);
    const newNote = newNotes[newNotes.indexOf(newNotes.find(n=>n.id===selectedNote.id))+addIndex];
    if(newNote)
      setSelectedNote(newNote);
      reload();
  };

  const inAdd = homeRoute == "addNote" || homeRoute == "addCategory";
  const inAcc = homeRoute == "account";
  const canSearch =
    !inAcc &&
    !inAdd &&
    homeRoute != "categoryDisplay" &&
    homeRoute != "viewNote";

  const Scroll = homeRoute !== "main" ? KeyboardAwareScrollView : ScrollView;
  
  return (
    <BackButtonContext.Provider value={{backButtonFunction, setBackButtonFunction}}>

        <View style={styles.container}>
          {Platform.OS === "web"
            ?<>
              <WebTopBar
                setHomeRoute={setHomeRoute}
                homeRoute={homeRoute}
                setSearchKey={setSearchKey}
                isUnreadNotification={isUnreadNotification}
                searchKey={searchKey}
              />
            </>
            :<TopBar
              setHomeRoute={setHomeRoute}
              categories={categories}
              setSelectedCategory={(id)=>setSelectedCategory(categories.find(c=>c.id===id))}
              homeRoute={homeRoute}
              setSearchKey={setSearchKey}
              setCopyMode={setCopyMode}
            />
          }
          <View style={{flexBasis: 200, justifyContent: "center", flex: (Platform.OS === "web" ? 1 : 0.7) + (!canSearch ? 0.1 : 0)}}>
            <View style={{flexDirection: "row-reverse", flex: 1, height: "100%"}}>
              <Scroll
                extraScrollHeight={40}
                keyboardOpeningTime={0}
                keyboardShouldPersistTaps='handled' 
                scrollEnabled={homeRoute !== "notifications" && !doingAction && (homeRoute !== "main" || Object.keys(selectedCategory).length === 0)} 
                style={{height: screenHeight * (Platform.OS === "web" ? 0.9 : 0.8), marginTop: Platform.OS === "web" || homeRoute !== "main" ? 0 : -20}}
                overScrollMode="never"
                ref={scrollRef}
                enableResetScrollToCoords={false}
              >
                <MainScrollContext.Provider value={{setDoingAction}}>
                {
                  {
                    main: (
                      <CategoryManagement
                        setHomeRoute={setHomeRoute}
                        categories={categories}
                        notes={notes}
                        setHomeSelectedCategory={setSelectedCategory}
                        EnterViewNote={EnterViewNote}
                        AddNewNote={AddNewNote}
                        EnterViewCat={EnterViewCat}
                        scrollEnabled={canScroll}
                        setCopyCategory={setcopyCategory}
                        recentNotes={recentNotes}
                        goToTop={scrollTo}
                        homeSelectedCategory={selectedCategory}
                        setCategories={setCategories}
                      />
                    ),
                    addNote: (
                      <AddNote
                        selectedCategory={selectedCategory}
                        setHomeRoute={setHomeRoute}
                        scrollTo={scrollTo} addRecentNote={addRecentNote}
                        notes={notes} setNotes={setNotes}
                      />
                    ),
                    viewNote: (
                      <NoteDisplay
                        selectedNote={selectedNote}
                        setHomeRoute={setHomeRoute}
                        scrollTo={scrollTo}
                        notes={notes}
                        moveToNextNote={moveToNextNote}
                      />
                    ),
                    searchScreen: (
                      <SearchScreen
                        notes={notes}
                        EnterViewNote={EnterViewNote}
                        searchKey={searchKey}
                      />
                    ),
                    categoryDisplay: (
                      <CategoryDisplay
                        category={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setHomeRoute={setHomeRoute}
                        categories={categories}
                        notes={notes.filter((n) => n.categoryId == selectedCategory.id)}
                        canScroll={canScroll}
                        setCanScroll={setCanScroll}
                        scrollTo={scrollTo}
                        setCopyMode={setCopyMode}
                        copyMode={copyMode}
                      />
                    ),
                    addCategory: (
                      <AddCategory
                        scrollTo={scrollTo}
                        setHomeRoute={setHomeRoute}
                        canScroll={canScroll}
                        setCanScroll={setCanScroll}
                        copyCategory={copyCategory}
                        addCat={(c)=>setCategories([...categories, c])}
                      />
                    ),
                    account: (
                      <AccountSettings
                        categories={categories}
                        notes={notes}
                      />
                    ),
                    notifications: (
                      <Notifications
                        notifications={notifications}
                        setIsUnreadNotification={setIsUnreadNotification}
                      />
                    ),
                    table: (
                      <TableView
                        category={selectedCategory}
                        notes={notes}
                        setHomeRoute={setHomeRoute}
                        setSelectedCategory={setSelectedCategory}
                        EnterViewNote={EnterViewNote}
                      />
                    )
                  }[homeRoute]
                }   
                </MainScrollContext.Provider>
              </Scroll>
              {Platform.OS === "web"
                ? <WebSideBar homeRoute={homeRoute} setHomeRoute={(r)=>{setHomeRoute(r); setSelectedCategory({});}} isUnreadNotification={isUnreadNotification} setCopyMode={setCopyMode}/>
                : null
              }                    
            </View>
          </View>
          <Modal
            animationType="slide"
            transparent={true}
            visible={tutorialPopup}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <LMText abideByTheme={false} style={styles.tutorialPopupHeader}>
                  About our application:
                </LMText>
                <LMText abideByTheme={false} style={styles.tutorialPopupText}>
                  {"\n"}Welcome to Life Manager!{"\n"}Here you can write notes of
                  various categories.{"\n"}Each category has the notes from it{"'"}s
                  topic.{"\n"}You can declare many fields for a category, so you
                  know what you should write in each note of it.{"\n"}It is so easy
                  to organize your life!!{"\n"}
                </LMText>
                <Button
                  text="Got It!"
                  style={styles.tutorialPopupButton}
                  onPress={closeTutorialPopup}
                />
              </View>
            </View>
          </Modal>
          {Platform.OS !== "web"
            ? <NavBar
                setHomeRout={(r)=>{setHomeRoute(r); setSelectedCategory({});}}
                homeRoute={homeRoute}
                setSearchKey={setSearchKey}
                isUnreadNotification={isUnreadNotification}
                searchKey={searchKey}
              />
            : null
          }
        </View>      

    </BackButtonContext.Provider>

  );
};

Home.propTypes = {
  forceUpdate: PropTypes.any,
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent:  Platform.OS === "web" ? "flex-start" : "space-between"
  },
  tutorialPopupHeader: {
    fontSize: 30,
    fontWeight: "400",
  },
  tutorialPopupText: {
    padding: 5,
    fontSize: 20,
    textAlign: "center",
  },
  tutorialPopupButton: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 100,
    backgroundColor: "#accfb1",
    borderColor: "black",
    borderWidth: 0.2,
    alignSelf: "flex-end",
    marginRight: "10%",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
