import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { Popup } from "../../../components/elements/popup";
import { View, StyleSheet } from "react-native";
import { InputText } from "../../../components/elements/InputText";
import { Col, Grid, Row } from "react-native-easy-grid";
import { REACT_APP_API_PASSWORD } from "@env";
import { setDataSave } from "../../../utils/dataSaver";
import { AppContext } from "../../../../AppContext";
import { LMText } from "../../../components/elements/Text";
import { OKButton } from "../../../components/elements/OKButton";

export const ChangePassword = ({ isVisible, close }) => {
  const {user, url, reload, setReload, Alert} = useContext(AppContext);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassC, setNewPassC] = useState("");

  useEffect(() => {
    setOldPass("");
    setNewPass("");
    setNewPassC("");
  }, [isVisible, user]);

  const onSubmit = () => {
    setReload(true);
    if (newPass === newPassC) {
      fetch(`${url}/lm_api/changeUserPassword`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: REACT_APP_API_PASSWORD,
          newPass: newPass,
          oldPass: oldPass,
          userPass: user.password,
          id: user.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res === "oldPassIsNotCorrect") {
            setReload(false);
            Alert("Old password is not correct");
            return;
          }
          fetch(`${url}/lm_api/login`, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              password: REACT_APP_API_PASSWORD,
              user: user.username,
              UPassword: newPass,
              isEmail: false,
              encrypt: false,
            }),
          })
            .then((res) => res.json())
            .then((user) => {
              setDataSave("userdata", user.username + "," + user.password);
              close();
              reload();
            })
            .catch((err) => console.log(err));
        });
    } else {
      Alert("Confirm password is not correct");
    }
  };

  return (
    <Popup isVisible={isVisible} style={styles.popup} close={close}>
      <LMText style={styles.header}>Change Password</LMText>
      <View style={{ height: 150, marginTop: 15 }}>
        <Grid>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>Old Password</LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                defaultValue={oldPass}
                onChange={(nativeEvent) => setOldPass(nativeEvent.text)}
                style={styles.textBox}
                placeholder="Old Password..."
                password={true} abideByTheme={false}
              />
            </Col>
          </Row>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>New Password</LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                defaultValue={newPass}
                onChange={(nativeEvent) => setNewPass(nativeEvent.text)}
                style={styles.textBox}
                placeholder="New Password..."
                password={true} abideByTheme={false}
              />
            </Col>
          </Row>
          <Row style={styles.dtRow}>
            <Col style={styles.cell}>
              <LMText style={styles.label}>Confirm</LMText>
            </Col>
            <Col style={styles.cell2}>
              <InputText
                defaultValue={newPassC}
                onChange={(nativeEvent) => setNewPassC(nativeEvent.text)}
                style={styles.textBox}
                placeholder="Confirm..."
                password={true} abideByTheme={false}
              />
            </Col>
          </Row>
        </Grid>
      </View>
      <View style={styles.flexRow}>
        <OKButton onClick={onSubmit} />
      </View>
    </Popup>
  );
};

ChangePassword.propTypes = {
  close: PropTypes.func,
  isVisible: PropTypes.any,
  reload: PropTypes.func,
  url: PropTypes.any,
  user: PropTypes.shape({
    id: PropTypes.any,
    password: PropTypes.any,
    username: PropTypes.string,
  }),
};

const styles = StyleSheet.create({
  popup: {
    width: 300,
  },
  header: {
    fontSize: 30,
    marginLeft: 50,
    marginRight: 50,
    fontWeight: "500",
    width: "100%",
    textAlign: "center"
  },
  dtRow: { borderBottomWidth: 0, height: 45, width: 330, marginLeft: 20, flex: 1 },
  cell: {
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 0.5,
    justifyContent: "center",
    width: 30,
    paddingRight: 10,
    alignItems: "flex-end",
  },
  cell2: {
    flex: 0.6,
    justifyContent: "center",
    width: 30,
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    fontWeight: "400",
  },
  textBox: {
    borderWidth: 2,
    borderColor: "#dddddd",
    width: "100%",
    height: 30,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    right: -100,
  },
});
