import PropTypes from "prop-types";
import React from 'react';
import { StyleSheet, ScrollView, View, Platform } from 'react-native';
import { Button } from '../elements/Button';
import { Menu, } from 'react-native-material-menu';
import { regularColor } from "../../utils/utils";

export const AnimatedMenu = ({
  show, list=[], backgroundColor=()=>null,
  onClick=()=>null, objectKey="", itemStyle={},
  sortFunction=()=>null, close=()=>null, ...props
}) => {

  return <Menu
      visible={show}
      style={{backgroundColor: "transparent", shadowColor: "transparent"}}
      onRequestClose={close} 
    >
      <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={[{height: 250, paddingBottom: 50}, Platform.OS !== "web" ? {width: 170} : {}]}>
        {props.children}
        {
          list.sort(sortFunction).map(element=>{
            return <Button 
              style={[styles.item, typeof itemStyle === 'function' ? itemStyle(element) : itemStyle, backgroundColor({})===0 ? {} : {backgroundColor: backgroundColor(element)}]} 
              key={element.id} 
              text={element[objectKey]==="" ? "No Title" : element[objectKey]}
              onPress={()=>onClick(element)}
            />;
          })
        }
        <View style={{height: 50}}/>
      </ScrollView>
    </Menu>;
};

AnimatedMenu.propTypes = {
  backgroundColor: PropTypes.func,
  children: PropTypes.any,
  close: PropTypes.func,
  height: PropTypes.number,
  itemStyle: PropTypes.any,
  list: PropTypes.array,
  objectKey: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.string,
  show: PropTypes.any,
  sortFunction: PropTypes.func,
  style: PropTypes.object
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: regularColor,
    justifyContent: 'center',
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 10,
    flex: 1,
    height: 40,
    marginTop: 4,
    padding: 10,
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset:{
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 4,
    width: 135,
    marginLeft: 0,
  },
});