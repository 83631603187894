import PropTypes from "prop-types";
import React, { useContext } from 'react';
import { Text, Platform } from "react-native";
import { AppContext } from "../../../AppContext";
import { whiteColor } from "../../utils/utils";

export const LMText = ({abideByTheme=true, ...props}) => {
  const {user} = useContext(AppContext);
  const lightTheme = !abideByTheme||user.theme===undefined||user.theme>0;
  return <Text {...props} style={[{color: lightTheme ? "black" : whiteColor, fontFamily:  `Poppins${props.style&&(props.style.fontWeight===800||props.style.fontWeight==="bold") ? "_bold" : ""}${Platform.OS === "web" ? ", sans-serif" : ""}`}, props.style]}>{props.children}</Text>;
};
LMText.propTypes = {
  abideByTheme: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.any
};
